import _ from "lodash";
import Util from "@/util/util";
import api from "@/util/api";
import Vue from "vue";
import PanelsModule from "@/modules/panel-manager/panels";
import PanelPagesModule from "@/modules/panel-manager/panel-pages";
import EnumPanelManagerPageTabs from "@/models/enum-panel-manager-page-tabs";

export default {
    state: {
        pageTabs: [
            {
                label: "Panels",
                key: EnumPanelManagerPageTabs.panels,
            },
            {
                label: "Pages",
                key: EnumPanelManagerPageTabs.regions,
            },
        ],
    },
    getters: {},
    modules: {
        panels: PanelsModule,
        panelPages: PanelPagesModule,
    },
    mutations: {
        panelManager_set(state, data) {
            Util.VuexSetValueOnState(state, data);
        },
    },
    actions: {},
};

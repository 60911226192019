<template>
    <div
        class="flex-grow h-full bg-white bg-cover overflow-y-auto w-full rounded-bl-lg"
    >
        <Spinner v-if="imageBusy && !imageError" />
        <div
            v-if="!selectedHasScreenshotUrl || imageError"
            class="text-center p-13 text-xl"
        >
            <template v-if="imageError">
                {{ $t("listings.reviewModal.mediaProperties.imageLoadError") }}
            </template>
            <template v-else>
                {{ $t("listings.reviewModal.mediaProperties.noScreenshot") }}
            </template>
            <a
                :href="(selectedMediaProperty || {}).url"
                target="_blank"
                class="block mt-5 no-underline text-primary-dark"
                rel="noopener"
            >
                {{ (selectedMediaProperty || {}).url | url }}
            </a>
        </div>
        <a v-else :href="(selectedMediaProperty || {}).url" target="_blank" rel="noopener">
            <img
                :src="(selectedMediaProperty || {}).screenshotUrl"
                class="w-full"
                @error="imageError = true"
                @load="imageBusy = false"
            />
        </a>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Spinner from "@mediarails/vue-spinner";
export default {
    name: "MediaPropertyTabsScreenshot",
    components: {Spinner},
    filters: {
        url(value = "") {
            return value.replace(/^https?:\/\/(www.)?/, "");
        },
    },
    props: {
        selectedMediaPropertyIndex: {type: Number, required: true},
    },
    data: () => ({
        imageBusy: false,
        imageError: false,
    }),
    computed: mapState({
        editedListing: s => s.listings.editedListing,
        mediaProperties() {
            return (this.editedListing || {}).mediaProperties || [];
        },
        selectedMediaProperty() {
            return (
                this.mediaProperties[this.selectedMediaPropertyIndex] || null
            );
        },
        selectedHasScreenshotUrl() {
            return (
                this.selectedMediaProperty &&
                this.selectedMediaProperty.screenshotUrl
            );
        },
    }),
    watch: {
        selectedMediaProperty(newMediaProperty, oldMediaProperty) {
            this.imageError = false;
            let newUrl = (newMediaProperty || {}).screenshotUrl;
            let oldUrl = (oldMediaProperty || {}).screenshotUrl;
            if (newUrl && newUrl != oldUrl) this.imageBusy = true;
        },
    },
};
</script>

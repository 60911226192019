<template>
    <div>
        <Spinner v-if="isBusy" />
        <div class="text-center">
            <RegionSelect
                :region-id="selectedRegionId"
                @change="(regionId) => (selectedRegionId = regionId)"
            />
            <ProgramTypeSelect
                :program-type-id="selectedProgramTypeId"
                @change="
                    (programTypeId) => (selectedProgramTypeId = programTypeId)
                "
            />
        </div>
        <div class="text-center mt-8">
            <div
                v-for="(t, i) in panelStatusTabs"
                :key="t.key"
                style="margin-left: -1px"
                class="inline-block w-17 py-1 border border-grey cursor-pointer shadow-lg"
                :class="{
                    'hover:bg-light': !t.selected,
                    'shadow-inner': t.selected,
                    'text-white': t.selected,
                    'bg-impact-bright-blue': t.selected,
                    'border-impact-dark-blue': t.selected,
                    'rounded-l': i === 0,
                    'rounded-r': i === panelStatusTabs.length - 1,
                }"
                @click="selectedStatusTab = t.key"
            >
                {{ t.label }}
            </div>
        </div>
        <div class="mt-7 pb-15">
            <ActivePanelsView
                v-if="selectedStatusTab === 'active'"
                :max-panels="maxPanelsPerPage"
                :selected-region-id="selectedRegionId"
                :selected-program-type-id="selectedProgramTypeId"
            />
            <UnpublishedPanelsView
                v-else
                :max-panels="maxPanelsPerPage"
                :selected-region-id="selectedRegionId"
                :selected-page-type-id="selectedPageTypeId"
                :selected-program-type-id="selectedProgramTypeId"
            />
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import {mapState} from "vuex";
import Spinner from "@mediarails/vue-spinner";
import RegionSelect from "./RegionSelect.vue";
import ProgramTypeSelect from "./ProgramTypeSelect.vue";
import ActivePanelsView from "./ActivePanelsView.vue";
import UnpublishedPanelsView from "./UnpublishedPanelsView.vue";
import enumPageTypes from "@/models/enum-page-type";
export default {
    name: "RegionView",
    components: {
        Spinner,
        RegionSelect,
        ProgramTypeSelect,
        ActivePanelsView,
        UnpublishedPanelsView,
    },
    data: () => ({
        selectedRegionId: null,
        selectedProgramTypeId: null,
        selectedStatusTab: "active",
    }),
    computed: mapState({
        isBusy: (s) => s.panelManager.panelPages.isBusy,
        regions: (s) => s.static.regions,
        programTypes: (s) => s.static.programTypes,
        selectedPageTypeId() {
            let pageTypes = this.$store.state.static.pageTypes;
            if (pageTypes && pageTypes.length) {
                let pageType = pageTypes.find(
                    (pt) => pt.name === enumPageTypes.homepage
                );
                return pageType ? pageType.id : null;
            } else {
                return null;
            }
        },
        panelStatusTabs() {
            return [
                {
                    label: this.$t("panelManager.regions.activeTab"),
                    key: "active",
                    selected: this.selectedStatusTab === "active",
                },
                {
                    label: this.$t("panelManager.regions.unpublishedTab"),
                    key: "unpublished",
                    selected: this.selectedStatusTab === "unpublished",
                },
            ];
        },
        maxPanelsPerPage: (s) =>
            s.static.pageTypes && s.static.pageTypes.length
                ? s.static.pageTypes[0].maxPanels
                : 0,
    }),
    watch: {
        regions: {
            immediate: true,
            handler: function (regions) {
                let region = regions.find((r) => r.defaultRegion === true);
                this.selectedRegionId = region ? region.id : null;
            },
        },
        programTypes: {
            immediate: true,
            handler: function (programTypes) {
                let programType = programTypes.find(
                    (pt) => pt.defaultProgramType === true
                );
                this.selectedProgramTypeId = programType
                    ? programType.id
                    : null;
            },
        },
    },
    mounted() {
        this.fetchInitPanelPages();
    },
    methods: {
        fetchInitPanelPages() {
            this.$store.commit("panelPages_set", {isBusy: true});
            this.$store
                .dispatch("panelPages_fetch")
                .catch((error) => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.fetch"),
                    });
                })
                .finally(() => {
                    this.$store.commit("panelPages_set", {isBusy: false});
                });
        },
    },
};
</script>

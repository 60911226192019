<template>
    <div class="text-center inline-block">
        <span class="mr-2 align-middle text-xl text-grey-500">
            {{ $t("panelManager.regions.regionTypeSelectLabel") }}
        </span>
        <SelectPopover
            :value="regionId"
            :options="regionOptions"
            font-size="xxl"
            class="text-left align-middle"
            @change="(v) => $emit('change', v)"
        />
    </div>
</template>

<script>
import {mapState} from "vuex";
import SelectPopover from "@mediarails/vue-select-popover";
export default {
    name: "RegionSelect",
    components: {SelectPopover},
    props: {
        regionId: {type: [String, Number], default: null},
    },
    data: () => ({}),
    computed: mapState({
        regions: (s) => s.static.regions,
        regionOptions() {
            return this.regions.map((r) => ({
                label: r.displayName,
                value: r.id,
            }));
        },
    }),
    watch: {},
    mounted() {},
    methods: {},
};
</script>

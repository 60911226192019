var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isBusy)?_c('Spinner'):_vm._e(),_c('div',{staticClass:"text-center"},[_c('RegionSelect',{attrs:{"region-id":_vm.selectedRegionId},on:{"change":function (regionId) { return (_vm.selectedRegionId = regionId); }}}),_c('ProgramTypeSelect',{attrs:{"program-type-id":_vm.selectedProgramTypeId},on:{"change":function (programTypeId) { return (_vm.selectedProgramTypeId = programTypeId); }}})],1),_c('div',{staticClass:"text-center mt-8"},_vm._l((_vm.panelStatusTabs),function(t,i){return _c('div',{key:t.key,staticClass:"inline-block w-17 py-1 border border-grey cursor-pointer shadow-lg",class:{
                'hover:bg-light': !t.selected,
                'shadow-inner': t.selected,
                'text-white': t.selected,
                'bg-impact-bright-blue': t.selected,
                'border-impact-dark-blue': t.selected,
                'rounded-l': i === 0,
                'rounded-r': i === _vm.panelStatusTabs.length - 1,
            },staticStyle:{"margin-left":"-1px"},on:{"click":function($event){_vm.selectedStatusTab = t.key}}},[_vm._v(" "+_vm._s(t.label)+" ")])}),0),_c('div',{staticClass:"mt-7 pb-15"},[(_vm.selectedStatusTab === 'active')?_c('ActivePanelsView',{attrs:{"max-panels":_vm.maxPanelsPerPage,"selected-region-id":_vm.selectedRegionId,"selected-program-type-id":_vm.selectedProgramTypeId}}):_c('UnpublishedPanelsView',{attrs:{"max-panels":_vm.maxPanelsPerPage,"selected-region-id":_vm.selectedRegionId,"selected-page-type-id":_vm.selectedPageTypeId,"selected-program-type-id":_vm.selectedProgramTypeId}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <SimpleDialog
        ref="dialog"
        :busy="isBusy"
        :title="$i18n.t('listings.editorialRemoveTagsModal.title')"
        :description="$i18n.t('listings.editorialRemoveTagsModal.description')"
        :action-label="
            $i18n.t('listings.editorialRemoveTagsModal.action.removeEtag')
        "
        :action-enabled="!!selectedTagsToRemove.length"
        :action-busy="isBusy"
        @action="saveChanges"
        @hide="$hide"
    >
        <div style="width: 500px">
            <MultiselectInput
                :label="
                    $i18n.t('listings.editorialRemoveTagsModal.form.tagSelect')
                "
                :options="tags"
                :value="selectedTagsToRemove"
                class="flex-1"
                :placeholder="
                    $i18n.t(
                        'listings.editorialRemoveTagsModal.form.selectPlaceholder'
                    )
                "
                @change="tagSelected"
            />
        </div>
    </SimpleDialog>
</template>

<script>
import Vue from "vue";
import {uniqBy} from "lodash";
import {mapState} from "vuex";
import SimpleDialog from "@mediarails/vue-modal-simple-dialog";
import MultiselectInput from "@mediarails/vue-multiselect-input";

export default {
    name: "ModalRemoveEtags",
    components: {SimpleDialog, MultiselectInput},
    data() {
        return {
            selectedListingIds: [],
            selectedTagsToRemove: [],
            isBusy: false,
        };
    },
    computed: {
        ...mapState({listings: (state) => state.listings.listings}),
        tags() {
            let listings = this.listings.filter((listing) =>
                this.selectedListingIds.includes(listing.id)
            );
            return uniqBy(
                listings.reduce((temp, listing) => {
                    let tags = listing.editorialTags.map((tag) => ({
                        value: tag,
                        label: tag,
                    }));
                    temp = [...temp, ...tags];
                    return temp;
                }, []),
                "value"
            );
        },
    },
    methods: {
        $show(listingIds) {
            this.selectedListingIds = listingIds;
            this.$refs.dialog.$show();
        },
        $hide() {
            this.selectedListingIds = [];
            this.isBusy = false;
            this.selectedTagsToRemove = [];
            this.$refs.dialog.$hide();
        },
        tagSelected(tags) {
            this.selectedTagsToRemove = tags;
        },
        saveChanges() {
            this.isBusy = true;
            this.$store
                .dispatch("listings_addRemoveTags", {
                    listingIds: this.selectedListingIds,
                    removeTags: this.selectedTagsToRemove,
                    scheme: "editorial",
                })
                .then(() => {
                    this.isBusy = false;
                    this.$emit("hide");
                    this.$hide();
                    this.$notify({
                        type: "success",
                        text: this.$i18n.t(
                            "listings.editorialRemoveTagsModal.etagRemoveSuccess"
                        ),
                    });
                })
                .catch((error) => {
                    this.isBusy = false;
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.save"),
                    });
                });
        },
    },
};
</script>

import Ajax from "@mediarails/util-ajax";
import _ from "lodash";
import apiEnv from "./environment";

const errorPrefix = "API:panel-pages";

export default (apiUtil) => {
    return {
        async fetch() {
            try {
                let response = await Ajax.get(
                    apiEnv.urls.pages,
                    null,
                    null,
                    true,
                    true,
                    await apiUtil.getAjaxConfig()
                );
                let pages = response && response.pages ? response.pages : [];
                pages.forEach((page) => {
                    if (page.panels) {
                        page.panels = page.panels.map((p) =>
                            formatPanelDataFromServer(p)
                        );
                    }
                });
                return pages;
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:fetch()`);
            }
        },

        async edit(panelPage) {
            /*
                Server page format
                {
                    id: 1,
                    pageTypeId: 1,
                    regionId: 1,
                    programTypeId: 1,
                    name: "United States",
                    draft: true,
                    publishAt: "2020-01-08 04:18",
                    panelIds: [1, 8, 5, 2]
                }
            */
            let payload = {
                id: panelPage.id,
                page_type_id: panelPage.pageTypeId,
                region_id: panelPage.regionId,
                program_type_id: panelPage.programTypeId,
                name: panelPage.name,
                draft: true,
                panel_ids: panelPage.panels.map((x) => x.id),
            };
            try {
                let response = await Ajax.post(
                    apiEnv.urls.pages,
                    payload,
                    null,
                    true,
                    true,
                    await apiUtil.getAjaxConfig()
                );
                if (!response || response.succeeded === false)
                    throw new Error("Error editing panel page.");
                return response || {};
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:edit()`);
            }
        },

        async create(panelPage) {
            /*
                // Server page format
                {
                    page_type_id: 1,
                    region_id: 1,
                    program_type_id: 1,
                    name: "United States",
                    draft: true,
                    panel_ids: [1, 8, 5, 2]
                }
            */
            let payload = {
                page_type_id: panelPage.pageTypeId,
                region_id: panelPage.regionId,
                program_type_id: panelPage.programTypeId,
                name: panelPage.name,
                draft: true,
                panel_ids: panelPage.panels.map((x) => x.id),
            };

            try {
                let response = await Ajax.post(
                    apiEnv.urls.pages,
                    payload,
                    null,
                    true,
                    true,
                    await apiUtil.getAjaxConfig()
                );
                if (!response || response.succeeded === false)
                    throw new Error("Error creating panel page.");
                return response || {};
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:create()`);
            }
        },

        async publish(panelPage) {
            /*
                // Server page format
                {
                    id: 1, // include if page already exists
                    page_type_id: 1,
                    region_id: 1,
                    program_type_id: 1,
                    name: "United States",
                    draft: false,
                    publish_at: "2020-01-08 04:12"
                    panel_ids: [1, 8, 5, 2] // must fill in the max panels for this page
                }
            */
            let now = new Date();
            let publishAt = `${now.getUTCFullYear()}-${
                now.getUTCMonth() + 1
            }-${now.getUTCDate()} ${now.getUTCHours()}:${now.getUTCMinutes()}:${now.getUTCSeconds()}`;

            let payload = {
                page_type_id: panelPage.pageTypeId,
                region_id: panelPage.regionId,
                program_type_id: panelPage.programTypeId,
                name: panelPage.name,
                draft: false,
                publish_at: publishAt,
                panel_ids: panelPage.panels.map((x) => x.id),
            };

            if (panelPage.id) payload.id = panelPage.id;

            try {
                let response = await Ajax.post(
                    apiEnv.urls.pages,
                    payload,
                    null,
                    true,
                    true,
                    await apiUtil.getAjaxConfig()
                );
                if (!response || response.succeeded === false)
                    throw new Error("Error creating panel page.");
                return response || {};
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:publish()`);
            }
        },

        async delete(pageIds = []) {
            try {
                let response = await Ajax.delete(
                    `${apiEnv.urls.pages}?ids=${pageIds.join(",")}`,
                    null,
                    true,
                    true,
                    await apiUtil.getAjaxConfig()
                );
                if (!response || response.succeeded === false)
                    throw new Error("Error deleting a panel page.");
                return response || {};
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:create()`);
            }
        },
    };
};

function formatPanelDataFromServer(panel) {
    /*
    // Server Panel Format
    {
        "id": 1,
        "name": "Valenties Days - English",
        "usage": {
            "published": 3,
            "scheduled": 1,
            "drafts": 0
        },
        "content": {
            "title": "OUR FAVORITES",
            "logoUrl": "https://storage.googleapis.com/marketplace-web/homepage/valentines-2020.jpg",
            "headLine": "Valentines Day",
            "searchTerm": "tags%3Acoupleaccount",
            "description": "Fall head over heels for our influencer couples."
          }
        }
    */
    return {
        id: panel.id,
        name: panel.name,
        usageAsPublished: panel.published,
        usageAsScheduled: panel.scheduled,
        usageAsDraft: panel.drafts,
        title: panel.content.title,
        logoUrl: panel.content.logoUrl,
        headline: panel.content.headLine,
        searchTerm: (panel.content.searchTerm || "").replace(/^.*%3A/, ""),
        description: panel.content.description,
    };
}

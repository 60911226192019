<template>
    <div
        class="flex absolute bottom-0 left-0 w-full px-6 py-4 text-white"
        style="background-color: rgba(0,0,0,0.5); align-items: center;"
    >
        <div class="flex-1 text-sm">
            <span class="font-bold">
                {{
                    $t(
                        "listings.reviewModal.mediaProperties.screenshotDateLabel"
                    )
                }}
            </span>
            {{
                screenshotDate
                    ? $d(screenshotDate, "short")
                    : $t(
                          "listings.reviewModal.mediaProperties.screenshotMetaUnavailableLabel"
                      )
            }}
        </div>
        <div class="flex-1 text-sm">
            <span class="font-bold">
                {{
                    $t(
                        "listings.reviewModal.mediaProperties.screenshotCaptureCountLabel"
                    )
                }}
            </span>
            {{
                screenshotCaptureCount ||
                    $t(
                        "listings.reviewModal.mediaProperties.screenshotMetaUnavailableLabel"
                    )
            }}
        </div>
        <div class="flex-1 text-right">
            <Button
                small
                :danger="screenshotFlaggedForRecapture"
                @click="$emit('toggleFlagging')"
            >
                <Icon
                    v-if="screenshotFlaggedForRecapture"
                    name="flagSolid"
                    class="mr-1"
                    size="sm"
                />
                {{
                    screenshotFlaggedForRecapture
                        ? $t(
                              "listings.reviewModal.mediaProperties.screenshotFlaggedButton"
                          )
                        : $t(
                              "listings.reviewModal.mediaProperties.screenshotFlagButton"
                          )
                }}
            </Button>
        </div>
    </div>
</template>

<script>
import Icon from "@mediarails/vue-fa-icon";
import Button from "@mediarails/vue-button";
export default {
    name: "MediaPropertyTabsScreenshotMetaActions",
    components: {Icon, Button},
    props: {
        screenshotDate: {type: Date, default: null},
        screenshotCaptureCount: {type: Number, default: null},
        screenshotFlaggedForRecapture: {type: Boolean, default: false},
    },
    data: () => ({}),
    computed: {},
    watch: {},
    mounted() {},
    methods: {},
};
</script>

<style scoped></style>

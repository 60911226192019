<template>
    <div>
        <div class="text-center text-sm max-w-3xl mx-auto">
            {{ $t("panelManager.regions.activeTabDescription") }}
        </div>
        <div class="active-panels mt-6">
            <PanelPreview
                v-for="(p, i) in activePanels"
                :key="`${p.id}-${i}`"
                :headline="p.headline"
                :title="p.title"
                :description="p.description"
                :logo-url="p.logoUrl"
                :clickable="false"
            />
            <PanelPreview
                v-for="i in countOfEmptyPanels"
                :key="`empty-${i}`"
                :clickable="false"
            />
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import enumPanelStatus from "@/models/enum-panel-status";
import PanelPreview from "@/components/PanelPreview.vue";
export default {
    name: "ActivePanelsView",
    components: {PanelPreview},
    props: {
        maxPanels: {type: Number, default: 0},
        selectedRegionId: {type: Number, default: null},
        selectedProgramTypeId: {type: Number, default: null},
    },
    data: () => ({}),
    computed: mapState({
        countOfEmptyPanels() {
            return Math.max(
                this.maxPanels - (this.activePanels || []).length,
                0
            );
        },
        activePanelPage() {
            let pages = this.$store.state.panelManager.panelPages.pages;
            return pages && pages.length
                ? pages.find(
                      (p) =>
                          p.status === enumPanelStatus.published &&
                          p.regionId === this.selectedRegionId &&
                          p.programTypeId === this.selectedProgramTypeId
                  )
                : null;
        },
        activePanels() {
            return this.activePanelPage && this.activePanelPage.panels
                ? this.activePanelPage.panels
                : [];
        },
    }),
    watch: {},
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.active-panels {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
}
</style>

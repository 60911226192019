export default [
    {
        label: "Prospect Discovery",
        iconName: "usersClassSolid",
        items: [
            {
                label: "Prospects",
                key: "/listings",
                selectOnUrlPattern: "/listings",
            },
            {
                label: "Panel Manager",
                key: "/panelmanager",
                selectOnUrlPattern: "/panelmanager",
            },
        ],
    },
];

class LocalStorageService {
    static userPreferenceKey(key) {
        return `${key}::preferences`;
    }

    static setUserPreference({storageKey, preferenceKey, value}) {
        let storageValue = JSON.stringify({[preferenceKey]: value});
        localStorage.setItem(
            LocalStorageService.userPreferenceKey(storageKey),
            storageValue
        );

        return {[preferenceKey]: value};
    }

    static getUserPreference({storageKey, preferenceKey}) {
        let storageValue = localStorage.getItem(
            LocalStorageService.userPreferenceKey(storageKey)
        );
        if (storageValue) return JSON.parse(storageValue)[preferenceKey];
    }
}

export default LocalStorageService;

<template>
    <div
        ref="scrollDiv"
        class="shadow-sidebar px-6 py-5 text-white overflow-y-auto"
        :class="{'sub-height': sidebarMode == 'enhance'}"
    >
        <div class="text-lg">
            {{ $t("listings.reviewModal.shadowValues.title") }}
        </div>
        <div class="text-xs mt-3 mb-6">
            {{ $t("listings.reviewModal.shadowValues.desc") }}
            <span class="text-tertiary">
                {{ $t("listings.reviewModal.shadowValues.valuesDiffDesc") }}
            </span>
        </div>
        <div v-if="!shadows || !shadows.length" class="text-sm">
            {{ $t("listings.reviewModal.shadowValues.noValues") }}
        </div>
        <div v-if="shadows && shadows.length > 1" class="flex mb-3">
            <div
                v-for="(shadow, index) in shadows"
                :key="`shadow-tab-${index}`"
                class="flex-1 text-xs text-center px-2 py-2 rounded-tl rounded-tr mb-5 cursor-pointer"
                :class="{'font-bold': index == selectedShadowIndex}"
                style="background-color:rgba(0,0,0,0.5); margin: 0 3px;"
                @click="selectedShadowIndex = index"
            >
                {{ `Shadow ${index + 1}` }}
            </div>
        </div>
        <ShadowListingFormMirror
            v-if="shadows && shadows.length > 0"
            :shadow-data="shadows[selectedShadowIndex]"
            :listing-data="editedListing"
            @valueNotMatchingCount="v => $emit('valueNotMatchingCount', v)"
        />
    </div>
</template>

<script>
import {mapState} from "vuex";
import _ from "lodash";
import ShadowListingFormMirror from "./ShadowValues/ShadowListingFormMirror.vue";
export default {
    name: "ListingsReviewModalShadowValues",
    components: {ShadowListingFormMirror},
    props: {},
    data: () => ({
        selectedShadowIndex: 0,
    }),
    computed: mapState({
        sidebarMode: s => s.reviewModal.mode,
        editedListing: s => s.listings.editedListing,
        sidebarFormScrollTop: s => s.reviewModal.sidebarFormScrollTop,
        listing() {
            let s = this.$store.state;
            let listings = s.listings.listings;
            let selectedIndex = s.listings.selectedListingIndex;
            return listings[selectedIndex];
        },
        shadows() {
            if (!this.listing || !Array.isArray(this.listing.shadows))
                return [];
            return this.listing.shadows;
        },
    }),
    watch: {
        listing: {
            immediate: true,
            handler: function() {
                this.selectedShadowIndex = 0;
            },
        },
        sidebarFormScrollTop(sidebarFormScrollTop) {
            this.$refs.scrollDiv.scrollTop = sidebarFormScrollTop;
        },
    },
};
</script>

<style scoped>
.shadow-sidebar {
    position: absolute;
    width: 500px;
    height: calc(100% - 53px);
    left: -500px;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
}

.sub-height {
    height: calc(100% - 118px);
}
</style>

<template>
    <ServerTable
        ref="table"
        :data="panels"
        :sort-column-name="sortColumnName"
        :sort-column-asc="sortColumnAsc"
        :empty-text="$t('panelManager.panels.table.emptyMessage')"
        compact
        :can-select-rows="userCanDeletePanels"
        :action-options="actionOptions"
        :total-count="totalServerCount"
        :page-index="pageIndex"
        :page-size="pageSize"
        @rowAction="handleRowAction"
        @rowClick="handleRowClick"
        @pageChange="handlePageChange"
        @sort="handleSort"
    >
        <TableColumn
            name="name"
            :label="$t('panelManager.panels.table.colName')"
            has-slot
            :class-data-func="columnClassFunction"
        />
        <template slot="name" slot-scope="props">
            <div class="flex items-center">
                <div>
                    <Avatar
                        :image-url="props.data.logoUrl"
                        width="80px"
                        height="60px"
                        class="mr-3"
                    />
                </div>
                <div class="flex-1">
                    {{ props.data.name }}
                </div>
            </div>
        </template>

        <TableColumn
            name="status"
            no-label
            has-slot
            :sortable="false"
            :class-data-func="columnClassFunction"
        />
        <template slot="status" slot-scope="props">
            <template v-if="props.data.usageAsPublished > 0">
                <Tooltip
                    :content="
                        $t('panelManager.panels.table.panelIsPublishedTooltip')
                    "
                >
                    <Icon name="flagSolid" />
                </Tooltip>
            </template>
            <template v-else-if="props.data.usageAsDraft > 0">
                <Tooltip
                    :content="
                        $t('panelManager.panels.table.panelIsDraftTooltip')
                    "
                >
                    <Icon name="flag" />
                </Tooltip>
            </template>
        </template>

        <TableColumn
            name="editorialTag"
            :label="$t('panelManager.panels.table.colEditorialTag')"
            has-slot
        />
        <template slot="editorialTag" slot-scope="props">
            {{ (editorialTags[props.data.searchTerm] || {}).name }}
        </template>

        <TableColumn
            name="usageAsPublished"
            format="int"
            align="center"
            :label="$t('panelManager.panels.table.colPublished')"
        />

        <TableColumn
            name="usageAsDraft"
            format="int"
            align="center"
            :label="$t('panelManager.panels.table.colDrafts')"
        />

        <TableColumn
            name="createdAt"
            format="date"
            :label="$t('panelManager.panels.table.colCreatedAt')"
            no-wrap
        />

        <TableColumn
            name="updatedAt"
            format="date"
            :label="$t('panelManager.panels.table.colUpdatedAt')"
            no-wrap
        />
    </ServerTable>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import {mapState} from "vuex";
import {ServerTable, TableColumn} from "@mediarails/vue-table";
import EnumPanelTableActions from "@/models/enum-panel-table-actions";
import Avatar from "@mediarails/vue-avatar";
import Icon from "@mediarails/vue-fa-icon";
import Tooltip from "@mediarails/vue-impact-tooltip";
export default {
    name: "PanelsTable",
    components: {ServerTable, TableColumn, Avatar, Icon, Tooltip},
    props: {},
    data: () => ({}),
    computed: mapState({
        panels: s => s.panelManager.panels.panels,
        totalServerCount: s => s.panelManager.panels.totalServerCount,
        pageIndex: s => s.panelManager.panels.pageIndex,
        pageSize: s => s.panelManager.panels.pageSize,
        sortColumnName: s => s.panelManager.panels.sortColumnName,
        sortColumnAsc: s => s.panelManager.panels.sortColumnAsc,
        actionOptions() {
            return [
                {
                    value: EnumPanelTableActions.delete,
                    label: this.$t("panelManager.panels.table.deleteAction"),
                },
            ];
        },
        editorialTags() {
            return _.keyBy(this.$store.state.static.editorialTags, x => x.id);
        },
        userCanDeletePanels() {
            return this.$store.getters.userHasPermission("canDeletePanels");
        },
        openReviewModalDisabled() {
            let userCantReviewOrEnhance =
                !this.userRole.permissions.canEnhance &&
                !this.userRole.permissions.canReview;
            return !this.filteredTotalCount || userCantReviewOrEnhance;
        },
    }),
    watch: {},
    mounted() {},
    methods: {
        $deselectAllRows() {
            this.$refs.table.$deselectAllRows();
        },
        handleRowAction({action, rows, rowsArray}) {
            if (action === EnumPanelTableActions.delete) {
                this.$emit("panelDelete", rowsArray);
            }
        },
        handleRowClick(row) {
            this.$emit("panelEdit", row);
        },
        handlePageChange(pageIndex) {
            this.$store.commit("panels_set", {isBusy: true, pageIndex});
            this.$store
                .dispatch("panels_fetch")
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.fetch"),
                    });
                })
                .finally(() => {
                    this.$store.commit("panels_set", {isBusy: false});
                });
        },
        handleSort({sortAsc, column}) {
            this.$store.commit("panels_set", {
                isBusy: true,
                sortColumnName: column,
                sortColumnAsc: sortAsc,
            });
            this.$store
                .dispatch("panels_fetch")
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.fetch"),
                    });
                })
                .finally(() => {
                    this.$store.commit("panels_set", {isBusy: false});
                });
        },
        columnClassFunction(rowData, columnInfo) {
            if (rowData.usageAsPublished > 0) return ["text-impact-red"];
            else if (rowData.usageAsDraft > 0) return ["text-tertiary"];
            else return [];
        },
    },
};
</script>

<template>
    <div class="flex flex-col h-full relative">
        <div>
            <Spinner v-if="isBusy" />
            <div class="bg-lighter flex rounded-tl-lg flex-wrap">
                <div
                    v-for="(mp, i) in mediaProperties"
                    :key="`${mp.url}-${i}`"
                    class="flex-1 text-center px-6 py-4 truncate cursor-pointer hover:text-black border-grey text-sm"
                    :class="{
                        'border-l': i > 0,
                        'border-b':
                            (selectedMediaPropertyIndex !== i &&
                                mediaProperties.length > 1) ||
                            (mediaProperties.length == 1 &&
                                sidebarMode == 'review') ||
                            selectedMediaPropertyIndex == -1,
                        'bg-dark':
                            selectedMediaPropertyIndex == i &&
                            (mediaProperties.length > 1 ||
                                sidebarMode == 'enhance'),
                        'rounded-tl-lg': i == 0,
                        'bg-impact-red': mediaPropertyHasIssues(mp),
                        'text-white': mediaPropertyHasIssues(mp),
                        'hover:text-impact-grey': mediaPropertyHasIssues(mp),
                    }"
                    @click="selectedMediaPropertyIndex = i"
                >
                    <Icon
                        name="exclamationTriangle"
                        size="lg"
                        class="text-white"
                        v-if="mediaPropertyHasIssues(mp)"
                    />
                    <template v-else>
                        <Icon
                            name="checkCircleSolid"
                            size="sm"
                            class="text-secondary"
                            v-if="mp.approved === true"
                        />
                        <Icon
                            name="timesOctagonSolid"
                            size="sm"
                            class="text-danger"
                            v-else-if="mp.approved === false"
                        />
                        <Icon
                            name="instagram"
                            size="sm"
                            v-else-if="mp.mediaType == 'instagram'"
                        />
                        <Icon name="link" size="sm" v-else />
                        <Icon
                            v-if="mp.primary === true"
                            class="text-secondary ml-1"
                            name="starSolid"
                            size="sm"
                        />
                    </template>
                    {{ mp.url | url }}
                </div>
                <div
                    v-if="sidebarMode == 'enhance'"
                    class="text-center px-6 py-4 truncate cursor-pointer hover:text-secondary border-grey border-l text-sm"
                    :class="{
                        'bg-dark': selectedMediaPropertyIndex == -1,
                        'border-b': selectedMediaPropertyIndex != -1,
                    }"
                    @click="selectedMediaPropertyIndex = -1"
                >
                    <Icon name="plus" size="sm" />
                    {{ $t("listings.reviewModal.mediaProperties.addButton") }}
                </div>
            </div>
            <MediaPropertyActionBar
                :selected-media-property-index="selectedMediaPropertyIndex"
                @delete="selectedMediaPropertyRemoved"
                @add="mediaPropertyAdded"
            />
        </div>
        <MediaPropertyScreenshot
            :selected-media-property-index="selectedMediaPropertyIndex"
        />
        <MediaPropertyMetaActions
            v-if="false && mediaProperties.length && sidebarMode == 'enhance'"
            :screenshot-date="selectedMediaProperty.screenshotDate"
            :screenshot-capture-count="
                selectedMediaProperty.screenshotCaptureCount
            "
            :screenshot-flagged-for-recapture="
                selectedMediaProperty.screenshotFlaggedForRecapture
            "
            @toggleFlagging="onScreenshotFlaggingToggle"
        />
    </div>
</template>

<script>
import {mapState} from "vuex";
import Icon from "@mediarails/vue-fa-icon";
import Spinner from "@mediarails/vue-spinner";
import MediaPropertyScreenshot from "./MediaPropertyTabs/Screenshot.vue";
import MediaPropertyActionBar from "./MediaPropertyTabs/ActionBar.vue";
import MediaPropertyMetaActions from "./MediaPropertyTabs/ScreenshotMetaActions.vue";
export default {
    name: "ListingsReviewModalMediaPropScreenshotTabs",
    components: {
        Icon,
        Spinner,
        MediaPropertyScreenshot,
        MediaPropertyActionBar,
        MediaPropertyMetaActions,
    },
    filters: {
        url(value = "") {
            return value.replace(/^https?:\/\/(www.)?/, "");
        },
    },
    data: () => ({
        selectedMediaPropertyIndex: 0,
    }),
    computed: mapState({
        isBusy: s => s.reviewModal.isBusy,
        sidebarMode: s => s.reviewModal.mode,
        editedListing: s => s.listings.editedListing,
        mediaProperties() {
            return (this.editedListing || {}).mediaProperties || [];
        },
        selectedMediaProperty() {
            return this.mediaProperties && this.mediaProperties.length
                ? this.mediaProperties[this.selectedMediaPropertyIndex]
                : {};
        },
    }),
    watch: {
        mediaProperties(value = []) {
            let endIndex = value.length - 1;
            this.selectedMediaPropertyIndex = Math.max(
                0,
                this.selectedMediaPropertyIndex
            );
            this.selectedMediaPropertyIndex = Math.min(
                this.selectedMediaPropertyIndex,
                endIndex
            );
        },
    },
    methods: {
        selectedMediaPropertyRemoved() {
            this.selectedMediaPropertyIndex = Math.max(
                0,
                this.selectedMediaPropertyIndex - 1
            );
        },
        mediaPropertyAdded() {
            this.selectedMediaPropertyIndex = this.mediaProperties.length - 1;
        },
        mediaPropertyHasIssues(mp) {
            let issues = mp.issues || [];
            return issues.filter(i => i.dismissed === false).length > 0;
        },
        onScreenshotFlaggingToggle() {
            this.$store.commit(
                "listings_update_editedListingValueMediaProperty",
                {
                    name: "screenshotFlaggedForRecapture",
                    value: !this.selectedMediaProperty
                        .screenshotFlaggedForRecapture,
                    index: this.selectedMediaPropertyIndex,
                }
            );
        },
    },
};
</script>

<template>
    <SimpleDialog
        ref="dialog"
        :title="$i18n.t('listings.workflowTagsModal.title')"
        :description="$i18n.t('listings.workflowTagsModal.description')"
        :busy="isBusy"
        :action-label="$i18n.t('listings.workflowTagsModal.action')"
        @action="saveChangesAndHide"
        @hide="$hide(false)"
    >
        <div class="flex" style="width:500px">
            <div class="border-r pr-7 pb-2" style="width:50%">
                <div class="flex">
                    <MultiselectInput
                        :label="
                            $i18n.t('listings.workflowTagsModal.form.tagSelect')
                        "
                        :options="workflowTagOptions"
                        :max="1"
                        class="flex-1"
                        :placeholder="
                            $i18n.t(
                                'listings.workflowTagsModal.form.selectPlaceholder'
                            )
                        "
                        @change="selectTag"
                    />
                </div>
                <div class="flex mt-5">
                    <TextInput
                        large
                        :value="newGroupTempValue"
                        :label="
                            $i18n.t('listings.workflowTagsModal.form.tagCreate')
                        "
                        class="flex-1"
                        :placeholder="
                            $i18n.t(
                                'listings.workflowTagsModal.form.createPlaceholder'
                            )
                        "
                        pattern="[a-z0-9-]*"
                        @input="v => (newGroupTempValue = v)"
                        @enter="
                            v => (newGroupValueIsValid ? selectTag(v) : false)
                        "
                        @validity="isValid => (newGroupValueIsValid = isValid)"
                    />
                    <Button
                        class="mt-5 ml-1"
                        :disabled="!newGroupValueIsValid"
                        @click="selectTag(newGroupTempValue)"
                    >
                        {{ $t("listings.workflowTagsModal.buttonAddGroup") }}
                    </Button>
                </div>
            </div>
            <div style="width:20px;position:relative;">
                <Icon
                    name="caretRight"
                    size="lg"
                    class="absolute"
                    style="top:50%;left:0;transform:translateY(-50%);color:lightGrey;"
                />
            </div>
            <div class="pl-3 flex-1">
                <div>
                    {{ $t("listings.workflowTagsModal.currentGroupValues") }}
                </div>
                <Tag
                    class="mt-3"
                    large
                    v-for="(t, i) in workflowTags"
                    :key="`${t}-${i}`"
                >
                    {{ t }}
                    <Icon
                        name="times"
                        size="sm"
                        clickable
                        danger
                        class="ml-1"
                        @click="unselectTag(t)"
                    />
                </Tag>
            </div>
        </div>
        <div class="text-sm text-grey mt-5">
            <template v-if="workflowTagsAdded.length">
                {{
                    $tc(
                        "listings.workflowTagsModal.addDescription",
                        workflowTagsAdded.length,
                        {count: workflowTagsAdded.length}
                    )
                }}
            </template>
            <template v-if="workflowTagsRemoved.length">
                {{
                    $tc(
                        "listings.workflowTagsModal.removeDescription",
                        workflowTagsRemoved.length,
                        {count: workflowTagsRemoved.length}
                    )
                }}
            </template>
        </div>
    </SimpleDialog>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import {mapState} from "vuex";
import SimpleDialog from "@mediarails/vue-modal-simple-dialog";
import {notDeepEqual} from "assert";
import TextInput from "@mediarails/vue-text-input";
import Button from "@mediarails/vue-button";
import Tag from "@mediarails/vue-tag";
import MultiselectInput from "@mediarails/vue-multiselect-input";
import Icon from "@mediarails/vue-fa-icon";
import {Promise} from "q";
export default {
    name: "ModalWorkflowTags",
    components: {SimpleDialog, TextInput, Button, Tag, MultiselectInput, Icon},
    data: () => ({
        isBusy: false,
        selectedRowIds: [],
        clearTableSelection: null,
        originalWorkflowTags: [],
        workflowTags: [],
        newGroupTempValue: null,
        newGroupValueIsValid: true,
    }),
    computed: mapState({
        listings: s => s.listings.listings,
        workflowTagOptions() {
            return this.$store.state.static.workflowTags
                .filter(t => !this.workflowTags.includes(t))
                .map(t => ({label: t, value: t}));
        },
        workflowTagsAdded() {
            return _.difference(this.workflowTags, this.originalWorkflowTags);
        },
        workflowTagsRemoved() {
            return _.difference(this.originalWorkflowTags, this.workflowTags);
        },
    }),
    watch: {
        selectedRowIds: {
            handler: function(ids) {
                const workflowTags = [];
                ids.forEach(id => {
                    const listing = this.listings.find(x => x.id == id);
                    const listingWorkflowTags = listing.workflowTags || [];
                    workflowTags.push(...listingWorkflowTags);
                });
                this.workflowTags = _.uniq(workflowTags);
                this.originalWorkflowTags = _.uniq(workflowTags);
            },
        },
    },
    methods: {
        $show(selectedRowIds, clearSelection) {
            this.selectedRowIds = selectedRowIds;
            this.clearTableSelection = clearSelection;
            this.$refs.dialog.$show();
        },
        $hide(clearSelection = true) {
            this.$refs.dialog.$hide();
            if (clearSelection) this.clearTableSelection();
        },
        saveChangesAndHide() {
            this.isBusy = true;
            this.$store
                .dispatch("listings_addRemoveTags", {
                    listingIds: this.selectedRowIds,
                    addTags: this.workflowTagsAdded,
                    removeTags: this.workflowTagsRemoved,
                    scheme: "workflow",
                })
                .then(() => {
                    this.isBusy = false;
                    this.$hide();
                })
                .catch(error => {
                    this.isBusy = false;
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.save"),
                    });
                });
        },
        selectTag(value) {
            if (!this.workflowTags.find(t => t === value)) {
                this.workflowTags.push(value);
            }
            this.newGroupTempValue = null;
        },
        unselectTag(value) {
            const index = this.workflowTags.indexOf(value);
            Vue.delete(this.workflowTags, index);
        },
    },
};
</script>

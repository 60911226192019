<template>
    <Modal
        :busy="busy"
        :show="showModal"
        :close-on-mask-click="false"
        :close-on-esc="false"
        :padding="false"
        :full-screen="true"
    >
        <div
            class="absolute -mt-3 -mr-2 right-0 top-0 rounded-full bg-white cursor-pointer px-2 py-1 shadow-md text-lg font-bold hover:text-danger hover:bg-dark z-50"
            @click="emitAndClose"
        >
            &times;
        </div>
        <div class="h-full flex">
            <div
                class="flex-1 rounded-l-lg"
                style="max-width:calc(100% - 550px)"
            >
                <slot name="content" />
            </div>
            <div
                class="flex-0 bg-lighter border-grey border-l rounded-r-lg"
                style="width:550px;min-width:550px;max-width:550px;position:relative;"
            >
                <slot name="shadowSidebar" />
                <slot name="sidebar" />
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "@mediarails/vue-modal";
export default {
    name: "ModalReview",
    components: {Modal},
    props: {
        busy: {type: Boolean, default: false},
    },
    data: () => ({
        showModal: false,
    }),
    methods: {
        emitAndClose() {
            this.showModal = false;
            this.$emit("close");
        },
        $show() {
            this.showModal = true;
        },
        $hide() {
            this.showModal = false;
        },
    },
};
</script>

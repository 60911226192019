<template>
    <div class="flex flex-col h-full">
        <div class="px-5 py-5">
            <SidebarHeader />
        </div>
        <div style="min-height:142px;">
            <div>
                <SidebarEnhanceActions
                    v-show="sidebarMode == 'enhance'"
                    @save="saveListingEdits"
                />
                <SidebarReviewActions
                    v-show="sidebarMode == 'review'"
                    @approve="approveListing"
                    @reject="rejectListing"
                />
            </div>
            <div
                v-if="hasRadiusPublisherId"
                class="text-danger text-center mb-4 mt-2 text-sm px-3"
            >
                {{ $t("listings.reviewModal.radiusPublisherIdEditWarning") }}
            </div>
        </div>
        <div
            class="mx-5 px-6 py-1 text-sm text-center border-b border-t hover:bg-dark cursor-pointer"
            @click="collapseToggle"
        >
            <template v-if="shadowValuesCollapsed">
                <Icon name="chevronLeft" size="sm" />
                <Icon name="chevronLeft" size="sm" class="mr-2" />
                {{ $t("listings.reviewModal.shadowValues.expandLabel") }}
            </template>
            <template v-else>
                <Icon name="chevronRight" size="sm" />
                <Icon name="chevronRight" size="sm" class="mr-2" />
                {{ $t("listings.reviewModal.shadowValues.collapseLabel") }}
            </template>
            <template v-if="valueNotMatchingCount && shadows && shadows.length">
                ({{
                    $tc(
                        "listings.reviewModal.shadowValues.valuesNotMatchingCount",
                        valueNotMatchingCount,
                        {count: valueNotMatchingCount}
                    )
                }})
            </template>
        </div>
        <div ref="scrollDiv" class="flex-1 px-5 py-5 overflow-y-auto">
            <SidebarEnhanceForm
                ref="sidebarEnhanceForm"
                v-show="sidebarMode == 'enhance'"
            />
            <SidebarReviewContent v-show="sidebarMode == 'review'" />
        </div>
        <Spinner v-if="isBusy" class="z-10" />
    </div>
</template>

<script>
import _ from "lodash";
import {mapState} from "vuex";
import Vue from "vue";
import SidebarHeader from "./SidebarHeader.vue";
import SidebarEnhanceActions from "./SidebarEnhanceActions.vue";
import SidebarReviewActions from "./SidebarReviewActions.vue";
import SidebarEnhanceForm from "./SidebarEnhanceForm.vue";
import SidebarReviewContent from "./SidebarReviewContent.vue";
import Spinner from "@mediarails/vue-spinner";
import Icon from "@mediarails/vue-fa-icon";
export default {
    name: "ListingsReviewModalSidebar",
    components: {
        SidebarHeader,
        SidebarEnhanceActions,
        SidebarReviewActions,
        SidebarEnhanceForm,
        SidebarReviewContent,
        Spinner,
        Icon,
    },
    props: {
        valueNotMatchingCount: {type: Number, default: 0},
    },
    data: () => ({
        shadowValuesCollapsed: true,
    }),
    computed: mapState({
        sidebarMode: s => s.reviewModal.mode,
        isBusy: s => s.reviewModal.isBusy,
        listing() {
            let s = this.$store.state;
            let listings = s.listings.listings;
            let selectedIndex = s.listings.selectedListingIndex;
            return listings[selectedIndex];
        },
        userRoles() {
            return this.$store.getters.sessionUserRoles;
        },
        hasRadiusPublisherId() {
            return this.listing
                ? this.listing.radiusPublisherId != null
                : false;
        },
        shadows() {
            if (!this.listing || !Array.isArray(this.listing.shadows))
                return [];
            return this.listing.shadows;
        },
        hasTasks() {
            return this.listing &&
                this.listing.taskTypes &&
                this.listing.taskTypes.length
                ? true
                : false;
        },
    }),
    watch: {
        userRoles: {
            immediate: true,
            handler: function(role) {
                let canEnhance = this.$store.getters.userHasPermission(
                    "canEnhance"
                );
                let canReview = this.$store.getters.userHasPermission(
                    "canReview"
                );
                if (!canEnhance || canReview) {
                    this.$store.commit("reviewModal_setMode", "review");
                } else {
                    this.$store.commit("reviewModal_setMode", "enhance");
                }
            },
        },
    },
    mounted() {
        this.$refs.scrollDiv.addEventListener(
            "scroll",
            _.throttle(e => {
                let scrollTop = e.target.scrollTop;
                this.$store.commit("reviewModal_set", {
                    sidebarFormScrollTop: scrollTop,
                });
            }, 200)
        );
    },
    methods: {
        saveListingEdits() {
            this.$refs.sidebarEnhanceForm.$saveListingChanges();
        },
        approveListing(statusReason) {
            this.$store.commit("reviewModal_busyStart");
            this.$store
                .dispatch("listings_approve", {
                    listingId: this.listing.id,
                    statusReason,
                })
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.save"),
                    });
                })
                .finally(() => {
                    this.$store.commit("reviewModal_busyStop");
                });
        },
        rejectListing(statusReason) {
            this.$store.commit("reviewModal_busyStart");
            this.$store
                .dispatch("listings_reject", {
                    listingId: this.listing.id,
                    statusReason,
                })
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.save"),
                    });
                })
                .finally(() => {
                    this.$store.commit("reviewModal_busyStop");
                });
        },
        collapseToggle() {
            this.shadowValuesCollapsed = !this.shadowValuesCollapsed;
            this.$emit("shadowCollapse", this.shadowValuesCollapsed);
        },
    },
};
</script>

<style scoped></style>

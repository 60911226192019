<template>
    <div>
        <div style="min-width: 600px" class="flex">
            <div>
                <Search
                    :class="[
                        'mr-1 mb-4',
                        {'text-search-input-error': !!errors.textSearch.msg},
                    ]"
                    style="margin-top: 2px"
                    :input-width="100"
                    :value="listingFilter.search"
                    :always-show="true"
                    real-time-search
                    :real-time-search-delay-m-sec="500"
                    @search="onSearch"
                />
            </div>
            <HelpIcon
                :width="315"
                class="py-2 mr-4 search-help-icon"
                title="Search - keywords help"
            >
                <ul class="pl-3 leading-relaxed list-disc text-xs text-justify">
                    <li>
                        <p style="text-wrap: nowrap;">
                            <code class="font-bold">
                            {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.fullTextSearch.textSearch"
                                    )
                                }}
                            </code>
                            {{
                                $i18n.t(
                                    "listings.searchKeywordsHelp.fullTextSearch.characterLimit"
                                )
                            }}
                        </p>
                    </li>
                    <li>
                        <p>
                            <code class="font-bold">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.listingOrPublisherId.keyword"
                                    )
                                }}
                            </code>
                            {{
                                $i18n.t(
                                    "listings.searchKeywordsHelp.listingOrPublisherId.description"
                                )
                            }}
                            <code class="italic">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.listingOrPublisherId.highlightText"
                                    )
                                }}
                            </code>
                        </p>
                    </li>
                    <li>
                        <p>
                            <code class="font-bold">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.listingId.keyword"
                                    )
                                }}
                            </code>
                            {{
                                $i18n.t(
                                    "listings.searchKeywordsHelp.listingId.description"
                                )
                            }}
                            <code class="italic">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.listingId.highlightText"
                                    )
                                }}
                            </code>
                        </p>
                    </li>
                    <li>
                        <p>
                            <code class="font-bold">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.publisherId.keyword"
                                    )
                                }}
                            </code>
                            {{
                                $i18n.t(
                                    "listings.searchKeywordsHelp.publisherId.description"
                                )
                            }}
                            <code class="italic">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.publisherId.highlightText"
                                    )
                                }}
                            </code>
                        </p>
                    </li>
                    <li>
                        <p>
                            <code class="font-bold">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.listingAndPublisherId.keyword"
                                    )
                                }}
                            </code>
                            {{
                                $i18n.t(
                                    "listings.searchKeywordsHelp.listingAndPublisherId.description"
                                )
                            }}
                            <code class="italic">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.listingAndPublisherId.highlightText"
                                    )
                                }}
                            </code>
                        </p>
                    </li>
                    <li>
                        <p>
                            <code class="font-bold">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.keywordTags.keyword"
                                    )
                                }}
                            </code>
                            {{
                                $i18n.t(
                                    "listings.searchKeywordsHelp.keywordTags.description"
                                )
                            }}
                            <code class="italic">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.keywordTags.highlightText"
                                    )
                                }}
                            </code>
                        </p>
                    </li>
                    <li>
                        <p>
                            <code class="font-bold">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.idTags.keyword"
                                    )
                                }}
                            </code>
                            {{
                                $i18n.t(
                                    "listings.searchKeywordsHelp.idTags.description"
                                )
                            }}
                            <code class="italic">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.idTags.highlightText"
                                    )
                                }}
                            </code>
                        </p>
                    </li>
                    <li>
                        <p>
                            <code class="font-bold">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.keywordEtags.keyword"
                                    )
                                }}
                            </code>
                            {{
                                $i18n.t(
                                    "listings.searchKeywordsHelp.keywordEtags.description"
                                )
                            }}
                            <code class="italic">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.keywordEtags.highlightText"
                                    )
                                }}
                            </code>
                        </p>
                    </li>
                    <li>
                        <p>
                            <code class="font-bold">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.idEtags.keyword"
                                    )
                                }}
                            </code>
                            {{
                                $i18n.t(
                                    "listings.searchKeywordsHelp.idEtags.description"
                                )
                            }}
                            <code class="italic">
                                {{
                                    $i18n.t(
                                        "listings.searchKeywordsHelp.idEtags.highlightText"
                                    )
                                }}
                            </code>
                        </p>
                    </li>
                </ul>
            </HelpIcon>
            <FilterFields
                :initial-field-values="initialFieldValues"
                :field-meta="filterFieldMeta"
                @fieldUpdate="({id, value}) => changeFilter(id, value)"
            />
        </div>
        <div class="text-search-error-msg" v-show="!!errors.textSearch.msg">
            {{  errors.textSearch.msg }}
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Vue from "vue";
import Search from "@mediarails/vue-impact-search";
import FilterFields from "@mediarails/vue-impact-filter-fields";
import HelpIcon from "@mediarails/vue-icon-help";
import {omit, pickBy} from "lodash";
import filterConfig from "@/../listing.filters.config";

export default {
    name: "ListingFilters",
    components: {Search, FilterFields, HelpIcon},
    data: () => ({
        errors: {
            textSearch: {
                msg: null,
            },
        },
    }),
    computed: mapState({
        isBusy: s => s.listings.isBusy,
        users: s => s.static.users,
        workflowTags: s => s.static.workflowTags,
        listingFilter: s => s.listings.filter,
        filteredTotalCount: s => s.listings.filteredTotalCount,
        countsByUser: s => s.listings.countsByUser,
        countsByStatus: s => s.listings.countsByStatus,
        countsByWorkflowTag: s => s.listings.countsByWorkflowTag,
        countsByTaskType: s => s.listings.countsByTaskType,
        countsByIssueType: s => s.listings.countsByIssueType,
        unassignedUserCount: s => s.listings.countsByUser["(unassigned)"] || 0,
        initialFieldValues() {
            return pickBy(
                this.listingFilter,
                (value, key) => ![undefined, null].includes(value)
            );
        },
        assignedToSelectEmptyLabel() {
            if (this.listingFilter.assignedTo) {
                return `${this.$i18n.t(
                    "listings.actions.selectEmpty"
                )} - ${this.$i18n.n(this.unassignedUserCount, "int")}`;
            } else {
                return this.$i18n.t("listings.actions.selectEmpty");
            }
        },
        userOptions() {
            let userCounts = this.countsByUser;
            let filterValue = this.listingFilter.assignedTo;
            let options = this.users.map(u => {
                let countNum = userCounts[u.value];
                let countStr = this.$i18n.n(countNum || 0, "int");
                return {
                    label: countNum ? `${u.label} (${countStr})` : u.label,
                    value: u.value,
                };
            });
            options.unshift({label: "Unassigned", value: "(unassigned)"});
            return options;
        },
        statusOptions() {
            let statusCounts = this.countsByStatus;
            let filterValue = this.listingFilter.status;
            return this.$store.state.static.listingStatuses.map(x => {
                let countNum = statusCounts[x];
                let countStr = this.$i18n.n(countNum || 0, "int");
                let label = x.charAt(0).toUpperCase() + x.slice(1);
                return {
                    label: countNum ? `${label} (${countStr})` : label,
                    value: x,
                };
            });
        },
        workflowTagOptions() {
            const workflowTagCounts = this.countsByWorkflowTag;
            const options = [];
            Object.keys(workflowTagCounts).forEach(k => {
                let countNum = workflowTagCounts[k];
                let countStr = this.$i18n.n(countNum || 0, "int");
                options.push({
                    label: countNum ? `${k} (${countStr})` : k,
                    value: k,
                });
            });
            return options;
        },
        taskTypeOptions() {
            const taskTypeCounts = this.countsByTaskType;
            const options = [];
            Object.keys(taskTypeCounts).forEach(k => {
                let countNum = taskTypeCounts[k];
                let countStr = this.$i18n.n(countNum || 0, "int");
                options.push({
                    label: `${k} (${countStr})`,
                    value: k,
                });
            });
            return options;
        },
        issueTypeOptions() {
            const issueTypeCounts = this.countsByIssueType;
            const options = [];
            Object.keys(issueTypeCounts).forEach(k => {
                let countNum = issueTypeCounts[k];
                let countStr = this.$i18n.n(countNum || 0, "int");
                options.push({
                    label: `${k} (${countStr})`,
                    value: k,
                });
            });
            return options;
        },
        filterFieldMeta() {
            const staticData = this.$store.state.static;
            let filterMeta = filterConfig.map(x => {
                return {
                    id: x.id,
                    label: x.label,
                    type: x.type,
                    disabled: x.disabled,
                    pinned: x.pinned,
                    sort: x.sort,
                    options: x.optionDataProp
                        ? this[x.optionDataProp]
                        : x.getOptions(staticData),
                };
            });

            return filterMeta.filter(f => f.options.length);
        },
    }),
    methods: {
        onSearch(searchValue) {
            // Hitting enter and input triggers search event multiple times so pushing same route multiple times
            // causes NavigationDuplicated: Avoided redundant navigation to current location error
            const searchLimit = 64;
            if (searchValue && searchValue.length >= searchLimit) {
                this.errors.textSearch.msg = this.$i18n.t(
                    "listings.searchKeywordsHelp.fullTextSearch.errorMsg"
                );
                return;
            }
            this.errors.textSearch.msg =  null;
            if (this.listingFilter.search === searchValue) return;

            this.$store.commit("listings_set", {
                sortColumn: "search",
                sortAsc: false,
            });
            this.changeFilter("search", searchValue);
        },
        changeFilter(filterName, value) {
            this.setRouteQueryParams(filterName, value);
        },
        setRouteQueryParams(key, value) {
            let query = {...this.$route.query, [key]: value, page: 1};
            if (!value) {
                query = omit(query, key);
            }
            // Note: Clearing unpinned filter after pinning without setting any filter also fires to set same route
            // While pinning filter route is not set and when clearing it fires fieldUpdate event and tries to same old route.
            this.$router
                .push({
                    name: "listings",
                    query,
                })
                .catch(err => {
                    if (
                        err.name !== "NavigationDuplicated" &&
                        !err.message.includes(
                            "Avoided redundant navigation to current location"
                        )
                    ) {
                        throw err;
                    }
                });
        },
    },
};
</script>
<style lang="scss">
.search-help-icon > .v-popover__content--show {
    transform: translate(-50%, -3%) !important;
}
.text-search-error-msg {
    color: red;
    font-size: 12px;
    margin-top: -12px;
    padding-left: 4px;
}
.text-search-input-error {
    border: 1px solid red !important;
}
</style>

<template>
    <div>
        <PageTabBar
            v-if="pageTabs && pageTabs.length"
            :items="pageTabs"
            :selected-key="selectedPageTabKey"
            @select="onPageTabSelect"
        />
        <div
            class="layout-single-column text-left"
            :style="{'max-width': maxWidth}"
        >
            <slot />
        </div>
    </div>
</template>

<script>
import PageTabBar from "@mediarails/vue-impact-page-tab-bar";
export default {
    name: "LayoutSingleColumn",
    components: {PageTabBar},
    props: {
        pageTabs: {type: Array, default: () => []},
        selectedPageTabKey: {type: String, default: null},
        maxWidth: {type: String, default: "1600px"},
    },
    data: () => ({}),
    watch: {},
    mounted() {},
    methods: {
        onPageTabSelect(tabKey) {
            this.$emit("pageTabSelect", tabKey);
        },
    },
};
</script>

<style scoped>
.layout-single-column {
    margin: 20px auto 40px auto;
    padding: 0 40px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col h-full relative"},[_c('div',[(_vm.isBusy)?_c('Spinner'):_vm._e(),_c('div',{staticClass:"bg-lighter flex rounded-tl-lg flex-wrap"},[_vm._l((_vm.mediaProperties),function(mp,i){return _c('div',{key:((mp.url) + "-" + i),staticClass:"flex-1 text-center px-6 py-4 truncate cursor-pointer hover:text-black border-grey text-sm",class:{
                    'border-l': i > 0,
                    'border-b':
                        (_vm.selectedMediaPropertyIndex !== i &&
                            _vm.mediaProperties.length > 1) ||
                        (_vm.mediaProperties.length == 1 &&
                            _vm.sidebarMode == 'review') ||
                        _vm.selectedMediaPropertyIndex == -1,
                    'bg-dark':
                        _vm.selectedMediaPropertyIndex == i &&
                        (_vm.mediaProperties.length > 1 ||
                            _vm.sidebarMode == 'enhance'),
                    'rounded-tl-lg': i == 0,
                    'bg-impact-red': _vm.mediaPropertyHasIssues(mp),
                    'text-white': _vm.mediaPropertyHasIssues(mp),
                    'hover:text-impact-grey': _vm.mediaPropertyHasIssues(mp),
                },on:{"click":function($event){_vm.selectedMediaPropertyIndex = i}}},[(_vm.mediaPropertyHasIssues(mp))?_c('Icon',{staticClass:"text-white",attrs:{"name":"exclamationTriangle","size":"lg"}}):[(mp.approved === true)?_c('Icon',{staticClass:"text-secondary",attrs:{"name":"checkCircleSolid","size":"sm"}}):(mp.approved === false)?_c('Icon',{staticClass:"text-danger",attrs:{"name":"timesOctagonSolid","size":"sm"}}):(mp.mediaType == 'instagram')?_c('Icon',{attrs:{"name":"instagram","size":"sm"}}):_c('Icon',{attrs:{"name":"link","size":"sm"}}),(mp.primary === true)?_c('Icon',{staticClass:"text-secondary ml-1",attrs:{"name":"starSolid","size":"sm"}}):_vm._e()],_vm._v(" "+_vm._s(_vm._f("url")(mp.url))+" ")],2)}),(_vm.sidebarMode == 'enhance')?_c('div',{staticClass:"text-center px-6 py-4 truncate cursor-pointer hover:text-secondary border-grey border-l text-sm",class:{
                    'bg-dark': _vm.selectedMediaPropertyIndex == -1,
                    'border-b': _vm.selectedMediaPropertyIndex != -1,
                },on:{"click":function($event){_vm.selectedMediaPropertyIndex = -1}}},[_c('Icon',{attrs:{"name":"plus","size":"sm"}}),_vm._v(" "+_vm._s(_vm.$t("listings.reviewModal.mediaProperties.addButton"))+" ")],1):_vm._e()],2),_c('MediaPropertyActionBar',{attrs:{"selected-media-property-index":_vm.selectedMediaPropertyIndex},on:{"delete":_vm.selectedMediaPropertyRemoved,"add":_vm.mediaPropertyAdded}})],1),_c('MediaPropertyScreenshot',{attrs:{"selected-media-property-index":_vm.selectedMediaPropertyIndex}}),(false && _vm.mediaProperties.length && _vm.sidebarMode == 'enhance')?_c('MediaPropertyMetaActions',{attrs:{"screenshot-date":_vm.selectedMediaProperty.screenshotDate,"screenshot-capture-count":_vm.selectedMediaProperty.screenshotCaptureCount,"screenshot-flagged-for-recapture":_vm.selectedMediaProperty.screenshotFlaggedForRecapture},on:{"toggleFlagging":_vm.onScreenshotFlaggingToggle}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
    default: {
        urls: {
            static: "//content-review-dev.mediarails.com/api/static",
            listings: "//content-review-dev.mediarails.com/api/listings",
            listingLogoOptions:
                "//content-review-dev.mediarails.com/api/listings/logo-options",
            listingApprove:
                "//content-review-dev.mediarails.com/api/listings/approve",
            listingReject:
                "//content-review-dev.mediarails.com/api/listings/reject",
            listingAssignTo:
                "//content-review-dev.mediarails.com/api/listings/assign",
            listingChangeTags:
                "//content-review-dev.mediarails.com/api/listings/tag",
            panels: "//content-review-dev.mediarails.com/api/webcontent/panels",
            pages: "//content-review-dev.mediarails.com/api/webcontent/pages",
        },
    },
    "content-review-dev.mediarails.com": {
        urls: {
            static: "//content-review-dev.mediarails.com/api/static",
            listings: "//content-review-dev.mediarails.com/api/listings",
            listingLogoOptions:
                "//content-review-dev.mediarails.com/api/listings/logo-options",
            listingApprove:
                "//content-review-dev.mediarails.com/api/listings/approve",
            listingReject:
                "//content-review-dev.mediarails.com/api/listings/reject",
            listingAssignTo:
                "//content-review-dev.mediarails.com/api/listings/assign",
            listingChangeTags:
                "//content-review-dev.mediarails.com/api/listings/tag",
            panels: "//content-review-dev.mediarails.com/api/webcontent/panels",
            pages: "//content-review-dev.mediarails.com/api/webcontent/pages",
        },
    },
    "content-review.mediarails.com": {
        urls: {
            static: "//content-review.mediarails.com/api/static",
            listings: "//content-review.mediarails.com/api/listings",
            listingLogoOptions:
                "//content-review.mediarails.com/api/listings/logo-options",
            listingApprove:
                "//content-review.mediarails.com/api/listings/approve",
            listingReject:
                "//content-review.mediarails.com/api/listings/reject",
            listingAssignTo:
                "//content-review.mediarails.com/api/listings/assign",
            listingChangeTags:
                "//content-review.mediarails.com/api/listings/tag",
            panels: "//content-review.mediarails.com/api/webcontent/panels",
            pages: "//content-review.mediarails.com/api/webcontent/pages",
        },
    },
};

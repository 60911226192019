<template>
    <div>
        <notifications
            position="top center"
            :duration="-1"
            :width="450"
            style="z-index: 11000;"
            classes="mr-notification"
        />
    </div>
</template>

<script>
/*
    Wrapper around https://github.com/euvl/vue-notification/
    HOW TO USE
        // 1. Add to vue instance
        import Notifications from "vue-notification";
        ...
        Vue.use(Notifications);

        // 2. Add to top level vue component
        import Notify from "vue-components/notify.vue";
        ...
        components: {Notify}
        ...
        <Notify/>

        // 3. Call to show a notification
        this.$notify({
            type: "error",  // Can be error|warn|success (Omit for info)
            text: "Optional title",
            text: this.$i18n.t("errors.pageDataFetch"),
            duration: 10000, // Hide after so many milliseconds (defaults to show forever)
        });
*/
export default {
    name: "Notify",
};
</script>

<style lang="scss">
.mr-notification {
    padding: 10px 15px;
    margin: 10px;
    color: #fff;
    font-size: 13px;
    line-height: 1.3;
    border-left: 5px solid transparent;

    background-color: "#23a9f6" !important;
    border-left-color: darken(#23a9f6, 15%) !important;

    &.warn.warn.warn {
        background-color: #ffb648 !important;
        border-left-color: darken(#ffb648, 15%) !important;
    }

    &.error.error.error {
        background-color: #e54d42 !important;
        border-left-color: darken(#e54d42, 15%) !important;
    }

    &.success.success.success {
        background-color: #68cd86 !important;
        border-left-color: darken(#68cd86, 15%) !important;
    }
}
</style>

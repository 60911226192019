import auth0 from "auth0-js";
import authConfig from "@/../auth.config";

const webAuth = new auth0.WebAuth({
    domain: authConfig.domain,
    redirectUri: `${window.location.origin}/index.html`,
    clientID: authConfig.clientId,
    responseType: "id_token",
    scope: "openid profile email",
});

const localStorageKey = "loggedIn";

class AuthService {
    isBusy = null;
    idToken = null;
    profile = null;
    tokenExpiry = null;
    tokenExpireTimeoutHandle = null;
    showExpireNotificationFunc = () => {};

    login(customState) {
        webAuth.authorize({
            appState: customState,
        });
    }

    logOut() {
        localStorage.removeItem(localStorageKey);
        this.idToken = null;
        this.tokenExpiry = null;
        this.profile = null;
        webAuth.logout({
            returnTo: `${window.location.origin}/index.html`,
        });
    }

    async handleAuthentication(hash) {
        hash = hash || window.location.hash;
        let authResult = await new Promise((resolve, reject) => {
            webAuth.parseHash({hash}, (err, authResult) => {
                if (err) {
                    reject(err.errorDescription);
                } else {
                    this.localLogin(authResult);
                    resolve(authResult);
                }
            });
        });
        return authResult;
    }

    isAuthenticated() {
        return (
            this.idToken &&
            Date.now() < this.tokenExpiry &&
            localStorage.getItem(localStorageKey) === "true"
        );
    }

    async getIdToken() {
        try {
            if (this.isAuthenticated()) {
                return this.idToken;
            } else if (this.isBusy) {
                return null;
            } else {
                let authResult = await this.renewTokens();
                return authResult.idToken;
            }
        } catch {
            return null;
        }
    }

    localLogin(authResult) {
        this.idToken = authResult.idToken;
        this.profile = authResult.idTokenPayload;
        // Convert the expiry time from seconds to milliseconds,
        // required by the Date constructor
        this.tokenExpiry = new Date(this.profile.exp * 1000);
        localStorage.setItem(localStorageKey, "true");
        this.scheduleExpireNotification();
    }

    scheduleExpireNotification() {
        // If a timer has already been scheduled cancel it
        if (this.tokenExpireTimeoutHandle)
            clearTimeout(this.tokenExpireTimeoutHandle);
        // Subtract an hour from the token expire time
        let expireWarnTime = new Date(
            Math.abs(this.tokenExpiry) - 60 * 60 * 1000
        );
        // Setup warning for the user before the token expires
        this.tokenExpireTimeoutHandle = setTimeout(
            this.showExpireNotificationFunc,
            Math.abs(Number(expireWarnTime) - Number(new Date()))
        );
    }

    setShowExpireNotificationFunc(func) {
        this.showExpireNotificationFunc = func;
    }

    async renewTokens() {
        if (localStorage.getItem(localStorageKey) !== "true") {
            throw new Error("Not logged in");
        }
        this.isBusy = true;
        let authResult = await new Promise((resolve, reject) => {
            webAuth.checkSession({}, (err, authResult) => {
                this.isBusy = false;
                if (err) {
                    reject(err.error_description);
                } else {
                    this.localLogin(authResult);
                    resolve(authResult);
                }
            });
        });
        return authResult;
    }
}

const authService = new AuthService();
export default authService;

import _ from "lodash";
import Util from "@/util/util";
import api from "@/util/api";
import Vue from "vue";
import enumPanelStatus from "@/models/enum-panel-status";

export default {
    state: {
        isBusy: false,
        pages: [],
    },
    getters: {},
    modules: {},
    mutations: {
        panelPages_set(state, data) {
            Util.VuexSetValueOnState(state, data);
        },
        panelPages_addPage(state, data) {
            let index = _.findIndex(state.pages, (page) => {
                return (
                    data.name === page.name &&
                    data.pageTypeId === page.pageTypeId &&
                    data.regionId === page.regionId &&
                    data.status === page.status
                );
            });
            Vue.set(state.pages, index, data);
        },
        panelPages_modifyPage(state, data) {
            let targetPage = state.pages.find((x) => x.id === data.id);
            Object.assign(targetPage, data);
        },
        panelPages_setDraftPagePanel(
            state,
            {panel, panelIndex, regionId, programTypeId}
        ) {
            let targetPage = state.pages.find(
                (x) =>
                    x.regionId === regionId &&
                    x.programTypeId === programTypeId &&
                    x.status === enumPanelStatus.draft
            );
            Vue.set(targetPage.panels, panelIndex, panel);
        },
        panelPages_addEmptyDraftPage(
            state,
            {regionId, pageTypeId, pageName, programTypeId}
        ) {
            state.pages.push({
                id: null,
                pageTypeId,
                regionId,
                programTypeId,
                name: pageName,
                status: enumPanelStatus.draft,
                publishAt: null,
                panels: [],
            });
        },
    },
    actions: {
        async panelPages_fetch({commit}) {
            let pages = await api.panelPages.fetch();
            commit("panelPages_set", {pages: pages});
            return pages;
        },
        async panelPages_createPage({commit}, panelPage) {
            let response = await api.panelPages.create(panelPage);
            commit(
                "panelPages_addPage",
                Object.assign({}, panelPage, {
                    id: response.pageId,
                })
            );
        },
        async panelPages_editPage({commit}, panelPage) {
            let result = await api.panelPages.edit(panelPage);
            commit("panelPages_modifyPage", panelPage);
            return result;
        },
        async panelPages_publish({dispatch}, panelPage) {
            await api.panelPages.publish(panelPage);
            await dispatch("panelPages_fetch");
            return true;
        },
    },
};

import authService from "@/util/auth";
import userRoles from "@/../roles.config";
const errorPrefix = "Store:modules:session";
export default {
    state: {
        isLoggedIn: false,
        userProfile: null,
    },
    getters: {
        sessionUserRoles: state => {
            let roleKeys =
                state.userProfile["https://mediarails.com/roles"] || [];
            roleKeys = roleKeys && roleKeys.length ? roleKeys : ["None"];
            return roleKeys.map(k => userRoles[k]);
        },
        userHasPermission: (state, getters) => permission => {
            let roles = getters.sessionUserRoles;
            for (let i = 0; i < roles.length; i++) {
                if (roles[i].permissions[permission] === true) return true;
            }
            return false;
        },
        sessionIsAuthenticated: state => () => {
            return authService.isAuthenticated();
        },
    },
    mutations: {
        session_set_login(state, {idTokenPayload}) {
            state.isLoggedIn = true;
            state.userProfile = idTokenPayload;
        },
        session_set_logout(state) {
            state.isLoggedIn = false;
            state.userProfile = null;
        },
    },
    actions: {
        session_login({state}, customState) {
            try {
                authService.login(customState);
            } catch (error) {
                const msg = `${errorPrefix}:login() -- ${error}`;
                console.error(msg, error);
                throw new Error(msg);
            }
        },
        session_logout({commit}) {
            try {
                authService.logOut();
                commit("session_set_logout");
            } catch (error) {
                const msg = `${errorPrefix}:logout() -- ${error}`;
                console.error(msg, error);
                throw new Error(msg);
            }
        },
        async session_handleAuthCallback({commit}, urlHash) {
            try {
                let authResult = await authService.handleAuthentication(
                    urlHash
                );
                commit("session_set_login", authResult);
                return authResult;
            } catch (error) {
                const msg = `${errorPrefix}:handleAuthCallback() -- ${error}`;
                console.error(msg, error);
                throw new Error(msg);
            }
        },
        async session_renewTokens({commit}) {
            try {
                let authResult = await authService.renewTokens();
                commit("session_set_login", authResult);
                return authResult;
            } catch (error) {
                const msg = `${errorPrefix}:renewTokens() -- ${error}`;
                console.error(msg, error);
                throw new Error(msg);
            }
        },
    },
};

<template>
    <div
        v-if="sidebarMode === 'enhance'"
        class="bg-dark px-5 py-4 border-b border-grey"
        :class="{'bg-impact-red': hasIssues, 'text-white': hasIssues}"
    >
        <div v-if="selectedMediaPropertyIndex == -1" class="flex">
            <TextInput
                autofocus
                class="flex-1"
                :value="newMediaPropertyUrl"
                :placeholder="
                    $i18n.t(
                        'listings.reviewModal.mediaProperties.newUrlInputPlaceholder'
                    )
                "
                @input="v => (newMediaPropertyUrl = v)"
                @enter="onMediaPropertyAdd"
            />
            <Button
                :disabled="!newMediaPropertyUrl"
                small
                primary
                class="ml-4"
                @click="onMediaPropertyAdd"
            >
                {{ $t("listings.reviewModal.mediaProperties.addButton") }}
            </Button>
        </div>
        <template v-else>
            <div class="flex">
                <div
                    class="flex-1 border px-2 py-1 rounded align-middle truncate text-sm"
                    :class="{
                        'border-white': hasIssues,
                        'border-grey': !hasIssues,
                    }"
                >
                    {{ mediaPropertyUrl }}
                </div>
                <div class="pl-3 pr-4 pt-1 align-middle">
                    <ApproveToggle
                        :form-align="false"
                        :value="isApproved"
                        :reverse-colors="hasIssues"
                        :show-positive="false"
                        @change="toggleMediaPropApproved"
                    />
                </div>
                <div
                    class="ml-3 pl-6 border-l"
                    :class="{
                        'border-white': hasIssues,
                        'border-grey': !hasIssues,
                    }"
                />
                <div
                    class="align-middle pt-1"
                    :class="{
                        'cursor-pointer': !isPrimary,
                        'text-white': hasIssues,
                    }"
                    @click="!isPrimary ? setAsPrimary() : false"
                >
                    <Icon v-if="isPrimary" name="starSolid" size="sm" />
                    <Icon v-else name="star" size="sm" />
                </div>
                <div class="align-middle pt-1 ml-4">
                    <DeleteIconInline
                        :disabled="isPrimary"
                        :reverse-colors="hasIssues"
                        @confirm="deleteMediaProperty"
                    />
                </div>
            </div>
            <div v-if="hasIssues" class="px-1">
                <DataQualityIssues
                    :data-quality-issues="dataQualityIssues"
                    @dismiss="onIssueDismiss"
                />
            </div>
        </template>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Icon from "@mediarails/vue-fa-icon";
import ApproveToggle from "@mediarails/vue-approve-toggle";
import TextInput from "@mediarails/vue-text-input";
import Button from "@mediarails/vue-button";
import DeleteIconInline from "@mediarails/vue-icon-delete-inline";
import DataQualityIssues from "./DataQualityIssues.vue";
export default {
    name: "MediaPropertyTabsActionBar",
    components: {
        Icon,
        ApproveToggle,
        TextInput,
        Button,
        DeleteIconInline,
        DataQualityIssues,
    },
    props: {
        selectedMediaPropertyIndex: {type: Number, required: true},
    },
    data: () => ({
        newMediaPropertyUrl: null,
    }),
    computed: mapState({
        sidebarMode: s => s.reviewModal.mode,
        editedListing: s => s.listings.editedListing,
        editedListingSelectedMediaProperty() {
            if (
                this.editedListing &&
                this.editedListing.mediaProperties &&
                this.editedListing.mediaProperties[
                    this.selectedMediaPropertyIndex
                ]
            )
                return this.editedListing.mediaProperties[
                    this.selectedMediaPropertyIndex
                ];
            else return null;
        },
        isApproved() {
            if (this.editedListingSelectedMediaProperty)
                return this.editedListingSelectedMediaProperty.approved;
            else return null;
        },
        mediaPropertyUrl() {
            if (this.editedListingSelectedMediaProperty)
                return this.editedListingSelectedMediaProperty.url;
            else return null;
        },
        isPrimary() {
            if (this.editedListingSelectedMediaProperty)
                return this.editedListingSelectedMediaProperty.primary;
            else return false;
        },
        dataQualityIssues() {
            return this.editedListingSelectedMediaProperty
                ? this.editedListingSelectedMediaProperty.issues || []
                : [];
        },
        hasIssues() {
            let issues = this.editedListingSelectedMediaProperty
                ? this.editedListingSelectedMediaProperty.issues || []
                : [];
            return issues.filter(i => i.dismissed === false).length > 0;
        },
    }),
    watch: {
        selectedMediaPropertyIndex() {
            this.newMediaPropertyUrl = null;
        },
    },
    methods: {
        toggleMediaPropApproved(value) {
            this.$store.commit(
                "listings_update_editedListingValueMediaProperty",
                {
                    name: "approved",
                    value,
                    index: this.selectedMediaPropertyIndex,
                }
            );
        },
        setAsPrimary() {
            this.$store.commit(
                "listings_update_editedListingValueMediaProperty_setPrimary",
                this.selectedMediaPropertyIndex
            );
        },
        deleteMediaProperty() {
            this.$store.commit(
                "listings_update_editedListingValueMediaProperty_remove",
                this.selectedMediaPropertyIndex
            );
            this.$emit("delete");
        },
        onMediaPropertyAdd() {
            this.$store.commit(
                "listings_update_editedListingValueMediaProperty_add",
                {url: (this.newMediaPropertyUrl || "").trim()}
            );
            this.newMediaPropertyUrl = null;
            this.$emit("add");
        },
        onIssueDismiss(issue) {
            this.$store.commit(
                "listings_update_editedListingValueMediaProperty_dismissIssue",
                {
                    index: this.selectedMediaPropertyIndex,
                    issue,
                }
            );
        },
    },
};
</script>

import Vue from "vue";
import Store from "@/store";
import Router from "vue-router";
import Home from "@/views/Home.vue";
import Listings from "@/views/Listings.vue";
import PanelManager from "@/views/PanelManager.vue";
import User from "@/views/User.vue";
import Login from "@/views/Login.vue";
import AccessDenied from "@/views/AccessDenied.vue";

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: "/",
            name: "home",
            component: Home,
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            //component: () => import("./views/User.vue"),
        },
        {
            path: "/listings",
            name: "listings",
            component: Listings,
        },
        {
            path: "/panelmanager",
            name: "panelManager",
            component: PanelManager,
        },
        {
            path: "/user",
            name: "user",
            component: User,
        },
        {
            path: "/login",
            name: "login",
            component: Login,
        },
        {
            path: "/access_denied",
            name: "accessDenied",
            component: AccessDenied,
        },
    ],
});

router.beforeEach((to, from, next) => {
    // IF this is the return from auth0 then handle the auth0 url hash and continue
    if (to.path.startsWith("/id_token")) {
        let urlHash = to.path.replace("/", "");
        Store.dispatch("session_handleAuthCallback", urlHash).then(
            authResult => {
                next({
                    path: (authResult.appState || {}).target || "/listings",
                });
            }
        );
    } else if (
        to.path === "/" ||
        to.path.startsWith("/access_denied") ||
        Store.getters.sessionIsAuthenticated()
    ) {
        next();
    } else if (to.path.startsWith("/error=access_denied")) {
        next(`/access_denied?${to.path.replace("/", "")}`);
    } else {
        // Specify the current path as the customState parameter, meaning it
        // will be returned to the application after auth
        Store.dispatch("session_login", {target: to.fullPath});
    }
});

export default router;

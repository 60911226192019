import {readOnlyFields} from "@/field-config";

export default function({
    countries,
    categories,
    partnerTypes,
    promoMethods,
    genders,
    languages,
    hasRadiusPublisherId,
    hasListingFeedback,
}) {
    return [
        {
            columns: "calc(100% - 70px) 70px",
            fields: [
                {
                    name: "clearListingFeedback",
                    type: "checkbox",
                    columnSpan: "2",
                    settings: {
                        valueLabel: "Clear Listing Feedback",
                        hidden: !hasListingFeedback,
                    },
                },
                {
                    name: "logoUrl",
                    type: "text",
                    settings: {
                        label: "Logo URL *",
                        required: true,
                        maxlength: 500,
                        placeholder:
                            "Valid URL's only, must start with http or https",
                        pattern: "^https?://.*",
                    },
                },
                {
                    name: "logoUrlApproved",
                    type: "approveToggle",
                    settings: {
                        showPositive: false,
                    },
                },
                {
                    name: "name",
                    type: "text",
                    settings: {
                        label: "Name *",
                        required: true,
                    },
                },
                {
                    name: "nameApproved",
                    type: "approveToggle",
                    settings: {
                        showPositive: false,
                    },
                },
                {
                    name: "description",
                    type: "textarea",
                    settings: {
                        label: "Description *",
                        required: true,
                    },
                },
                {
                    name: "descriptionApproved",
                    type: "approveToggle",
                    settings: {
                        showPositive: false,
                    },
                },
                {
                    name: "partnerType",
                    type: "select",
                    settings: {
                        label: "Listing type *",
                        required: true,
                        options: partnerTypes,
                        maxHeight: 150,
                    },
                },
                {
                    name: "partnerTypeApproved",
                    type: "approveToggle",
                    settings: {
                        showPositive: false,
                    },
                },
                {
                    name: "locationCountry",
                    type: "select",
                    settings: {
                        label: "Country",
                        options: countries,
                        maxHeight: 150,
                        disabled: hasRadiusPublisherId,
                    },
                },
                {
                    name: "locationCountryApproved",
                    type: "approveToggle",
                    settings: {
                        showPositive: false,
                    },
                },
                {
                    name: "language",
                    type: "select",
                    settings: {
                        label: "Language *",
                        required: !hasRadiusPublisherId,
                        options: languages,
                        maxHeight: 150,
                        disabled: hasRadiusPublisherId,
                    },
                },
                {
                    name: "languageApproved",
                    type: "approveToggle",
                    settings: {
                        showPositive: false,
                    },
                },
                {
                    name: "promoMethods",
                    type: "multiselect",
                    settings: {
                        label: "Promo methods",
                        disabled: readOnlyFields.includes("promoMethods"),
                        options: promoMethods,
                        maxHeight: 150,
                    },
                },
                {
                    name: "promoMethodsApproved",
                    type: "approveToggle",
                    settings: {
                        showPositive: false,
                        disabled: readOnlyFields.includes("promoMethods"),
                    },
                },
                {
                    name: "tags",
                    type: "multiselect",
                    settings: {
                        label: "Tags *",
                        required: true,
                        options: [],
                        maxHeight: 150,
                        canCreate: true,
                    },
                },
                {
                    name: "tagsApproved",
                    type: "approveToggle",
                    settings: {
                        showPositive: false,
                    },
                },
                {
                    name: "editorialTags",
                    type: "multiselect",
                    columnSpan: "2",
                    settings: {
                        label: "Editorial Tags (not shown to customers)",
                        options: [],
                        maxHeight: 150,
                        canCreate: true,
                    },
                },
                {
                    name: "categories",
                    type: "multiselect",
                    settings: {
                        label: "Categories",
                        options: categories,
                        maxHeight: 150,
                        disabled: hasRadiusPublisherId,
                    },
                },
                {
                    name: "categoriesApproved",
                    type: "approveToggle",
                    settings: {
                        showPositive: false,
                    },
                },
                {
                    name: "gender",
                    type: "select",
                    settings: {
                        label: "Gender",
                        required: false,
                        options: genders,
                        maxHeight: 150,
                    },
                },
                {
                    name: "genderApproved",
                    type: "approveToggle",
                    settings: {
                        showPositive: false,
                    },
                },
            ],
        },
        {
            label: "Contact",
            columns: "calc(100% - 70px) 70px",
            fields: [
                {
                    name: "contactEmail",
                    type: "text",
                    settings: {
                        label: "Email *",
                        required: true,
                        disabled: hasRadiusPublisherId,
                    },
                },
                {
                    name: "contactEmailApproved",
                    type: "approveToggle",
                    settings: {
                        showPositive: false,
                    },
                },
                {
                    name: "contactIsAgent",
                    type: "checkbox",
                    columnSpan: "2",
                    settings: {
                        valueLabel: "Is agency representative",
                        disabled: hasRadiusPublisherId,
                    },
                },
                {
                    name: "contactFullName",
                    type: "text",
                    columnSpan: "2",
                    settings: {
                        label: "Full Name",
                        disabled: hasRadiusPublisherId,
                    },
                },
                {
                    name: "contactFirstName",
                    type: "text",
                    columnSpan: "2",
                    settings: {
                        label: "First name",
                        disabled: hasRadiusPublisherId,
                    },
                },
                {
                    name: "contactLastName",
                    type: "text",
                    columnSpan: "2",
                    settings: {
                        label: "Last name",
                        disabled: hasRadiusPublisherId,
                    },
                },
                {
                    name: "contactPhone",
                    type: "text",
                    columnSpan: "2",
                    settings: {
                        label: "Phone",
                        disabled: hasRadiusPublisherId,
                    },
                },
            ],
        },
    ];
}

import Vue from "vue";
import Vuex from "vuex";
import ModuleListings from "@/modules/listings";
import ModuleStatic from "@/modules/static";
import ModuleSession from "@/modules/session";
import ModuleReviewModal from "@/modules/review-modal";
import ModulePanelManager from "@/modules/panel-manager";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        session: ModuleSession,
        static: ModuleStatic,
        listings: ModuleListings,
        reviewModal: ModuleReviewModal,
        panelManager: ModulePanelManager,
    },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.sidebarMode === 'enhance')?_c('div',{staticClass:"bg-dark px-5 py-4 border-b border-grey",class:{'bg-impact-red': _vm.hasIssues, 'text-white': _vm.hasIssues}},[(_vm.selectedMediaPropertyIndex == -1)?_c('div',{staticClass:"flex"},[_c('TextInput',{staticClass:"flex-1",attrs:{"autofocus":"","value":_vm.newMediaPropertyUrl,"placeholder":_vm.$i18n.t(
                    'listings.reviewModal.mediaProperties.newUrlInputPlaceholder'
                )},on:{"input":function (v) { return (_vm.newMediaPropertyUrl = v); },"enter":_vm.onMediaPropertyAdd}}),_c('Button',{staticClass:"ml-4",attrs:{"disabled":!_vm.newMediaPropertyUrl,"small":"","primary":""},on:{"click":_vm.onMediaPropertyAdd}},[_vm._v(" "+_vm._s(_vm.$t("listings.reviewModal.mediaProperties.addButton"))+" ")])],1):[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-1 border px-2 py-1 rounded align-middle truncate text-sm",class:{
                    'border-white': _vm.hasIssues,
                    'border-grey': !_vm.hasIssues,
                }},[_vm._v(" "+_vm._s(_vm.mediaPropertyUrl)+" ")]),_c('div',{staticClass:"pl-3 pr-4 pt-1 align-middle"},[_c('ApproveToggle',{attrs:{"form-align":false,"value":_vm.isApproved,"reverse-colors":_vm.hasIssues,"show-positive":false},on:{"change":_vm.toggleMediaPropApproved}})],1),_c('div',{staticClass:"ml-3 pl-6 border-l",class:{
                    'border-white': _vm.hasIssues,
                    'border-grey': !_vm.hasIssues,
                }}),_c('div',{staticClass:"align-middle pt-1",class:{
                    'cursor-pointer': !_vm.isPrimary,
                    'text-white': _vm.hasIssues,
                },on:{"click":function($event){!_vm.isPrimary ? _vm.setAsPrimary() : false}}},[(_vm.isPrimary)?_c('Icon',{attrs:{"name":"starSolid","size":"sm"}}):_c('Icon',{attrs:{"name":"star","size":"sm"}})],1),_c('div',{staticClass:"align-middle pt-1 ml-4"},[_c('DeleteIconInline',{attrs:{"disabled":_vm.isPrimary,"reverse-colors":_vm.hasIssues},on:{"confirm":_vm.deleteMediaProperty}})],1)]),(_vm.hasIssues)?_c('div',{staticClass:"px-1"},[_c('DataQualityIssues',{attrs:{"data-quality-issues":_vm.dataQualityIssues},on:{"dismiss":_vm.onIssueDismiss}})],1):_vm._e()]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <Popover ref="popover" right :hide-on-click-away="false">
        <span slot="target">
            <slot />
        </span>
        <div slot="content" class="w-14 text-left">
            <TextArea
                ref="textarea"
                autofocus
                :label="
                    $i18n.t(
                        'listings.reviewModal.statusReasonPopover.reasonLabel'
                    )
                "
                height="70px"
                :value="value"
                @input="v => (value = v)"
            />
            <div class="text-right mt-4">
                <Button @click="$hide">
                    {{
                        $t(
                            "listings.reviewModal.statusReasonPopover.buttonCancel"
                        )
                    }}
                </Button>
                <Button primary @click="onActionClick">
                    {{ actionLabel }}
                </Button>
            </div>
        </div>
    </Popover>
</template>

<script>
import Popover from "@mediarails/vue-popover";
import TextArea from "@mediarails/vue-textarea-input";
import Button from "@mediarails/vue-button";
export default {
    name: "PopoverStatusReason",
    components: {Popover, TextArea, Button},
    props: {
        actionLabel: {type: String, required: true},
    },
    data: () => ({
        value: null,
    }),
    methods: {
        $show() {
            this.value = null;
            this.$refs.popover.$show();
            this.$refs.textarea.$focus();
        },
        $hide() {
            this.$refs.popover.$hide();
        },
        onActionClick() {
            this.$emit("action", this.value);
            this.$hide();
        },
    },
};
</script>

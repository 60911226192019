<template>
    <div>
        <div
            v-for="(issue, index) in activeIssues"
            :key="`${issue.name}-${index}`"
            class="flex mt-4"
        >
            <div
                class="self-center pr-2 whitespace-no-wrap cursor-pointer hover:text-impact-grey text-sm border-r mr-6 pr-5"
                @click="$emit('dismiss', issue)"
            >
                <Icon name="eyeSlash" />
                {{ $t("listings.reviewModal.dataQualityIssueDismiss") }}
            </div>
            <div class="flex-1">
                <div class="font-bold text-sm">{{ issue.type }}</div>
                <div class="text-xs -mt-1">{{ issue.description }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Icon from "@mediarails/vue-fa-icon";
export default {
    name: "DataQualityIssues",
    components: {Icon},
    props: {
        dataQualityIssues: {type: Array, default: () => []}, // {name, type, dismissed, description}
    },
    data: () => ({}),
    computed: mapState({
        activeIssues() {
            return this.dataQualityIssues.filter(
                issue => issue.dismissed !== true
            );
        },
    }),
    watch: {},
    mounted() {},
    methods: {},
};
</script>

<style scoped></style>

<template>
    <ModalConfirm
        ref="publishConfirm"
        :busy="isBusy"
        :title="
            $t('panelManager.regions.publishConfirm.title', {
                region: selectedRegionName,
                programType: selectedProgramTypeName,
            })
        "
        :description="$t('panelManager.regions.publishConfirm.description')"
        :action-label="$t('panelManager.regions.publishConfirm.actionLabel')"
        dangerous
        @confirm="onPublishConfirm"
    />
</template>

<script>
import Vue from "vue";
import {mapState} from "vuex";
import ModalConfirm from "@/components/ModalConfirm.vue";
export default {
    name: "PagePublishConfirm",
    components: {ModalConfirm},
    data: () => ({
        isBusy: false,
    }),
    props: {
        selectedRegionName: {type: String, default: null},
        selectedProgramTypeName: {type: String, default: null},
    },
    computed: mapState({}),
    watch: {},
    mounted() {},
    methods: {
        $show(panelPageToPublish) {
            this.panelPageToPublish = panelPageToPublish;
            this.$refs.publishConfirm.$show(panelPageToPublish);
        },
        onPublishConfirm(panelPageToPublish) {
            this.isBusy = true;
            this.$store
                .dispatch("panelPages_publish", panelPageToPublish)
                .then(() => {
                    this.$refs.publishConfirm.$hide();
                    this.$emit("finished");
                })
                .catch((error) => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.save"),
                    });
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
    },
};
</script>

<template>
    <SimpleDialog
        :busy="busy"
        ref="logo-options"
        :title="
            $t('listings.reviewModal.logoOptionsModalTitle', {
                count: logoOptions.length || '',
            })
        "
        width="800px"
        class="overflow-hidden"
    >
        <div :class="`grid grid-cols-2 gap-4 overflow-auto max-h-30 min-h-20`">
            <PictureCard
                v-for="logoOption in logoOptions"
                :key="`${logoOption.description}-${logoOption.listingId}`"
                :image-url="logoOption.url"
                title=""
                :sub-title="logoOption.description"
                :fallback-text="
                    $t('listings.reviewModal.logoOptionFallbackMsg', {
                        description: logoOption.description,
                    })
                "
                image-container-height="300px"
                image-width="80%"
                v-bind="
                    listing.logoUrl === logoOption.url
                        ? {iconName: 'check', iconColor: 'success'}
                        : {}
                "
                @click="() => cardClick(logoOption)"
            />
        </div>
    </SimpleDialog>
</template>
<script>
import SimpleDialog from "@mediarails/vue-modal-simple-dialog";
import PictureCard from "@mediarails/vue-card-picture";
import {mapState} from "vuex";

export default {
    name: "LogoOptionsModal",
    components: {
        SimpleDialog,
        PictureCard,
    },
    data() {
        return {
            busy: false,
        };
    },
    computed: {
        ...mapState({
            listing: state => state.listings.editedListing,
            logoOptions(state) {
                let logoOptions = state.listings.logoOptions;
                return logoOptions.filter(
                    logoOption => logoOption.listingId === this.listing.id
                );
            },
        }),
    },
    methods: {
        fetchLogoOptions() {
            this.busy = true;
            this.$store
                .dispatch("listing_fetch_logo_options", this.listing.id)
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.fetch"),
                    });
                })
                .finally(() => (this.busy = false));
        },
        $show() {
            this.$refs["logo-options"].$show();
            !this.logoOptions.length && this.fetchLogoOptions();
        },
        cardClick(data) {
            this.$store.commit("listings_update_editedListingValue", {
                name: "logoUrl",
                value: data.url,
            });
        },
    },
};
</script>

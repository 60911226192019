<template>
    <LayoutSingleColumn>
        <PageTitle>
            <span slot="title">{{ $t("user.pageTitle") }}</span>
        </PageTitle>
        <div class="flex">
            <div class="text-left">
                <div class="text-2xl">{{ profile.name }}</div>
                <div class="mt-3">{{ profile.email }}</div>
                <div class="mt-3" v-for="role in userRoles" :key="role.label">
                    <b>{{ role.label }}</b>
                    ({{ role.description }})
                </div>
            </div>
            <div v-if="profile.picture" class="text-right flex-1">
                <img :src="`${profile.picture}`" class="w-11 rounded-full" />
            </div>
        </div>
        <div class="text-left mt-10">
            <pre>{{ JSON.stringify(profile, null, 2) }}</pre>
        </div>
    </LayoutSingleColumn>
</template>

<script>
import {mapState} from "vuex";
import LayoutSingleColumn from "@/components/LayoutSingleColumn.vue";
import PageTitle from "@mediarails/vue-page-title";
export default {
    name: "ViewUser",
    components: {LayoutSingleColumn, PageTitle},
    computed: mapState({
        profile: s => s.session.userProfile,
        userRoles() {
            return this.$store.getters.sessionUserRoles;
        },
    }),
};
</script>

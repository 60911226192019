<template>
    <div class=" pb-4 pt-5 mx-5">
        <div v-if="userCanApprove || userCanReject" class="text-center pt-1">
            <Button
                v-if="userCanApprove"
                success
                :disabled="approveDisabled"
                @click="v => $emit('approve', 'Approved by ' + profile.name)"
            >
                <Icon name="check" size="lg" class="mr-1" />
                {{
                    $t("listings.reviewModal.statusReasonPopover.buttonApprove")
                }}
            </Button>
            <PopoverStatusReason
                ref="rejectPopover"
                :action-label="
                    $i18n.t(
                        'listings.reviewModal.statusReasonPopover.buttonReject'
                    )
                "
                @action="v => $emit('reject', v)"
            >
                <Button
                    v-if="userCanReject"
                    class="ml-5"
                    danger
                    :disabled="rejectDisabled"
                    @click="$refs.rejectPopover.$show()"
                >
                    <Icon name="times" size="lg" class="mr-1" />
                    {{ $t("listings.reviewModal.buttonReject") }}
                </Button>
            </PopoverStatusReason>
        </div>
        <div
            v-if="listingApproved || listingRejected"
            class="text-center pt-5"
            :class="{
                'text-danger': listingRejected,
                'text-success': listingApproved,
            }"
        >
            {{ listing.statusReason }}
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Button from "@mediarails/vue-button";
import Icon from "@mediarails/vue-fa-icon";
import enumListingStatus from "@/models/enum-listing-status";
import PopoverStatusReason from "./PopoverStatusReason.vue";
export default {
    name: "ListingsSidebarReviewActions",
    components: {Button, Icon, PopoverStatusReason},
    data: () => ({
        enumListingStatus: enumListingStatus,
    }),
    computed: mapState({
        listingApproved() {
            return this.listing.status === "approved";
        },
        listingRejected() {
            return this.listing.status === "rejected";
        },
        approveDisabled() {
            return !(this.listing.status == enumListingStatus.enhanced);
        },
        rejectDisabled() {
            return this.listing.status == enumListingStatus.rejected;
        },
        listing() {
            let s = this.$store.state;
            let listings = s.listings.listings;
            let selectedIndex = s.listings.selectedListingIndex;
            return listings[selectedIndex] || {};
        },
        userCanApprove() {
            return this.$store.getters.userHasPermission("canApprove");
        },
        userCanReject() {
            return this.$store.getters.userHasPermission("canReject");
        },
        profile: s => s.session.userProfile,
    }),
};
</script>

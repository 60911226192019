<template>
    <LayoutSingleColumn>
        <PageTitle :no-bottom-margin="true">
            <span slot="title">{{ $t("listings.list.pageTitle") }}</span>
        </PageTitle>
        <div class="title-spacer mt-2" />
        <ListActions ref="listActions" />
        <ListingsList
            class="mt-4"
            @openListingReview="
                listingIndex =>
                    $refs.listActions.$openReviewByIndex(listingIndex)
            "
        />
    </LayoutSingleColumn>
</template>

<script>
import LayoutSingleColumn from "@/components/LayoutSingleColumn.vue";
import PageTitle from "@mediarails/vue-page-title";
import {mapState} from "vuex";
import {omit} from "lodash";
import ListActions from "./Listings/ListActions.vue";
import ListingsList from "./Listings/List.vue";
import Vue from "vue";
export default {
    name: "ViewListings",
    components: {LayoutSingleColumn, PageTitle, ListActions, ListingsList},
    computed: {
        ...mapState({
            listingFilter: state => state.listings.filter,
            sortColumnName: state => state.listings.sortColumn,
            pageIndex: state => state.listings.pageIndex,
            listings: state => state.listings.listings,
        }),
    },
    watch: {
        $route: {
            immediate: true,
            deep: true,
            handler() {
                // Prevent fetching if listing with id is present even if route is changed
                let {id, reviewMode} = this.$route.query;
                let reviewModeOn = String(reviewMode) === "true";
                let listing = this.listings.find(
                    l => l && l.id === parseInt(id)
                );

                if (
                    this.listings.length &&
                    ((!id && reviewModeOn) || (listing && id && reviewModeOn))
                ) {
                    return;
                }

                this.setDefaultQueryParams();
                this.setFilterFromQueryParams();
                this.fetchListings();
            },
        },
    },
    mounted() {
        this.$store.dispatch("static_fetch_info").catch(error => {
            Vue.rollbar.error(error);
            this.$notify({
                type: "error",
                text: error || this.$i18n.t("errors.fetch"),
            });
        });
        this.$store.dispatch("static_fetch_users").catch(error => {
            Vue.rollbar.error(error);
            this.$notify({
                type: "error",
                text: error || this.$i18n.t("errors.fetch"),
            });
        });
    },
    methods: {
        setFilterFromQueryParams() {
            let listingFilter = _.reduce(
                this.listingFilter,
                (result, value, key) => {
                    result[key] = this.$route.query[key] || null;
                    return result;
                },
                {}
            );
            this.$store.commit("listings_set_filter", listingFilter);
            this.$store.commit("listings_set", {
                isBusy: true,
                listings: [],
                sortAsc: String(this.$route.query.sortAsc) === "true",
                sortColumn: this.$route.query.sortColumn || this.sortColumnName,
                pageIndex: this.$route.query.page
                    ? Number(this.$route.query.page) - 1
                    : this.pageIndex,
            });
        },
        setDefaultQueryParams() {
            if (this.$route.fullPath === "/listings") {
                this.$router.push({
                    name: "listings",
                    query: {
                        sortAsc: false,
                        sortColumn: this.sortColumnName,
                        page: this.pageIndex + 1,
                    },
                });
            }
        },
        fetchListings() {
            this.$store.commit("listings_set", {isBusy: true});
            this.$store
                .dispatch("listings_fetch")
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.fetch"),
                    });
                })
                .finally(() => {
                    this.$store.commit("listings_set", {isBusy: false});

                    let id = parseInt(this.$route.query.id);

                    if (this.listings.length && id) {
                        let listing = this.listings.find(
                            listing => listing && listing.id === id
                        );

                        !listing &&
                            this.$router.push({
                                name: "listings",
                                query: {
                                    ...omit(this.$route.query, [
                                        "reviewMode",
                                        "id",
                                    ]),
                                },
                            });
                    }
                });
        },
    },
};
</script>

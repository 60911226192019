<template>
    <div>
        <div class="text-center text-sm max-w-3xl mx-auto">
            {{ $t("panelManager.regions.unpublishedTabDescription") }}
        </div>
        <div class="draft-panels mt-6">
            <template v-for="i in maxPanels">
                <template v-if="draftPanels[i - 1]">
                    <PanelPreview
                        :key="`index-${i - 1}`"
                        :headline="draftPanels[i - 1].headline"
                        :title="draftPanels[i - 1].title"
                        :description="draftPanels[i - 1].description"
                        :logo-url="draftPanels[i - 1].logoUrl"
                    >
                        <PanelHoverActions
                            v-if="userHasEditPermission"
                            @selectPanel="onOpenPanelSelect(i - 1)"
                            @createPanel="onOpenCreatePanel(i - 1)"
                        />
                    </PanelPreview>
                </template>
                <template v-else>
                    <PanelPreview :key="`empty-${i - 1}`">
                        <PanelHoverActions
                            v-if="userHasEditPermission"
                            @selectPanel="onOpenPanelSelect(i - 1)"
                            @createPanel="onOpenCreatePanel(i - 1)"
                        />
                    </PanelPreview>
                </template>
            </template>
        </div>
        <div class="text-right mt-8 pt-7 border-t border-grey">
            <Button
                primary
                :disabled="!draftPanelPage || !userHasEditPermission"
                @click="onPanelPageSave"
            >
                {{ $t("panelManager.regions.saveChangesButton") }}
            </Button>
            <Button :disabled="!canPublish" @click="onPublishConfirmOpen">
                {{ $t("panelManager.regions.publishDraftButton") }}
            </Button>
        </div>
        <PanelCreateEditModal ref="createModal" @finished="onPanelCreate" />
        <PanelSelectModal ref="selectModal" @finished="onPanelSelect" />
        <PublishConfirm
            ref="publishConfirmModal"
            :selected-region-name="selectedRegionName"
            :selected-program-type-name="selectedProgramTypeName"
        />
    </div>
</template>

<script>
import Vue from "vue";
import {mapState} from "vuex";
import enumPanelStatus from "@/models/enum-panel-status";
import PanelPreview from "@/components/PanelPreview.vue";
import PanelHoverActions from "./PanelHoverActions.vue";
import PanelCreateEditModal from "../PanelCreateEditModal.vue";
import PanelSelectModal from "../PanelSelectModal.vue";
import Button from "@mediarails/vue-button";
import PublishConfirm from "./PagePublishConfirm.vue";
export default {
    name: "UnpublishedPanelsView",
    components: {
        PanelPreview,
        PanelHoverActions,
        PanelCreateEditModal,
        PanelSelectModal,
        Button,
        PublishConfirm,
    },
    props: {
        maxPanels: {type: Number, default: 0},
        selectedRegionId: {type: Number, default: null},
        selectedPageTypeId: {type: Number, default: null},
        selectedProgramTypeId: {type: Number, default: null},
    },
    data: () => ({
        currentlyEditingPanelIndex: null,
    }),
    computed: mapState({
        draftPanelPage() {
            let pages = this.$store.state.panelManager.panelPages.pages;
            return pages && pages.length
                ? pages.find(
                      (p) =>
                          p.status === enumPanelStatus.draft &&
                          p.regionId === this.selectedRegionId &&
                          p.programTypeId === this.selectedProgramTypeId
                  )
                : null;
        },
        draftPanels() {
            return this.draftPanelPage && this.draftPanelPage.panels
                ? this.draftPanelPage.panels
                : [];
        },
        countOfEmptyPanels() {
            return Math.max(
                this.maxPanels - (this.draftPanels || []).length,
                0
            );
        },
        selectedRegionName() {
            let region = this.$store.state.static.regions.find(
                (r) => r.id === this.selectedRegionId
            );
            return region ? region.displayName : null;
        },
        selectedProgramTypeName() {
            let programType = this.$store.state.static.programTypes.find(
                (pt) => pt.id === this.selectedProgramTypeId
            );
            return programType ? programType.displayName : null;
        },
        userHasEditPermission() {
            return this.$store.getters.userHasPermission("canEditPages");
        },
        userHasPublishPermission() {
            return this.$store.getters.userHasPermission("canPublishPages");
        },
        canPublish() {
            if (!this.userHasPublishPermission) return false;
            let panelCount = 0;
            for (let i = 0; i < this.maxPanels; i++) {
                if (this.draftPanels[i]) panelCount++;
            }
            return panelCount === this.maxPanels;
        },
    }),
    watch: {},
    mounted() {},
    methods: {
        onOpenPanelSelect(index) {
            this.currentlyEditingPanelIndex = index;
            this.$refs.selectModal.$show();
        },
        onOpenCreatePanel(index) {
            this.currentlyEditingPanelIndex = index;
            this.$refs.createModal.$show();
        },
        onPanelCreate(panel) {
            if (!this.draftPanelPage)
                this.$store.commit("panelPages_addEmptyDraftPage", {
                    regionId: this.selectedRegionId,
                    programTypeId: this.selectedProgramTypeId,
                    pageTypeId: this.selectedPageTypeId,
                    pageName: `${this.selectedRegionName} - ${
                        this.selectedProgramTypeName
                    } - ${Date.now()}`,
                });
            this.$store.commit("panelPages_setDraftPagePanel", {
                panel,
                panelIndex: this.currentlyEditingPanelIndex,
                regionId: this.selectedRegionId,
                programTypeId: this.selectedProgramTypeId,
            });
        },
        onPanelSelect(panel) {
            if (!this.draftPanelPage)
                this.$store.commit("panelPages_addEmptyDraftPage", {
                    regionId: this.selectedRegionId,
                    programTypeId: this.selectedProgramTypeId,
                    pageTypeId: this.selectedPageTypeId,
                    pageName: `${this.selectedRegionName} - ${
                        this.selectedProgramTypeName
                    } - ${Date.now()}`,
                });
            this.$store.commit("panelPages_setDraftPagePanel", {
                panel,
                panelIndex: this.currentlyEditingPanelIndex,
                regionId: this.selectedRegionId,
                programTypeId: this.selectedProgramTypeId,
            });
        },
        onPanelPageSave() {
            const action = this.draftPanelPage.id
                ? "panelPages_editPage"
                : "panelPages_createPage";

            this.$store.commit("panelPages_set", {isBusy: true});
            this.$store
                .dispatch(action, this.draftPanelPage)
                .catch((error) => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.save"),
                    });
                })
                .finally(() => {
                    this.$store.commit("panelPages_set", {isBusy: false});
                });
        },
        onPublishConfirmOpen() {
            this.$refs.publishConfirmModal.$show(this.draftPanelPage);
        },
    },
};
</script>

<style scoped>
.draft-panels {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
}
</style>

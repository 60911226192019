<template>
    <div>
        <Spinner size="100px" />
    </div>
</template>

<script>
import Spinner from "@mediarails/vue-spinner";
export default {
    name: "ViewLogin",
    components: {Spinner},
    created() {
        this.$store.dispatch("session_login");
    },
};
</script>

<template>
    <Modal
        :busy="busy"
        :show="showModal"
        :close-on-mask-click="false"
        :close-on-esc="false"
        :padding="true"
        @close="$hide"
    >
        <div class="mx-6">
            <div class="text-center text-3xl">
                {{ title }}
            </div>
            <div class="mt-5 text-center text-sm">
                {{ description }}
            </div>
            <div v-if="warning" class="mt-5 text-center text-sm text-danger">
                {{ warning }}
            </div>
            <div class="mt-8 text-center">
                <Button @click="$hide">Cancel</Button>
                <Button
                    @click="onConfirm"
                    :primary="!dangerous"
                    :danger="dangerous"
                    :disabled="disabled"
                >
                    {{ actionLabel }}
                </Button>
            </div>
        </div>
    </Modal>
</template>

<script>
import Modal from "@mediarails/vue-modal";
import Button from "@mediarails/vue-button";
export default {
    name: "ModalConfirm",
    components: {Modal, Button},
    props: {
        busy: {type: Boolean, default: false},
        title: {type: String, required: true},
        description: {type: String, required: true},
        warning: {type: String, default: null},
        actionLabel: {type: String, required: true},
        dangerous: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false},
    },
    data: () => ({
        showModal: false,
        info: null,
    }),
    methods: {
        $show(info) {
            this.info = info;
            this.showModal = true;
        },
        $hide() {
            this.showModal = false;
        },
        onConfirm() {
            this.$emit("confirm", this.info);
        },
    },
};
</script>

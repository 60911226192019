<template>
    <div class="text-center mx-5">
        <div>
            <span
                v-if="hasUnsavedChanges"
                style="color:orange; font-size:13px;"
            >
                {{ $t("listings.reviewModal.unsavedChangesMsg") }}
            </span>
            <Icon
                v-if="hasUnsavedChanges"
                name="exclamationTriangle"
                size="xs"
                style="color:orange;margin-left:3px;"
            />
            <Button
                :primary="hasUnsavedChanges"
                small
                class="mx-5"
                @click="$emit('save')"
            >
                {{ $t("listings.reviewModal.buttonSave") }}
            </Button>
            <Icon
                v-if="hasUnsavedChanges"
                name="exclamationTriangle"
                size="xs"
                style="color:orange;margin-right:3px;"
            />
            <span
                v-if="hasUnsavedChanges"
                style="color:orange; font-size:13px;"
            >
                {{ $t("listings.reviewModal.unsavedChangesMsg") }}
            </span>
        </div>
        <div v-if="taskTypes.length" class="mt-3">
            <span class="text-sm mr-2">
                {{ $t("listings.reviewModal.tasksLabel") }}
            </span>
            <Tag v-for="task in taskTypes" :key="task">
                {{ task }}
            </Tag>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Button from "@mediarails/vue-button";
import Tag from "@mediarails/vue-tag";
import Icon from "@mediarails/vue-fa-icon";
export default {
    name: "ListingsSidebarEnhanceActions",
    components: {Button, Tag, Icon},
    computed: mapState({
        listing() {
            let s = this.$store.state;
            let listings = s.listings.listings;
            let selectedIndex = s.listings.selectedListingIndex;
            return listings[selectedIndex];
        },
        taskTypes() {
            return this.listing ? this.listing.taskTypes || [] : [];
        },
        hasUnsavedChanges: s => s.listings.hasUnsavedChanges,
    }),
};
</script>

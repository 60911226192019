<template>
    <ModalConfirm
        ref="deleteConfirm"
        :busy="isBusy"
        :title="
            $tc('panelManager.panels.deleteConfirm.title', deleteCount, {
                count: deleteCount,
            })
        "
        :description="$t('panelManager.panels.deleteConfirm.description')"
        :warning="warningMsg"
        :action-label="$t('panelManager.panels.deleteConfirm.actionLabel')"
        :disabled="panelsInUseCount === deleteCount"
        dangerous
        @confirm="onDeleteConfirm"
    />
</template>

<script>
import Vue from "vue";
import {mapState} from "vuex";
import ModalConfirm from "@/components/ModalConfirm.vue";
export default {
    name: "PanelDeleteConfirm",
    components: {ModalConfirm},
    data: () => ({
        panels: [],
        isBusy: false,
    }),
    computed: mapState({
        deleteCount() {
            return this.panels.length;
        },
        panelsInUseCount() {
            return this.panels.filter(p => {
                return (
                    p.usageAsDraft > 0 ||
                    p.usageAsPublished > 0 ||
                    p.usageAsScheduled > 0
                );
            }).length;
        },
        warningMsg() {
            if (this.panelsInUseCount === this.deleteCount) {
                return this.$i18n.t(
                    "panelManager.panels.deleteConfirm.allPanelsInUse"
                );
            } else if (this.panelsInUseCount > 0) {
                return this.$i18n.tc(
                    "panelManager.panels.deleteConfirm.panelsInUse",
                    this.panelsInUseCount,
                    {count: this.panelsInUseCount}
                );
            } else {
                return null;
            }
        },
    }),
    watch: {},
    mounted() {},
    methods: {
        $show(rows) {
            this.panels = rows;
            this.$refs.deleteConfirm.$show(rows);
        },
        onDeleteConfirm(rows) {
            this.isBusy = true;
            let panelIds = rows.map(p => p.id);
            this.$store
                .dispatch("panels_delete", panelIds)
                .then(() => {
                    this.$refs.deleteConfirm.$hide();
                    this.$emit("finished");
                })
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.fetch"),
                    });
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
    },
};
</script>

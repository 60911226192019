export default [
    {
        label: "William",
        value: "william.hamerjones@impact.com",
    },
    {
        label: "Richa",
        value: "richa.dani@impact.com",
    },
    {
        label: "Tiah",
        value: "tiahngodfrey@gmail.com",
    },
    {
        label: "Daniel Clotfelter",
        value: "daniel.clotfelter@impact.com",
    },
    {
        label: "Jason Apollos",
        value: "japollos@impact.com",
    },
    {
        label: "John Sherriff",
        value: "john@impact.com",
    },
    {
        label: "Ròchelle Nathan",
        value: "rochelle@impact.com",
    },
    {
        label: "Dominyka Bucelyte",
        value: "dominyka.bucelyte@impact.com",
    },
    {
        label: "Lisa Kay",
        value: "lisa.kay@impact.com",
    },
    {
        label: "Rosie Rae",
        value: "rosie.rae@impact.com",
    },
    {
        label: "Priya Shukla",
        value: "priya.shukla@impact.com",
    },
    {
        label: "Cris Tan",
        value: "cris.tan@impact.com",
    },
    {
        label: "Cleo Cupido",
        value: "cleo@impact.com",
    },
];

<template>
    <div class="shadow-tag-field flex">
        <div class="flex-1">
            <div
                class="shadow-tag-field__label"
                :class="{'text-tertiary': valueMatches === false}"
            >
                {{ label }}
            </div>
            <div
                class="shadow-tag-field__value border rounded px-1 py-1"
                :class="{
                    'border-tertiary': valueMatches === false,
                    'border-grey': valueMatches,
                }"
            >
                <Tag v-for="v in value" :key="v" small>
                    <span class="text-white">{{ v }}</span>
                </Tag>
            </div>
        </div>
        <div class="self-center pl-3" style="margin-top:25px;">
            <Button
                :disabled="disableCopy"
                style="margin-bottom:2px;"
                @click="$emit('copyValue', value)"
            >
                <Icon name="longArrowRight" />
            </Button>
        </div>
    </div>
</template>

<script>
import Button from "@mediarails/vue-button";
import Icon from "@mediarails/vue-fa-icon";
import Tag from "@mediarails/vue-tag";
import {readOnlyFields} from "@/field-config";

export default {
    name: "ShadowTagField",
    components: {Tag, Button, Icon},
    props: {
        name: {type: String, default: null},
        label: {type: String, default: null},
        value: {type: Array, default: () => []},
        valueMatches: {type: Boolean, default: true},
    },
    data: () => ({}),
    computed: {
        disableCopy() {
            return this.value === null || readOnlyFields.includes(this.name);
        },
    },
    watch: {},
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.shadow-tag-field {
    margin-bottom: 28px;
}

.shadow-tag-field__label {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 4px;
    box-sizing: border-box;
    text-align: left;
}

.shadow-tag-field__value {
    font-size: 15px;
    box-sizing: border-box;
    min-height: 40px;
}
</style>

<template>
    <div class="shadow-text-field flex">
        <div class="flex-1">
            <div
                class="shadow-text-field__label"
                :class="{'text-tertiary': valueMatches === false}"
            >
                {{ label }}
            </div>
            <div
                class="shadow-text-field__value border rounded px-2 py-2"
                :class="{
                    'border-tertiary': valueMatches === false,
                    'border-grey': valueMatches,
                }"
            >
                {{ value }}
            </div>
        </div>
        <div class="self-end pl-3">
            <Button
                :disabled="value == null"
                style="margin-bottom:2px;"
                @click="$emit('copyValue', value)"
            >
                <Icon name="longArrowRight" />
            </Button>
        </div>
    </div>
</template>

<script>
import Button from "@mediarails/vue-button";
import Icon from "@mediarails/vue-fa-icon";
export default {
    name: "ShadowTextField",
    components: {Button, Icon},
    props: {
        label: {type: String, default: null},
        value: {type: String, default: null},
        valueMatches: {type: Boolean, default: true},
    },
    data: () => ({}),
    computed: {},
    watch: {},
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.shadow-text-field {
    margin-bottom: 28px;
}

.shadow-text-field__label {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 4px;
    box-sizing: border-box;
    text-align: left;
}

.shadow-text-field__value {
    font-size: 13px;
    height: 40px;
    max-width: 390px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
}
</style>

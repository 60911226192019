import _ from "lodash";
import Ajax from "@mediarails/util-ajax";
import apiEnv from "./environment";
import users from "@/../users.config";

const errorPrefix = "API:static";

export default apiUtil => {
    return {
        async fetchListingsInfo() {
            try {
                return await Ajax.getCached({
                    url: apiEnv.urls.static,
                    params: null,
                    dataPath: null,
                    convertDates: false,
                    renameToCamelCase: false,
                    cacheExpirationMinutes: 15,
                    axiosConfig: await apiUtil.getAjaxConfig(),
                });
            } catch (error) {
                apiUtil.handleApiError(
                    error,
                    `${errorPrefix}:fetchListingsInfo()`
                );
            }
        },
        async fetchUsers() {
            return _.sortBy(users, x => x.label);
        },
    };
};

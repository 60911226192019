<template>
    <div>
        <div class="text-center">
            <div
                class="bg-contain bg-no-repeat bg-center mr-4 bg-neon-pink inline-block align-middle border"
                :style="{
                    'background-image': `url(${logoUrl})`,
                    width: '220px',
                    height: '220px',
                }"
            />
            <div
                style="width:30px;"
                class="text-center pl-3 inline-block align-middle"
                :class="{
                    'text-success': listing.logoUrlApproved === true,
                    'text-danger': listing.logoUrlApproved === false,
                }"
            >
                <Icon
                    v-if="listing.logoUrlApproved === true"
                    name="checkCircleSolid"
                    size="1x"
                    class="align-middle ml-1"
                />
                <Icon
                    v-if="listing.logoUrlApproved === false"
                    name="flagSolid"
                    size="sm"
                    class="align-middle ml-1"
                />
            </div>
        </div>
        <div class="flex mt-6">
            <div
                class="flex-1 text-lg font-bold truncate"
                :class="{'text-danger': isRejectedOrFlagged}"
            >
                {{ listing.name }}
            </div>
            <div
                class="text-right text-lg capitalize pl-6"
                :class="{
                    'text-success':
                        listing.status == enumListingStatus.approved,
                    'text-danger': isRejectedOrFlagged,
                }"
            >
                {{ listing.status }}
            </div>
        </div>
        <div class="mt-5">{{ listing.description }}</div>
        <div class="mt-7">
            <div>
                {{ $t("listings.reviewModal.headerMediaProperties") }}
            </div>
            <div
                v-for="mp in listing.mediaProperties"
                :key="mp.url"
                class="mt-2 pl-4 truncate"
            >
                <Tooltip
                    :content="
                        (mp.issues || []).map(x => x.type).join(', ') ||
                            $t(
                                'listings.reviewModal.mediaProperties.noDataQualityIssues'
                            )
                    "
                >
                    <Icon
                        name="exclamationTriangle"
                        class="text-danger mr-1"
                        size="sm"
                        v-if="mediaPropertyHasIssues(mp)"
                    />
                    <Icon
                        name="checkCircleSolid"
                        class="text-secondary mr-1"
                        size="sm"
                        v-else-if="mp.approved === true"
                    />
                    <Icon
                        name="timesOctagonSolid"
                        class="text-danger mr-1"
                        size="sm"
                        v-else-if="mp.approved === false"
                    />
                    <Icon
                        v-if="mp.primary"
                        name="starSolid"
                        size="sm"
                        class="text-secondary mr-1"
                    />
                    <a
                        :href="mp.url"
                        target="_blank"
                        class="no-underline text-primary"
                        :class="{'text-danger': mediaPropertyHasIssues(mp)}"
                    >
                        {{ mp.url }}
                    </a>
                </Tooltip>
            </div>
        </div>
        <div
            class="mt-7"
            v-if="
                listing.listingFeedback != null &&
                    listing.listingFeedback.length > 0
            "
        >
            <div>
                {{ $t("listings.reviewModal.headerListingFeedback") }}
            </div>
            <div
                v-for="lf in listing.listingFeedback"
                :key="lf.feedbackId"
                class="mt-2 pl-4"
            >
                <div class="text-danger">
                    <Icon name="exclamationTriangle" class="mr-1" size="sm" />
                    {{ lf.feedbackType }}
                </div>
                <div>{{ "Received: " + $d(lf.submittedAt, "short") }}</div>
                <div>{{ "Application: " + lf.submittedFrom }}</div>
                <div>
                    {{ "Submitted By: " + (lf.submittedBy || "Unknown") }}
                </div>
                <div class="flex-1">
                    {{ "Comment: " + (lf.comment || "No comment available") }}
                </div>
            </div>
        </div>
        <div class="mt-7">
            <div v-for="v in values" :key="v.key" class="flex mb-5">
                <div class="" style="width:150px;">
                    {{ v.label }}
                </div>
                <div
                    style="width:30px;"
                    class="text-center pr-3"
                    :class="{
                        'text-success': v.approved === true,
                        'text-danger': v.approved === false,
                    }"
                >
                    <Icon
                        v-if="v.approved === true"
                        name="checkCircleSolid"
                        size="1x"
                        class="align-middle ml-1"
                    />
                    <Icon
                        v-if="v.approved === false"
                        name="flagSolid"
                        size="sm"
                        class="align-middle ml-1"
                    />
                </div>
                <div class="flex-1">
                    <template v-if="v.type == 'tag' && v.value">
                        <Tag
                            v-for="(t, i) in v.value.split(',')"
                            :key="`${t} ${i}`"
                        >
                            {{ t }}
                        </Tag>
                    </template>
                    <template
                        v-else-if="
                            v.type == 'boolean' && typeof v.value === 'boolean'
                        "
                    >
                        <span :class="{'text-tertiary': v.value === true}">
                            {{ v.value ? "Yes" : "No" }}
                        </span>
                    </template>
                    <template v-else-if="v.type == 'spam'">
                        <span v-if="v.value === false" class="text-danger">
                            <Icon
                                name="userSecretSolid"
                                size="lg"
                                class="align-middle ml-1 mr-1"
                            />
                            {{ $t("listings.reviewModal.isNotSpamYesLabel") }}
                        </span>
                        <span v-else-if="v.value === true" class="text-success">
                            <Icon
                                name="userCheckSolid"
                                size="lg"
                                class="align-middle ml-1 mr-1"
                            />
                            {{ $t("listings.reviewModal.isNotSpamNoLabel") }}
                        </span>
                        <template v-else>
                            {{ $t("listings.reviewModal.isNotSpamEmptyLabel") }}
                        </template>
                    </template>
                    <template v-else-if="v.type == 'currency'">
                        {{ $n(v.value, "currency-float") }}
                    </template>
                    <template v-else-if="v.type == 'currencyInt'">
                        {{ $n(v.value, "currency-int") }}
                    </template>
                    <template v-else-if="v.type == 'floatSingle'">
                        {{ $n(v.value, "float-single") }}
                    </template>
                    <template v-else-if="v.type == 'dateShort'">
                        {{ v.value ? $d(v.value, "short") : "--" }}
                    </template>
                    <template v-else>
                        {{ v.value || "--" }}
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Icon from "@mediarails/vue-fa-icon";
import enumListingStatus from "@/models/enum-listing-status";
import Tag from "@mediarails/vue-tag";
import Tooltip from "@mediarails/vue-impact-tooltip";
export default {
    name: "ListingsSidebarReviewContent",
    components: {Icon, Tag, Tooltip},
    data: () => ({
        enumListingStatus: enumListingStatus,
    }),
    computed: mapState({
        isRejectedOrFlagged() {
            return (
                this.listing.status == this.enumListingStatus.rejected ||
                this.listing.status == this.enumListingStatus.flagged
            );
        },
        listing() {
            let s = this.$store.state;
            let listings = s.listings.listings;
            let selectedIndex = s.listings.selectedListingIndex;
            return listings[selectedIndex] || {};
        },
        logoUrl() {
            let url = (this.listing || {}).logoUrl || "";
            if (!url.startsWith("http://") && !url.startsWith("https://"))
                url = `http://${url}`;
            return url;
        },
        values() {
            return [
                {
                    key: "isNotSpam",
                    label: "Spam?",
                    value: this.listing.isNotSpam,
                    approved: null,
                    type: "spam",
                },
                {
                    key: "published",
                    label: "Published",
                    value: this.listing.published,
                    approved: null,
                    type: "boolean",
                },
                {
                    key: "source",
                    label: "Source",
                    value: this.listing.source,
                    approved: null,
                    type: "string",
                },
                {
                    key: "id",
                    label: "Listing ID",
                    value: this.listing.id,
                    approved: null,
                    type: "string",
                },
                {
                    key: "radiusPublisherId",
                    label: "Radius Publisher ID",
                    value: this.listing.radiusPublisherId,
                    approved: null,
                    type: "string",
                },
                {
                    key: "radiusPublisherName",
                    label: "Radius Name",
                    value: this.listing.radiusPublisherName,
                    approved: null,
                    type: "string",
                },
                {
                    key: "revenue180Days",
                    label: "Revenue (180 days)",
                    value: this.listing.revenue180Days,
                    approved: null,
                    type: "currencyInt",
                },
                {
                    key: "actions180Days",
                    label: "Actions (180 days)",
                    value: this.listing.actions180Days,
                    approved: null,
                    type: "string",
                },
                {
                    key: "reachRating",
                    label: "Partner Size",
                    value: this.listing.reachRating,
                    approved: null,
                    type: "floatSingle",
                },
                {
                    key: "partnerType",
                    label: "Listing type",
                    value: this.listing.partnerType,
                    approved: this.listing.partnerTypeApproved,
                    type: "string",
                },
                {
                    key: "locationCountry",
                    label: "Country",
                    value: this.listing.locationCountry,
                    approved: this.listing.locationCountryApproved,
                    type: "string",
                },
                {
                    key: "promoCountries",
                    label: "Audience countries",
                    value: (this.listing.promoCountries || []).join(", "),
                    approved: null,
                    type: "string",
                },
                {
                    key: "language",
                    label: "Language",
                    value: this.listing.language,
                    approved: this.listing.languageApproved,
                    type: "string",
                },
                {
                    key: "promoMethods",
                    label: "Promo methods",
                    value: (this.listing.promoMethods || []).join(", "),
                    approved: this.listing.promoMethodsApproved,
                    type: "string",
                },
                {
                  key: "programTypes",
                  label: "Program Types",
                  value: (this.listing.programTypes || []).join(", "),
                  approved: null,
                  type: "string",
                },
                {
                    key: "tags",
                    label: "Tags",
                    value: (this.listing.tags || []).join(", "),
                    approved: this.listing.tagsApproved,
                    type: "tag",
                },
                {
                    key: "editorialTags",
                    label: "Editorial Tags",
                    value: (this.listing.editorialTags || []).join(", "),
                    type: "tag",
                },
                {
                    key: "categories",
                    label: "Categories",
                    value: (this.listing.categories || []).join(", "),
                    approved: this.listing.categoriesApproved,
                    type: "tag",
                },
                {
                    key: "gender",
                    label: "Gender",
                    value: this.listing.gender,
                    approved: this.listing.genderApproved,
                    type: "string",
                },
                {
                    key: "contactIsAgent",
                    label: "Contact is agent",
                    value: this.listing.contactIsAgent,
                    approved: this.listing.contactIsAgentApproved,
                    type: "boolean",
                },
                {
                    key: "contactEmail",
                    label: "Email",
                    value: this.listing.contactEmail,
                    approved: this.listing.contactEmailApproved,
                    type: "string",
                },
                {
                    key: "contactFullName",
                    label: "Full name",
                    value: this.listing.contactFullName,
                    approved: this.listing.contactFullNameApproved,
                    type: "string",
                },
                {
                    key: "contactFirstName",
                    label: "First name",
                    value: this.listing.contactFirstName,
                    approved: this.listing.contactFirstNameApproved,
                    type: "string",
                },
                {
                    key: "contactLastName",
                    label: "Last name",
                    value: this.listing.contactLastName,
                    approved: this.listing.contactLastNameApproved,
                    type: "string",
                },
                {
                    key: "contactPhone",
                    label: "Phone",
                    value: this.listing.contactPhone,
                    approved: this.listing.contactPhoneApproved,
                    type: "string",
                },
                {
                    key: "notes",
                    label: "Notes",
                    value: this.listing.notes,
                    approved: null,
                    type: "string",
                },
                {
                    key: "doNotPay",
                    label: "Do Not Pay (DNP)",
                    value: this.listing.doNotPay,
                    approved: null,
                    type: "boolean",
                },
                {
                    key: "partnerStatus",
                    label: "Partner Status",
                    value: this.listing.partnerStatus,
                    approved: null,
                    type: "string",
                },
            ];
        },
    }),
    methods: {
        mediaPropertyHasIssues(mp) {
            let issues = mp.issues || [];
            return issues.filter(i => i.dismissed === false).length > 0;
        },
    },
};
</script>

import _ from "lodash";
import auth from "@/util/auth";
import listingsApi from "./api/listings";
import staticApi from "./api/static";
import panelsApi from "./api/panels";
import panelPagesApi from "./api/panel-pages";
import Vue from "vue";

const apiUtil = {
    async getAjaxConfig() {
        try {
            const authToken = await auth.getIdToken();
            if (!authToken)
                throw new Error("authentication failed please try again.");
            return {
                responseType: "json",
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            };
        } catch (error) {
            throw new Error(`Api authentication error: ${error}`);
        }
    },
    handleApiError(error, msgPrefix) {
        const msg =
            _.get(error, "response.data.message") ||
            _.get(error, "message") ||
            error;
        console.error(`${msgPrefix} -- ${msg}`);
        Vue.rollbar.error(error);
        throw new Error(msg);
    },
};

export default {
    listings: listingsApi(apiUtil),
    static: staticApi(apiUtil),
    panels: panelsApi(apiUtil),
    panelPages: panelPagesApi(apiUtil),
};

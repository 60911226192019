<template>
    <div class="text-center">
        <div>
            <Button large @click="$emit('selectPanel')">
                {{ $t("panelManager.regions.selectPanelButton") }}
            </Button>
        </div>
        <div class="mt-6">
            <Button large @click="$emit('createPanel')">
                {{ $t("panelManager.regions.createPanelButton") }}
            </Button>
        </div>
    </div>
</template>

<script>
import Button from "@mediarails/vue-button";
export default {
    name: "PanelHoverActions",
    components: {Button},
    props: {},
    data: () => ({}),
    computed: {},
    watch: {},
    mounted() {},
    methods: {},
};
</script>

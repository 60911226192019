<template>
    <div v-if="editedListing">
        <div class="mt-5">
            <div
                class="bg-contain bg-no-repeat bg-center mx-auto bg-dark border"
                :style="{
                    'background-image': `url(${logoUrl})`,
                    width: '220px',
                    height: '220px',
                }"
            />
        </div>
        <!-- Logo Edit is current hidden due to issue we did not get better logo image data -->
        <!-- Please check for JIRA ticket https://impact.atlassian.net/browse/PD-980  -->
        <!-- PR https://github.com/ImpactInc/pd-content-review-ui/pull/19 for issue detail -->
        <LogoOptionsModal ref="logo-options-modal" v-if="false" />
        <div class="flex justify-center" v-if="false">
            <Button class="outline-none" text @click="showLogoOptionsModal">
                <Icon name="edit" size="sm" class="mr-1" />
                {{ $t("listings.reviewModal.changeLogoBtnLabel") }}
            </Button>
            <Button
                :disabled="editedListing.logoUrl === listing.logoUrl"
                class="outline-none"
                text
                @click="
                    () =>
                        changeListingValue({
                            name: 'logoUrl',
                            value: listing.logoUrl,
                        })
                "
            >
                <Icon name="undo" size="sm" class="mr-1" />
                {{ $t("listings.reviewModal.resetLogoBtnLabel") }}
            </Button>
        </div>
        <div
            class="mt-5"
            v-if="
                editedListing.listingFeedback != null &&
                    editedListing.listingFeedback.length > 0
            "
        >
            <div>
                {{ $t("listings.reviewModal.headerListingFeedback") }}
            </div>
            <div
                v-for="lf in editedListing.listingFeedback"
                :key="lf.feedbackId"
                class="mt-2 pl-4"
            >
                <div class="text-danger">
                    <Icon name="exclamationTriangle" class="mr-1" size="sm" />
                    {{ lf.feedbackType }}
                </div>
                <div>{{ "Received: " + $d(lf.submittedAt, "short") }}</div>
                <div>{{ "Application: " + lf.submittedFrom }}</div>
                <div>
                    {{ "Submitted By: " + (lf.submittedBy || "Unknown") }}
                </div>
                <div class="flex-1">
                    {{ "Comment: " + (lf.comment || "No comment available") }}
                </div>
            </div>
        </div>
        <div class="mt-5">
            {{ $t("listings.reviewModal.statusLabel") }}
            <span
                class="capitalize ml-2"
                :class="{
                    'text-success':
                        editedListing.status == enumListingStatus.approved,
                    'text-danger':
                        editedListing.status == enumListingStatus.rejected,
                }"
            >
                {{ editedListing.status }}
            </span>
        </div>
        <div class="mt-5">
            <span>{{ $t("listings.reviewModal.form.spamLabel") }}</span>
            <span class="ml-4 text-grey-darker">
                <span
                    class="hover:text-success cursor-pointer"
                    :class="{
                        'text-success': editedListing.isNotSpam === true,
                    }"
                    @click="changeIsNotSpam(true)"
                >
                    <Icon
                        name="userCheckSolid"
                        size="lg"
                        class="align-middle mr-1"
                    />
                    {{ $t("listings.reviewModal.form.spamNoButton") }}
                </span>
                <span
                    class="hover:text-danger cursor-pointer"
                    :class="{
                        'text-danger': editedListing.isNotSpam === false,
                    }"
                    @click="changeIsNotSpam(false)"
                >
                    <Icon
                        name="userSecretSolid"
                        size="lg"
                        class="align-middle ml-4 mr-1"
                    />
                    {{ $t("listings.reviewModal.form.spamYesButton") }}
                </span>
            </span>
        </div>
        <div class="sidebar-enhance-form mt-7">
            <FormBuilder
                :data="sidebarEnhanceFormMeta"
                :values="editedListing"
                @input="changeListingValue"
            />
        </div>
        <div class="mt-7 mr-3">
            <TextArea
                label="Notes (not shown to customers)"
                :value="editedListing.notes"
                height="70px"
                @input="v => changeListingValue({name: 'notes', value: v})"
            />
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import _ from "lodash";
import {mapState} from "vuex";
import sidebarEnhanceFormMeta from "./SidebarEnhanceFormMeta.js";
import FormBuilder from "@mediarails/vue-form-builder";
import enumListingStatus from "@/models/enum-listing-status";
import Icon from "@mediarails/vue-fa-icon";
import TextArea from "@mediarails/vue-textarea-input";
import Button from "@mediarails/vue-button";
import LogoOptionsModal from "./LogoOptionsModal";

export default {
    name: "SidebarEnhanceForm",
    components: {FormBuilder, Icon, TextArea, LogoOptionsModal, Button},
    data: () => ({
        enumListingStatus: enumListingStatus,
    }),
    computed: mapState({
        editedListing: s => s.listings.editedListing,
        countries: s => s.static.countries,
        categories: s => s.static.categories,
        partnerTypes: s => s.static.partnerTypes,
        promoMethods: s => s.static.promoMethods,
        genders: s => s.static.genders,
        languages: s => s.static.languages,
        sidebarEnhanceFormMeta() {
            return sidebarEnhanceFormMeta({
                countries: this.countries.map(x => ({label: x, value: x})),
                categories: this.categories.map(x => ({label: x, value: x})),
                partnerTypes: this.partnerTypes.map(x => ({
                    label: x,
                    value: x,
                })),
                promoMethods: this.promoMethods.map(x => ({
                    label: x,
                    value: x,
                })),
                genders: this.genders.map(x => ({
                    label: x,
                    value: x,
                })),
                languages: this.languages.map(x => ({
                    label: x,
                    value: x,
                })),
                hasRadiusPublisherId: this.hasRadiusPublisherId,
                hasListingFeedback: this.hasListingFeedback,
            });
        },
        listing() {
            let s = this.$store.state;
            let listings = s.listings.listings;
            let selectedIndex = s.listings.selectedListingIndex;
            return listings[selectedIndex] || {};
        },
        logoUrl() {
            let url = (this.editedListing || {}).logoUrl || "";
            if (!url.startsWith("http://") && !url.startsWith("https://"))
                url = `http://${url}`;
            return url;
        },
        hasRadiusPublisherId() {
            return this.listing
                ? this.listing.radiusPublisherId != null
                : false;
        },
        hasListingFeedback() {
            return this.listing
                ? this.listing.listingFeedback != null &&
                      this.listing.listingFeedback.length > 0
                : false;
        },
    }),
    watch: {
        listing: {
            immediate: true,
            deep: true,
            handler: function(value) {
                this.$store.commit("listings_setListingToEdit", value);
            },
        },
    },
    methods: {
        showLogoOptionsModal() {
            this.$refs["logo-options-modal"].$show();
        },
        changeListingValue({name, value}) {
            this.$store.commit("listings_update_editedListingValue", {
                name,
                value,
            });
        },
        $saveListingChanges() {
            this.$store.commit("reviewModal_busyStart");
            this.$store
                .dispatch("listings_edit", {listing: this.editedListing})
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.edit"),
                    });
                })
                .finally(() => {
                    this.$store.commit("reviewModal_busyStop");
                });
        },
        changeIsNotSpam(newValue) {
            if (
                (newValue === true && this.editedListing.isNotSpam === true) ||
                (newValue === false && this.editedListing.isNotSpam === false)
            ) {
                newValue = null;
            }
            this.changeListingValue({name: "isNotSpam", value: newValue});
        },
    },
};
</script>

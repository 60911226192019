<template>
    <div class="flex">
        <div>
            <Search
                always-show
                :value="panelFilter.search"
                @search="onSearch"
            />
        </div>
        <div class="px-8">
            <FilterFields
                :field-meta="filterFieldsMeta"
                @fieldUpdate="onFilterFieldUpdate"
            />
        </div>
        <div class="text-right flex-1">
            <Button primary :disabled="!userCanCreate" @click="onNewPanelClick">
                {{ $t("panelManager.panels.createPanelButton") }}
            </Button>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import {mapState} from "vuex";
import Search from "@mediarails/vue-impact-search";
import FilterFields from "@mediarails/vue-impact-filter-fields";
import Button from "@mediarails/vue-button";
import filterConfig from "@/../panel.filters.config";
export default {
    name: "PanelsView",
    components: {Search, FilterFields, Button},
    props: {},
    data: () => ({}),
    computed: mapState({
        panelFilter: s => s.panelManager.panels.filter,
        filterFieldsMeta() {
            const staticData = this.$store.state.static;
            return filterConfig.map(x => {
                return {
                    id: x.id,
                    label: x.label,
                    type: x.type,
                    disabled: x.disabled,
                    pinned: x.pinned,
                    options: x.optionDataProp
                        ? this[x.optionDataProp]
                        : x.getOptions(staticData),
                };
            });
        },
        userCanCreate() {
            return this.$store.getters.userHasPermission("canCreatePanels");
        },
    }),
    watch: {},
    mounted() {},
    methods: {
        onSearch(searchQuery) {
            this.$store.commit("panels_set_filter", {search: searchQuery});
            this.refetchPanels();
        },
        onFilterFieldUpdate({id, value}) {
            if (id) {
                this.$store.commit("panels_set_filter", {[id]: value});
                this.refetchPanels();
            }
        },
        refetchPanels() {
            this.$store.commit("panels_set", {
                isBusy: true,
                pageIndex: 0,
                panels: [],
            });
            this.$store
                .dispatch("panels_fetch")
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.fetch"),
                    });
                })
                .finally(() => {
                    this.$store.commit("panels_set", {isBusy: false});
                });
        },
        onNewPanelClick() {
            this.$emit("panelCreate");
        },
    },
};
</script>

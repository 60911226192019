<template>
    <LayoutSingleColumn>
        <PageTitle>
            <span slot="title">{{ $t("accessDenied.pageTitle") }}</span>
        </PageTitle>
        <div>{{ errorDescription }} Did you forget to connect to the VPN?.</div>
        <div class="mt-10">
            <img src="denied.jpeg" />
        </div>
    </LayoutSingleColumn>
</template>

<script>
import {mapState} from "vuex";
import LayoutSingleColumn from "@/components/LayoutSingleColumn.vue";
import PageTitle from "@mediarails/vue-page-title";
export default {
    name: "ViewUser",
    components: {LayoutSingleColumn, PageTitle},
    computed: mapState({
        errorDescription() {
            return this.$route.query.error_description;
        },
    }),
};
</script>

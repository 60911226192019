<template>
    <SimpleDialog
        ref="dialog"
        :busy="false"
        :title="$i18n.t('listings.editorialAddTagModal.title')"
        :description="$i18n.t('listings.editorialAddTagModal.description')"
        :action-label="$i18n.t('listings.editorialAddTagModal.action.addEtag')"
        :action-enabled="!!selectedTag || !!customTag"
        :action-busy="isBusy"
        @action="saveChanges"
        @hide="$hide"
    >
        <div class="flex" style="width: 500px">
            <div class="border-r pr-7 pb-2" style="width: 50%">
                <div class="flex">
                    <MultiselectInput
                        :label="
                            $i18n.t(
                                'listings.editorialAddTagModal.form.tagSelect'
                            )
                        "
                        :options="tagOptions"
                        :max="1"
                        :value="selectedTag ? selectedTag.value : null"
                        class="flex-1"
                        :placeholder="
                            $i18n.t(
                                'listings.editorialAddTagModal.form.selectPlaceholder'
                            )
                        "
                        :disabled="!!selectedTag || !!customTag"
                        @change="tagSelected"
                    />
                </div>
                <div class="flex mt-5">
                    <TextInput
                        large
                        :label="
                            $i18n.t(
                                'listings.editorialAddTagModal.form.tagCreate'
                            )
                        "
                        class="flex-1"
                        :placeholder="
                            $i18n.t(
                                'listings.editorialAddTagModal.form.createPlaceholder'
                            )
                        "
                        ref="customTag"
                        @validity="checkValid"
                        pattern="^[a-zA-Z0-9].*"
                        :disabled="!!customTag || !!selectedTag"
                    />
                    <Button
                        class="mt-5 ml-1"
                        @click="addCustomTag"
                        :disabled="!customTagValid || !!tagAdded"
                    >
                        {{
                            $t("listings.editorialAddTagModal.customTagAddBtn")
                        }}
                    </Button>
                </div>
            </div>
            <div style="width: 20px; position: relative">
                <Icon
                    name="caretRight"
                    size="lg"
                    class="absolute"
                    style="
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        color: lightGrey;
                    "
                />
            </div>
            <div class="pl-3 flex-1">
                <div>
                    {{ $t("listings.editorialAddTagModal.currentGroupValues") }}
                </div>
                <Tag v-if="tagAdded" class="mt-3" large>
                    {{ tagAdded.label }}
                    <Icon
                        name="times"
                        size="sm"
                        clickable
                        danger
                        class="ml-1"
                        @click="removeTag"
                    />
                </Tag>
            </div>
        </div>
    </SimpleDialog>
</template>

<script>
import Vue from "vue";
import {mapState} from "vuex";
import SimpleDialog from "@mediarails/vue-modal-simple-dialog";
import TextInput from "@mediarails/vue-text-input";
import Button from "@mediarails/vue-button";
import MultiselectInput from "@mediarails/vue-multiselect-input";
import Tag from "@mediarails/vue-tag";
import Icon from "@mediarails/vue-fa-icon";

export default {
    name: "ModalAddEtag",
    components: {SimpleDialog, Icon, MultiselectInput, TextInput, Button, Tag},
    data() {
        return {
            selectedListingIds: [],
            selectedTag: null,
            customTag: null,
            customTagValid: false,
            isBusy: false,
        };
    },
    computed: {
        ...mapState({listings: (state) => state.listings.listings}),
        tagOptions() {
            const tags = this.$store.state.static.editorialTags;

            return tags.map((tag) => ({
                label: tag.name,
                value: tag.name,
            }));
        },
        tagAdded() {
            return this.selectedTag || this.customTag;
        },
    },
    methods: {
        $show(listingIds) {
            this.selectedListingIds = listingIds;
            this.$refs.dialog.$show();
        },
        $hide() {
            this.selectedListingIds = [];
            this.selectedTag = null;
            this.customTag = null;
            this.customTagValid = false;
            this.isBusy = false;
            this.$refs.dialog.$hide();
        },
        removeTag() {
            this.selectedTag = null;
            this.customTag = null;
            this.customTagValid = false;
        },
        checkValid(valid) {
            let customTag = this.$refs.customTag.$el.lastElementChild.value;

            if (!customTag) {
                this.customTagValid = false;
                return;
            }
            this.customTagValid = valid;
        },
        addCustomTag() {
            let customTag = this.$refs.customTag.$el.lastElementChild.value;
            this.customTag = this.findTag(customTag) || {
                label: customTag,
                value: customTag,
            };
        },
        tagSelected(val) {
            this.selectedTag = this.findTag(val);
        },
        findTag(val) {
            return this.tagOptions.find((tag) => val === tag.value);
        },
        saveChanges() {
            this.isBusy = true;
            this.$store
                .dispatch("listings_addRemoveTags", {
                    listingIds: this.selectedListingIds,
                    addTags: [this.tagAdded.value],
                    scheme: "editorial",
                })
                .then((resp) => {
                    this.isBusy = false;
                    this.$emit("hide");
                    if (resp.ids && !resp.ids.length) {
                        return this.$notify({
                            type: "error",
                            text: resp.message,
                        });
                    } else if (
                        resp.ids &&
                        resp.ids.length !== this.selectedListingIds.length
                    ) {
                        const failAddCount =
                            this.selectedListingIds.length - resp.ids.length;
                        this.$notify({
                            type: "success",
                            text: this.$i18n.t(
                                "listings.editorialAddTagModal.etagAddSuccessPartial",
                                {count: failAddCount, msg: resp.message}
                            ),
                        });
                    }
                    this.$notify({
                        type: "success",
                        text: this.$i18n.t(
                            "listings.editorialAddTagModal.etagAddSuccess",
                            {count: this.selectedListingIds.length}
                        ),
                    });
                    this.$hide();
                })
                .catch((error) => {
                    this.isBusy = false;
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.save"),
                    });
                });
        },
    },
};
</script>

<template>
    <FormBuilderModal
        ref="dialog"
        :title="$i18n.t('listings.assignToModal.title')"
        :description="$i18n.t('listings.assignToModal.description')"
        :action-label="$i18n.t('listings.assignToModal.action')"
        :busy="isBusy"
        :form-meta="formMeta"
        :form-values="formValues"
        @action="saveChangesAndHide"
        @cancel="$hide(false)"
    />
</template>

<script>
import {mapState} from "vuex";
import Vue from "vue";
import FormBuilderModal from "@mediarails/vue-modal-form-builder";
import {notDeepEqual} from "assert";
export default {
    name: "ModalAssignTo",
    components: {FormBuilderModal},
    props: {},
    data: () => ({
        isBusy: false,
        selectedRowIds: [],
        formValues: {
            assignTo: null,
        },
        clearTableSelection: null,
    }),
    computed: mapState({
        userOptions: s => s.static.users,
        formMeta() {
            return [
                {
                    width: "100%",
                    columns: "1fr",
                    fields: [
                        {
                            name: "assignTo",
                            type: "select",
                            columnSpan: 1,
                            settings: {
                                label: this.$i18n.t(
                                    "listings.assignToModal.form.assignTo"
                                ),
                                options: this.userOptions,
                            },
                        },
                    ],
                },
            ];
        },
    }),
    methods: {
        $show(selectedRowIds, clearSelection) {
            this.selectedRowIds = selectedRowIds;
            this.clearTableSelection = clearSelection;
            this.$refs.dialog.$show();
        },
        $hide(clearSelection = true) {
            this.$refs.dialog.$hide();
            if (clearSelection) this.clearTableSelection();
        },
        saveChangesAndHide(formValues) {
            this.isBusy = true;
            this.$store
                .dispatch("listings_assignedTo", {
                    listingIds: this.selectedRowIds,
                    assignedTo: formValues.assignTo,
                })
                .then(() => {
                    this.isBusy = false;
                    this.$hide();
                })
                .catch(error => {
                    this.isBusy = false;
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.save"),
                    });
                });
        },
    },
};
</script>

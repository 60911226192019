import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import Notifications from "vue-notification";
import "@/assets/css/tailwind.css";
import "@/assets/css/variables.css";
import "auth0-js";
import version from "../version.json";

const isProduction = process.env.NODE_ENV === "production";
const isDevelopment = process.env.NODE_ENV === "development";

Vue.config.productionTip = false;

Vue.use(Notifications);

var Rollbar = require("vue-rollbar");
Vue.use(Rollbar, {
    accessToken: "edca8f3189f0421ab3a5c1668fc01ead",
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: isProduction || isDevelopment,
    environment: process.env.NODE_ENV,
    payload: {
        client: {
            javascript: {
                code_version: version,
                source_map_enabled: true,
                guess_uncaught_frames: true,
            },
        },
    },
});

Vue.config.errorhandler = function(err, vm, info) {
    Vue.rollbar.error(err);
};

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount("#app");

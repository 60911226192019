import _ from "lodash";
import Util from "@/util/util";
import api from "@/util/api";
import Vue from "vue";
import filterConfig from "@/../listing.filters.config";

const emptyFilter = () => {
    return _.reduce(
        filterConfig,
        (result, value, key) => {
            result[value.id] = null;
            return result;
        },
        {
            search: null,
        }
    );
};

const defaultState = {
    sortAsc: false,
    sortColumn: "createdAt",
    pageIndex: 0,
    filter: emptyFilter(),
};

export default {
    state: {
        isBusy: false,
        selectedListingIndex: null,
        hasUnsavedChanges: false,
        editedListing: {},
        listings: [],
        logoOptions: [],
        filter: defaultState.filter,
        pageIndex: defaultState.pageIndex,
        pageSize: 100,
        totalCount: 0,
        filteredTotalCount: 0,
        sortAsc: defaultState.sortAsc,
        sortColumn: defaultState.sortColumn,
        countsByUser: {},
        countsByStatus: {
            new: 0,
            flagged: 0,
            approved: 0,
            enhanced: 0,
            rejected: 0,
        },
        countsByWorkflowTag: {},
        countsByTaskType: {},
        countsByIssueType: {},
    },
    mutations: {
        logo_options_set(state, data) {
            let logoOptions = data.logoOptions.map(logoOption => {
                return {
                    ...logoOption,
                    listingId: data.id,
                };
            });
            state.logoOptions = [...state.logoOptions, ...logoOptions];
        },
        update_listing(state, data) {
            let listingIndex = _.findIndex(state.listings, {id: data.id});
            state.listings = state.listings.map((listing, index) =>
                index === listingIndex ? data : listing
            );
        },
        listings_set(state, data) {
            Util.VuexSetValueOnState(state, data);
        },
        listings_setPage(state, {startIndex, listings}) {
            let floatSingleFormatter = new Intl.NumberFormat("en-US", {
                style: "decimal",
                minimumFractionDigits: 1,
                maximumFractionDigits: 1,
            });
            for (let i = startIndex, j = 0; j < listings.length; i++, j++) {
                state.listings[i] = listings[j];
                state.listings[i].reachRating = floatSingleFormatter.format(
                    state.listings[i].reachRating
                );
            }
            state.listings = state.listings.slice(0);
        },
        listings_setListing(state, listingValues) {
            let listing = state.listings.find(x =>
                x ? x.id == listingValues.id : false
            );
            Object.assign(listing, _.cloneDeep(listingValues));
            state.listings = state.listings.slice(0);
        },
        listings_set_filter(state, data) {
            Object.assign(state.filter, data);
            let filterWorkflowTag = state.filter.workflowTags;
            if (!state.countsByWorkflowTag.hasOwnProperty(filterWorkflowTag))
                state.filter.workflowTags = null;
        },
        listings_clear_filter(state, data) {
            state.sortAsc = data.sortAsc;
            state.sortColumn = data.sortColumn;
            state.pageIndex = data.pageIndex;
            state.filter = data.filter;
            state.listings = [];
        },
        listings_setListingAssignedTo(state, {listingIds, assignedTo}) {
            listingIds.forEach(id => {
                let listing = state.listings.find(x =>
                    x ? x.id == id : false
                );
                listing.assignedTo = assignedTo;
            });
        },
        listings_setListingToEdit(state, listing) {
            state.hasUnsavedChanges = false;
            state.editedListing = {};
            let copy = _.cloneDeep(listing);
            _.forOwn(copy, (v, k) => {
                Vue.set(state.editedListing, k, v);
            });
        },
        listings_update_editedListingValue(state, {name, value}) {
            Vue.set(state.editedListing, name, value);
            state.hasUnsavedChanges = true;
        },
        listings_update_editedListingValueMediaProperty(
            state,
            {name, value, index}
        ) {
            Vue.set(state.editedListing.mediaProperties[index], name, value);
            state.hasUnsavedChanges = true;
        },
        listings_update_editedListingValueMediaProperty_setPrimary(
            state,
            index
        ) {
            state.editedListing.mediaProperties.forEach((x, i) => {
                state.hasUnsavedChanges = true;
                if (i == index) {
                    Vue.set(
                        state.editedListing.mediaProperties[i],
                        "primary",
                        true
                    );
                } else {
                    Vue.set(
                        state.editedListing.mediaProperties[i],
                        "primary",
                        false
                    );
                }
            });
        },
        listings_update_editedListingValueMediaProperty_remove(state, index) {
            state.hasUnsavedChanges = true;
            Vue.delete(state.editedListing.mediaProperties, index);
        },
        listings_update_editedListingValueMediaProperty_add(state, {url}) {
            state.hasUnsavedChanges = true;
            state.editedListing.mediaProperties.push({
                approved: null,
                mediaType: "website",
                mediarailsUrl: null,
                primary: false,
                screenshotUrl: null,
                url,
            });
        },
        listings_update_editedListingValueMediaProperty_dismissIssue(
            state,
            {index, issue}
        ) {
            let mediaProperty = state.editedListing.mediaProperties[index];
            let targetIssue = mediaProperty.issues.find(
                i => i.name === issue.name
            );
            if (targetIssue) targetIssue.dismissed = true;
            state.hasUnsavedChanges = true;
        },
    },
    actions: {
        async listings_clear_filter({commit}) {
            return new Promise((resolve, reject) => {
                commit("listings_clear_filter", {
                    ...defaultState,
                    filter: emptyFilter(),
                });
                let sortFilterParams = _.omit(defaultState, [
                    "filter",
                    "pageIndex",
                ]);
                return resolve({
                    ...sortFilterParams,
                    ...emptyFilter(),
                    page: defaultState.pageIndex + 1,
                    sortAsc: sortFilterParams.sortAsc,
                });
            });
        },
        async listing_fetch_logo_options({state, commit}, id) {
            let logoOptions = await api.listings.fetchLogoOptions(id);
            commit("logo_options_set", {id, logoOptions});
            return logoOptions;
        },
        async listings_fetch_nextPage({state, commit, dispatch}) {
            let pageIndex = state.pageIndex + 1;
            let data = await dispatch("listings_fetch", {pageIndex});
            commit("listings_set", {pageIndex});
            return data;
        },
        async listings_fetch_prevPage({state, commit, dispatch}) {
            let pageIndex = state.pageIndex - 1;
            let data = await dispatch("listings_fetch", {pageIndex});
            commit("listings_set", {pageIndex});
            return data;
        },
        async refresh_listing({commit, dispatch}, id) {
            let listing = await dispatch("fetch_listing", id);
            commit("update_listing", listing);

            return listing;
        },
        async fetch_listing({commit}, id) {
            let data = await api.listings.fetchByFilter({search: `l${id}`});
            let listing = data.results[0];

            return listing;
        },
        async listings_fetch({state, commit}, options = {}) {
            let filter = options.filter || state.filter;
            let pageIndex = options.pageIndex || state.pageIndex;
            let data = await api.listings.fetchByFilter(
                filter,
                pageIndex,
                state.pageSize,
                state.sortColumn,
                state.sortAsc
            );
            let listings = data.results;
            let startIndex = pageIndex * state.pageSize;
            commit("listings_set", {
                totalCount: data.stats.total,
                filteredTotalCount: data.stats.filterTotal,
                countsByStatus: data.stats.byStatus,
                countsByUser: data.stats.byAssignment,
                countsByWorkflowTag: data.stats.byWorkflowTag,
                countsByTaskType: data.stats.byTaskType,
                countsByIssueType: data.stats.byIssueType,
            });
            commit("listings_set_filter", state.filter);
            commit("listings_setPage", {startIndex, listings});
            return data;
        },
        async listings_edit({commit}, {listing}) {
            let updatedListing = await api.listings.edit(listing);
            commit("listings_setListing", updatedListing);
            return updatedListing;
        },
        async listings_approve({commit}, {listingId, statusReason}) {
            let updatedListing = await api.listings.approve(
                listingId,
                statusReason
            );
            commit("listings_setListing", updatedListing);
            return updatedListing;
        },
        async listings_reject({commit}, {listingId, statusReason}) {
            let updatedListing = await api.listings.reject(
                listingId,
                statusReason
            );
            commit("listings_setListing", updatedListing);
            return updatedListing;
        },
        async listings_assignedTo(
            {commit, dispatch},
            {listingIds, assignedTo}
        ) {
            let response = await api.listings.assignTo(listingIds, assignedTo);
            commit("listings_setListingAssignedTo", {listingIds, assignedTo});
            dispatch("listings_fetch");
            return response;
        },
        async listings_addRemoveTags(
            {dispatch, commit},
            {listingIds, addTags, removeTags, scheme}
        ) {
            let response = {};
            if (addTags && Array.isArray(addTags) && addTags.length)
                response = await api.listings.addTags(
                    listingIds,
                    addTags,
                    scheme
                );
            if (removeTags && Array.isArray(removeTags) && removeTags.length)
                response = await api.listings.removeTags(
                    listingIds,
                    removeTags,
                    scheme
                );

            if (response.ids && !response.ids.length) return response;

            commit("listings_set", {isBusy: true});
            dispatch("listings_fetch").then(() =>
                commit("listings_set", {isBusy: false})
            );
            return response;
        },
    },
};

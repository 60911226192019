<template>
    <LayoutSingleColumn
        max-width="1700px"
        :page-tabs="pageTabs"
        :selected-page-tab-key="selectedPageTabKey"
        @pageTabSelect="k => (selectedPageTabKey = k)"
    >
        <PageTitle>
            <span slot="title">
                {{ title }}
            </span>
        </PageTitle>
        <component :is="currentViewComponent" />
    </LayoutSingleColumn>
</template>

<script>
import Vue from "vue";
import {mapState} from "vuex";
import LayoutSingleColumn from "@/components/LayoutSingleColumn.vue";
import PageTitle from "@mediarails/vue-page-title";
import PanelsView from "./PanelManager/Panels/Panels.vue";
import RegionsView from "./PanelManager/Regions/Regions.vue";
import EnumPanelManagerPageTabs from "@/models/enum-panel-manager-page-tabs";
export default {
    name: "PanelManager",
    components: {LayoutSingleColumn, PageTitle, PanelsView, RegionsView},
    data: () => ({
        selectedPageTabKey: EnumPanelManagerPageTabs.panels,
    }),
    computed: mapState({
        pageTabs: s => s.panelManager.pageTabs,
        currentViewComponent() {
            return this.selectedPageTabKey === EnumPanelManagerPageTabs.regions
                ? "RegionsView"
                : "PanelsView";
        },
        title() {
            return this.selectedPageTabKey === "panels"
                ? this.$t("panelManager.panels.pageTitle")
                : this.$t("panelManager.regions.pageTitle");
        },
    }),
    watch: {},
    mounted() {
        this.fetchStaticInfo();
    },
    methods: {
        fetchStaticInfo() {
            this.$store.dispatch("static_fetch_info").catch(error => {
                Vue.rollbar.error(error);
                this.$notify({
                    type: "error",
                    text: error || this.$i18n.t("errors.fetch"),
                });
            });
        },
    },
};
</script>

<template>
    <div class="flex">
        <div class="flex-1">
            <ListFilters />
        </div>
        <div style="padding-top:5px;">
            <Button
                text
                class="outline-none"
                :disabled="clearFilterDisable"
                @click="clearFilter"
            >
                {{ $t("listings.actions.clearFilter") }}
            </Button>
            <Button
                primary
                small
                :disabled="openReviewModalDisabled"
                @click="openReviewModal"
            >
                {{ $t("listings.actions.buttonReview") }}
            </Button>
            <ReviewModal ref="ReviewModal" @close="reviewModalClosed" />
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Button from "@mediarails/vue-button";
import {omit, pickBy, findIndex, keys} from "lodash";
import ListFilters from "./ListFilters.vue";
import ReviewModal from "./ReviewModal/ReviewModal.vue";
export default {
    name: "ListingsActions",
    components: {Button, ListFilters, ReviewModal},
    computed: mapState({
        listings: s => s.listings.listings,
        totalCount: s => s.listings.totalCount,
        filteredTotalCount: s => s.listings.filteredTotalCount,
        pageIndex: s => s.listings.pageIndex,
        pageSize: s => s.listings.pageSize,
        pageStartIndex() {
            return this.pageIndex * this.pageSize;
        },
        userCanEnhance() {
            return this.$store.getters.userHasPermission("canEnhance");
        },
        userCanReview() {
            return this.$store.getters.userHasPermission("canReview");
        },
        openReviewModalDisabled() {
            let userCantReviewOrEnhance =
                !this.userCanEnhance && !this.userCanReview;
            return !this.filteredTotalCount || userCantReviewOrEnhance;
        },
        clearFilterDisable(state) {
            let {sortAsc, sortColumn, filter, pageIndex} = state.listings;
            let listingFilter = pickBy(
                filter,
                (value, key) => ![undefined, null].includes(value)
            );
            return (
                pageIndex === 0 &&
                !sortAsc &&
                !keys(listingFilter).length &&
                sortColumn === "createdAt"
            );
        },
    }),
    watch: {
        "$route.query.reviewMode"() {
            if (String(this.$route.query.reviewMode) === "true") {
                this.$refs.ReviewModal.$show();
            } else {
                this.$refs.ReviewModal.$hide();
            }
        },
        "$route.query.id"() {
            this.openReviewModalFromQueryParam();
        },
        listings() {
            this.openReviewModalFromQueryParam();
        },
    },
    methods: {
        clearFilter() {
            this.$store.dispatch("listings_clear_filter").then(filter => {
                this.setRouteQueryParams(filter);
            });
        },
        reviewModalClosed() {
            this.setRouteQueryParams({id: null, reviewMode: null});
        },
        openReviewModal() {
            this.setRouteQueryParams({reviewMode: true});
            this.$store.commit("listings_set", {
                selectedListingIndex: this.pageStartIndex,
            });
            this.$refs.ReviewModal.$show();
        },
        $openReviewByIndex(selectedListingIndex) {
            const listingId = this.listings[selectedListingIndex].id;
            this.setRouteQueryParams({id: listingId, reviewMode: true});
            this.$store.commit("listings_set", {selectedListingIndex});
            this.$refs.ReviewModal.$show();
        },
        openReviewModalFromQueryParam() {
            let {reviewMode, id} = this.$route.query;
            let reviewModeOn = String(reviewMode) === "true";
            let selectedListingIndex = findIndex(this.listings, {
                id: parseInt(id),
            });

            if (reviewModeOn && selectedListingIndex !== -1) {
                this.$store.commit("listings_set", {selectedListingIndex});
                this.$refs.ReviewModal.$show();
            } else if (reviewModeOn) {
                this.$store.commit("listings_set", {
                    selectedListingIndex: this.pageStartIndex,
                });
                this.$refs.ReviewModal.$show();
            }
        },
        setRouteQueryParams(params) {
            let query = pickBy(
                {...this.$route.query, ...params},
                (value, key) => ![undefined, null].includes(value)
            );

            this.$router.push({name: "listings", query});
        },
    },
};
</script>

export default [
    {
        label: "Profile",
        key: "/user",
    },
    {
        label: "Sign out",
        key: "signout",
    },
];

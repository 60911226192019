var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleDialog',{ref:"dialog",attrs:{"busy":false,"title":_vm.$i18n.t('listings.editorialAddTagModal.title'),"description":_vm.$i18n.t('listings.editorialAddTagModal.description'),"action-label":_vm.$i18n.t('listings.editorialAddTagModal.action.addEtag'),"action-enabled":!!_vm.selectedTag || !!_vm.customTag,"action-busy":_vm.isBusy},on:{"action":_vm.saveChanges,"hide":_vm.$hide}},[_c('div',{staticClass:"flex",staticStyle:{"width":"500px"}},[_c('div',{staticClass:"border-r pr-7 pb-2",staticStyle:{"width":"50%"}},[_c('div',{staticClass:"flex"},[_c('MultiselectInput',{staticClass:"flex-1",attrs:{"label":_vm.$i18n.t(
                            'listings.editorialAddTagModal.form.tagSelect'
                        ),"options":_vm.tagOptions,"max":1,"value":_vm.selectedTag ? _vm.selectedTag.value : null,"placeholder":_vm.$i18n.t(
                            'listings.editorialAddTagModal.form.selectPlaceholder'
                        ),"disabled":!!_vm.selectedTag || !!_vm.customTag},on:{"change":_vm.tagSelected}})],1),_c('div',{staticClass:"flex mt-5"},[_c('TextInput',{ref:"customTag",staticClass:"flex-1",attrs:{"large":"","label":_vm.$i18n.t(
                            'listings.editorialAddTagModal.form.tagCreate'
                        ),"placeholder":_vm.$i18n.t(
                            'listings.editorialAddTagModal.form.createPlaceholder'
                        ),"pattern":"^[a-zA-Z0-9].*","disabled":!!_vm.customTag || !!_vm.selectedTag},on:{"validity":_vm.checkValid}}),_c('Button',{staticClass:"mt-5 ml-1",attrs:{"disabled":!_vm.customTagValid || !!_vm.tagAdded},on:{"click":_vm.addCustomTag}},[_vm._v(" "+_vm._s(_vm.$t("listings.editorialAddTagModal.customTagAddBtn"))+" ")])],1)]),_c('div',{staticStyle:{"width":"20px","position":"relative"}},[_c('Icon',{staticClass:"absolute",staticStyle:{"top":"50%","left":"0","transform":"translateY(-50%)","color":"lightGrey"},attrs:{"name":"caretRight","size":"lg"}})],1),_c('div',{staticClass:"pl-3 flex-1"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("listings.editorialAddTagModal.currentGroupValues"))+" ")]),(_vm.tagAdded)?_c('Tag',{staticClass:"mt-3",attrs:{"large":""}},[_vm._v(" "+_vm._s(_vm.tagAdded.label)+" "),_c('Icon',{staticClass:"ml-1",attrs:{"name":"times","size":"sm","clickable":"","danger":""},on:{"click":_vm.removeTag}})],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }
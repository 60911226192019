<template>
    <div>
        <ConfirmModal
            ref="refreshWarningDialog"
            :confirm-button-label="
                $t('listings.reviewModal.refreshModal.actionLabel')
            "
            :title="
                $t('listings.reviewModal.refreshModal.title', {
                    name: currentListing.name,
                })
            "
            :description="
                $tc(
                    'listings.reviewModal.refreshModal.description',
                    totalChange,
                    {
                        totalChange,
                    }
                )
            "
            :show="showRefreshConfirmModal"
            @confirm="fetchCurrentListing"
            @close="() => (showRefreshConfirmModal = false)"
        />
        <div class="bg-dark rounded-full">
            <div
                class="bg-secondary rounded-full"
                :style="`height:2px; width:${progressPercent}%;`"
            />
        </div>
        <div class="flex mt-4">
            <div class="text-left">
                <Button
                    small
                    :disabled="isAtFirstListing"
                    @click="moveToPrevListing"
                >
                    <Icon name="chevronLeft" size="sm" />
                    {{ $t("listings.reviewModal.buttonPrevious") }}
                </Button>
            </div>
            <div class="text-center flex-1">
                <ModeToggle
                    :value="mode"
                    :options="modeOptions"
                    @change="changeMode"
                />
                <Button
                    text
                    :title="$t('listings.reviewModal.buttonRefreshTitle')"
                    class="ml-3 mb-1 outline-none"
                    @click="refreshCurrentListing"
                >
                    <Icon name="sync" size="lg" />
                </Button>
            </div>
            <div class="text-right">
                <Button
                    small
                    :disabled="isAtLastListing"
                    @click="moveToNextListing"
                >
                    {{ $t("listings.reviewModal.buttonNext") }}
                    <Icon name="chevronRight" size="sm" />
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import Vue from "vue";
import {keys} from "lodash";
import ConfirmModal from "@mediarails/vue-modal-confirm-delete";
import Button from "@mediarails/vue-button";
import Icon from "@mediarails/vue-fa-icon";
import ModeToggle from "@/components/ModeToggle.vue";
export default {
    name: "ListingsReviewModalSidebarHeader",
    components: {Button, Icon, ModeToggle, ConfirmModal},
    data: () => ({
        showRefreshConfirmModal: false,
        userCanEnhance: false,
        userCanReview: false,
    }),
    computed: mapState({
        mode: s => s.reviewModal.mode,
        userRoles() {
            return this.$store.getters.sessionUserRoles;
        },
        modeOptions() {
            if (this.userCanEnhance && this.userCanReview) {
                return [
                    {label: "Enhance", value: "enhance"},
                    {label: "Review", value: "review"},
                ];
            } else {
                return [];
            }
        },
        selectedListingIndex: s => s.listings.selectedListingIndex,
        listings: s => s.listings.listings,
        hasUnsavedChanges: state => state.listings.hasUnsavedChanges,
        totalChange() {
            let editedListing = this.$store.state.listings.editedListing;

            let changes = keys(editedListing).reduce((changes, key) => {
                let listingValue = JSON.stringify(this.currentListing[key]);
                let editedValue = JSON.stringify(editedListing[key]);

                if (editedValue !== listingValue) {
                    changes = {...changes, [key]: editedListing[key]};
                }
                return changes;
            }, {});

            return keys(changes).length;
        },
        currentListing: state => {
            const {listings, selectedListingIndex} = state.listings;

            return listings[selectedListingIndex] || {};
        },
        totalCount: s => s.listings.totalCount,
        filteredTotalCount: s => s.listings.filteredTotalCount,
        isAtFirstListing() {
            return this.selectedListingIndex === 0;
        },
        isAtLastListing() {
            return this.selectedListingIndex === this.filteredTotalCount - 1;
        },
        progressPercent() {
            return (
                ((this.selectedListingIndex + 1) / this.filteredTotalCount) *
                100
            );
        },
    }),
    watch: {
        userRoles: {
            immediate: true,
            handler: function(userRoles) {
                this.userCanEnhance = this.$store.getters.userHasPermission(
                    "canEnhance"
                );
                this.userCanReview = this.$store.getters.userHasPermission(
                    "canReview"
                );
            },
        },
    },
    methods: {
        refreshCurrentListing() {
            if (!this.hasUnsavedChanges) {
                return this.fetchCurrentListing();
            }
            this.showRefreshConfirmModal = true;
        },
        fetchCurrentListing() {
            this.showRefreshConfirmModal = false;
            this.$store.commit("reviewModal_busyStart");

            this.$store
                .dispatch("refresh_listing", this.currentListing.id)
                .finally(() => {
                    this.$store.commit("reviewModal_busyStop");
                })
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.fetch"),
                    });
                });
        },
        moveToPrevListing() {
            let prevIndex = Math.max(0, this.selectedListingIndex - 1);
            let indexExists = Boolean(this.listings[prevIndex]);
            if (indexExists) {
                this.goToListingIndex(prevIndex);
                this.changeRoute(this.listings[prevIndex].id);
            } else {
                this.$store.commit("reviewModal_busyStart");
                this.$store
                    .dispatch("listings_fetch_prevPage")
                    .then(() => {
                        this.changeRoute(this.listings[prevIndex].id);
                        this.goToListingIndex(prevIndex);
                        this.$store.commit("reviewModal_busyStop");
                    })
                    .catch(error => {
                        this.$store.commit("reviewModal_busyStop");
                        Vue.rollbar.error(error);
                        this.$notify({
                            type: "error",
                            text: error || this.$i18n.t("errors.fetch"),
                        });
                    });
            }
        },
        moveToNextListing() {
            let lastIndex = this.totalCount - 1;
            let nextIndex = Math.min(this.selectedListingIndex + 1, lastIndex);
            let indexExists = Boolean(this.listings[nextIndex]);
            if (indexExists) {
                this.goToListingIndex(nextIndex);
                this.changeRoute(this.listings[nextIndex].id);
            } else {
                this.$store.commit("reviewModal_busyStart");
                this.$store
                    .dispatch("listings_fetch_nextPage")
                    .then(() => {
                        this.changeRoute(this.listings[nextIndex].id);
                        this.goToListingIndex(nextIndex);
                        this.$store.commit("reviewModal_busyStop");
                    })
                    .catch(error => {
                        this.$store.commit("reviewModal_busyStop");
                        Vue.rollbar.error(error);
                        this.$notify({
                            type: "error",
                            text: error || this.$i18n.t("errors.fetch"),
                        });
                    });
            }
        },
        goToListingIndex(index) {
            this.$store.commit("listings_set", {
                selectedListingIndex: index,
            });
        },
        changeMode(mode) {
            this.$store.commit("reviewModal_setMode", mode);
        },
        changeRoute(id) {
            return this.$router.push({
                name: "listings",
                query: {
                    ...this.$route.query,
                    id,
                },
            });
        },
    },
};
</script>

import { render, staticRenderFns } from "./LayoutSingleColumn.vue?vue&type=template&id=31146792&scoped=true&"
import script from "./LayoutSingleColumn.vue?vue&type=script&lang=js&"
export * from "./LayoutSingleColumn.vue?vue&type=script&lang=js&"
import style0 from "./LayoutSingleColumn.vue?vue&type=style&index=0&id=31146792&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31146792",
  null
  
)

export default component.exports
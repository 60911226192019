<template>
    <div class="text-left">
        <PrimaryNav
            ref="primaryNav"
            :app-name="$t('primaryNav.appName')"
            :account-name="profile.name"
            :user-name="profile.name"
            :user-company="userRoleLabels"
            :user-email="profile.email"
            :user-avatar-href="profile.picture"
            :menu-items="menuItems"
            :user-menu-items="userMenuItems"
            @itemClick="handleItemClick"
        />
    </div>
</template>

<script>
import {mapState} from "vuex";
import PrimaryNav from "@mediarails/vue-impact-primary-nav";
import AppNavMenuItems from "./AppNavMenuItems";
import AppNavUserMenuItems from "./AppNavUserMenuItems";
export default {
    name: "AppNav",
    components: {PrimaryNav},
    props: {},
    data: () => ({
        menuItems: AppNavMenuItems,
        userMenuItems: AppNavUserMenuItems,
    }),
    computed: mapState({
        profile: s => s.session.userProfile,
        userRoleLabels() {
            return this.$store.getters.sessionUserRoles
                .map(r => r.label)
                .join(", ");
        },
    }),
    mounted() {
        this.$refs.primaryNav.$selectItem("/listings");
    },
    methods: {
        handleItemClick({key}) {
            if (key === "signout") {
                this.$emit("logout");
            } else {
                if (key && this.$route.path !== key)
                    this.$router.push({path: key});
                this.$refs.primaryNav.$selectItem(key);
            }
        },
    },
};
</script>

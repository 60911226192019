var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SimpleDialog',{ref:"logo-options",staticClass:"overflow-hidden",attrs:{"busy":_vm.busy,"title":_vm.$t('listings.reviewModal.logoOptionsModalTitle', {
            count: _vm.logoOptions.length || '',
        }),"width":"800px"}},[_c('div',{class:"grid grid-cols-2 gap-4 overflow-auto max-h-30 min-h-20"},_vm._l((_vm.logoOptions),function(logoOption){return _c('PictureCard',_vm._b({key:((logoOption.description) + "-" + (logoOption.listingId)),attrs:{"image-url":logoOption.url,"title":"","sub-title":logoOption.description,"fallback-text":_vm.$t('listings.reviewModal.logoOptionFallbackMsg', {
                    description: logoOption.description,
                }),"image-container-height":"300px","image-width":"80%"},on:{"click":function () { return _vm.cardClick(logoOption); }}},'PictureCard',
                _vm.listing.logoUrl === logoOption.url
                    ? {iconName: 'check', iconColor: 'success'}
                    : {}
            ,false))}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }
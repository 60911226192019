var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inline-block rounded-lg"},_vm._l((_vm.options),function(o,i){return _c('div',{key:o.value,staticClass:"px-4 py-1 inline-block cursor-pointer border-grey-light",class:{
            'rounded-l-lg': i == 0,
            'rounded-r-lg': i == _vm.options.length - 1,
            'border-l-0': i > 0,
            'bg-secondary': o.value == _vm.value,
            'text-white': o.value == _vm.value,
            'shadow-inner': o.value == _vm.value,
            'border-transparent': o.value == _vm.value,
            'bg-white': o.value != _vm.value,
            shadow: o.value != _vm.value,
            border: o.value != _vm.value,
            'hover:bg-light': o.value != _vm.value,
        },on:{"click":function($event){return _vm.$emit('change', o.value)}}},[_vm._v(" "+_vm._s(o.label)+" ")])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <Modal
        :busy="isBusy"
        :show="showModal"
        :close-on-mask-click="false"
        :close-on-esc="false"
        padding
        @close="$hide"
    >
        <div class="mx-6">
            <div class="text-left text-2xl">
                {{ title }}
            </div>
            <div class="flex mt-5">
                <div class="" style="width:400px;">
                    <div class="truncate">
                        <input
                            ref="logoFileInput"
                            type="file"
                            :disabled="!userCanEdit"
                            @change="onFileChange"
                        />
                    </div>
                    <div
                        v-if="!userPickedAnImageFile && panelData.logoUrl"
                        class="break-words text-xs mt-3"
                    >
                        {{ panelData.logoUrl }}
                    </div>
                    <FormBuilderManager
                        class="mt-5"
                        :field-meta="editFormMeta"
                        :field-values="panelData"
                        @change="onFormValueChange"
                        @validity="onFormValidityChange"
                    />
                </div>
                <div class="pl-6" style="width:40vw; max-width:900px;">
                    <div class="text-sm mb-1">
                        {{ $t("panelManager.panelEditForm.panelPreview") }}
                    </div>
                    <PanelPreview
                        :headline="editedPanelData.headline"
                        :title="editedPanelData.title"
                        :description="editedPanelData.description"
                        :logo-url="logoPreviewUrl"
                    />
                </div>
            </div>
            <div v-if="panelIsInUse" class="mt-2 text-danger text-sm">
                {{ $t("panelManager.panelEditForm.inUseWarning") }}
            </div>
            <div class="mt-7 text-right">
                <Button @click="$hide">Cancel</Button>
                <Button
                    primary
                    :disabled="!formIsValid || !logoPreviewUrl || !userCanEdit"
                    @click="isEdit ? onEditSave() : onCreateSave()"
                >
                    {{ actionLabel }}
                </Button>
            </div>
        </div>
    </Modal>
</template>

<script>
import _ from "lodash";
import Vue from "vue";
import {mapState} from "vuex";
import Modal from "@mediarails/vue-modal";
import Button from "@mediarails/vue-button";
import PanelPreview from "@/components/PanelPreview.vue";
import filterConfig from "@/../panel.filters.config";
import {FormBuilderManager} from "@mediarails/vue-form-builder";
export default {
    name: "PanelCreateEditModal",
    components: {
        Modal,
        Button,
        PanelPreview,
        FormBuilderManager,
    },
    data: () => ({
        showModal: false,
        isBusy: false,
        panelData: {},
        editedPanelData: {},
        logoPreviewUrl: null,
        formIsValid: true,
        isEdit: false,
        userPickedAnImageFile: false,
    }),
    computed: mapState({
        userCanEdit() {
            return this.$store.getters.userHasPermission("canEditPanels");
        },
        title() {
            return this.isEdit
                ? this.$t("panelManager.panels.createEditModal.editTitle")
                : this.$t("panelManager.panels.createEditModal.createTitle");
        },
        actionLabel() {
            return this.isEdit
                ? this.$t("panelManager.panels.createEditModal.editAction")
                : this.$t("panelManager.panels.createEditModal.createAction");
        },
        editorialTagOptions() {
            return filterConfig
                .find(x => x.id === "editorialTag")
                .getOptions(this.$store.state.static);
        },
        editFormMeta() {
            return [
                {
                    width: "100%",
                    columns: "1fr 1fr",
                    fields: [
                        {
                            name: "title",
                            type: "text",
                            settings: {
                                label: this.$t(
                                    "panelManager.panelEditForm.labels.title"
                                ),
                                required: true,
                                readonly: !this.userCanEdit,
                            },
                        },
                        {
                            name: "headline",
                            type: "text",
                            settings: {
                                label: this.$t(
                                    "panelManager.panelEditForm.labels.headline"
                                ),
                                required: true,
                                readonly: !this.userCanEdit,
                            },
                        },
                        {
                            name: "description",
                            type: "text",
                            columnSpan: 2,
                            settings: {
                                label: this.$t(
                                    "panelManager.panelEditForm.labels.description"
                                ),
                                required: true,
                                readonly: !this.userCanEdit,
                            },
                        },
                        {
                            name: "searchTerm",
                            type: "select",
                            columnSpan: 2,
                            settings: {
                                label: this.$t(
                                    "panelManager.panelEditForm.labels.searchTerm"
                                ),
                                required: true,
                                options: this.editorialTagOptions,
                                disabled: !this.userCanEdit,
                            },
                        },
                        {
                            name: "name",
                            type: "text",
                            columnSpan: 2,
                            settings: {
                                label: this.$t(
                                    "panelManager.panelEditForm.labels.name"
                                ),
                                required: true,
                                readonly: !this.userCanEdit,
                            },
                        },
                    ],
                },
            ];
        },
        panelIsInUse() {
            return this.panelData.usageAsPublished > 0;
        },
    }),
    watch: {},
    mounted() {},
    methods: {
        $show(panelData) {
            this.$refs.logoFileInput.value = "";
            this.userPickedAnImageFile = false;
            this.panelData = _.cloneDeep(panelData) || {};
            this.editedPanelData = _.cloneDeep(panelData) || {};
            this.logoPreviewUrl = panelData ? panelData.logoUrl : null;
            this.isEdit = panelData ? true : false;
            this.showModal = true;
        },
        $hide() {
            this.showModal = false;
            this.panelData = {};
            this.editedPanelData = {};
        },
        onFormValueChange(values) {
            this.editedPanelData = values;
        },
        onFormValidityChange(isValid) {
            this.formIsValid = isValid;
        },
        onFileChange(e) {
            const input = e.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = e => {
                    this.logoPreviewUrl = e.target.result;
                };
                reader.readAsDataURL(input.files[0]);
                this.userPickedAnImageFile = true;
            }
        },
        onCreateSave() {
            const panelData = Object.assign({}, this.editedPanelData, {
                logoFile: this.$refs.logoFileInput.files[0],
            });
            this.isBusy = true;
            this.$store
                .dispatch("panels_createPanel", panelData)
                .then(createdPanel => {
                    this.$hide();
                    this.$emit("finished", createdPanel);
                })
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.save"),
                    });
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        onEditSave() {
            const panelData = Object.assign({}, this.editedPanelData, {
                logoFile: this.$refs.logoFileInput.files[0],
            });
            this.isBusy = true;
            this.$store
                .dispatch("panels_editPanel", panelData)
                .then(editedPanel => {
                    this.$hide();
                    this.$emit("finished", editedPanel);
                })
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.save"),
                    });
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
    },
};
</script>

import Ajax from "@mediarails/util-ajax";
import _ from "lodash";
import apiEnv from "./environment";

const errorPrefix = "API:panels";

export default apiUtil => {
    return {
        async fetchByFilter(filter, page = 0, size = 50, sortColumn, sortAsc) {
            try {
                let payload = {size, page};
                _.forOwn(filter, (val, key) => {
                    if (val) payload[key] = val;
                });

                if (sortColumn) payload.sortBy = sortColumn;
                if (sortColumn === "usageAsDraft")
                    payload.sortBy = "usageAsDrafts";
                if (sortAsc != null)
                    payload.sortDirection = sortAsc ? "asc" : "desc";

                let response = await Ajax.get(
                    apiEnv.urls.panels,
                    payload,
                    null,
                    true,
                    true,
                    await apiUtil.getAjaxConfig()
                );
                response = Object.assign(
                    {
                        total: 0,
                        filteredTotal: 0,
                        panels: [],
                    },
                    response
                );
                response.panels = response.panels.map(p =>
                    formatPanelDataFromServer(p)
                );
                return response;
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:fetchByFilter()`);
            }
        },

        async edit(panelValues) {
            /*
                {
                    name: "",
                    title: "",
                    logoFile: file,
                    headline: "",
                    searchTerm: "1232",
                    description: "",
                }
            */
            let payload = new FormData();
            if (panelValues.logoFile)
                payload.append("file", panelValues.logoFile);
            payload.append("id", panelValues.id);
            payload.append("name", panelValues.name);
            payload.append(
                "content",
                JSON.stringify({
                    title: panelValues.title,
                    headLine: panelValues.headline,
                    searchTerm: `etags%3A${panelValues.searchTerm}`,
                    description: panelValues.description,
                    logoUrl: panelValues.logoFile ? "" : panelValues.logoUrl,
                })
            );

            let apiConfig = await apiUtil.getAjaxConfig();
            apiConfig.headers["Content-Type"] = "multipart/form-data";

            try {
                let response = await Ajax.post(
                    apiEnv.urls.panels,
                    payload,
                    null,
                    true,
                    true,
                    apiConfig
                );
                return formatPanelDataFromServer((response || {}).jsonPanel);
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:edit()`);
            }
        },

        async create(panelValues) {
            /*
                {
                    name: "",
                    title: "",
                    logoFile: file,
                    headline: "",
                    searchTerm: "1232",
                    description: "",
                }
            */
            let payload = new FormData();
            payload.append("file", panelValues.logoFile);
            payload.append("name", panelValues.name);
            payload.append(
                "content",
                JSON.stringify({
                    title: panelValues.title,
                    headLine: panelValues.headline,
                    searchTerm: `etags%3A${panelValues.searchTerm}`,
                    description: panelValues.description,
                    logoUrl: "",
                })
            );

            let apiConfig = await apiUtil.getAjaxConfig();
            apiConfig.headers["Content-Type"] = "multipart/form-data";

            try {
                let response = await Ajax.post(
                    apiEnv.urls.panels,
                    payload,
                    null,
                    true,
                    true,
                    apiConfig
                );
                return formatPanelDataFromServer((response || {}).jsonPanel);
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:create()`);
            }
        },

        async delete(panelIds = []) {
            try {
                let response = await Ajax.delete(
                    `${apiEnv.urls.panels}?ids=${panelIds.join(",")}`,
                    null,
                    false,
                    false,
                    await apiUtil.getAjaxConfig()
                );
                return (response || {}).updated;
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:edit()`);
            }
        },
    };
};

function formatPanelDataFromServer(panel) {
    /*
    // Incoming Panel Format
    {
        "id": 1,
        "name": "Valenties Days - English",
        "usage": {
            "published": 3,
            "scheduled": 1,
            "drafts": 0
        },
        "content": {
            "title": "OUR FAVORITES",
            "logoUrl": "https://storage.googleapis.com/marketplace-web/homepage/valentines-2020.jpg",
            "headLine": "Valentines Day",
            "searchTerm": "tags%3Acoupleaccount",
            "description": "Fall head over heels for our influencer couples."
          }
        }
    */
    return {
        id: panel.id,
        name: panel.name,
        usageAsPublished: panel.published,
        usageAsScheduled: panel.scheduled,
        usageAsDraft: panel.drafts,
        title: panel.content.title,
        logoUrl: panel.content.logoUrl,
        headline: panel.content.headLine,
        searchTerm: (panel.content.searchTerm || "").replace(/^.*%3A/, ""),
        description: panel.content.description,
        createdAt: panel.createdAt,
        updatedAt: panel.updatedAt,
    };
}

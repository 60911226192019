<template>
    <div>
        <Spinner v-if="tableBusy" top-distance="150px" />
        <AssignToModal ref="assignToModal" />
        <WorkflowTagsModal ref="workflowTagsModal" />
        <ModalAddEtag ref="modalAddEtag" />
        <ModalRemoveEtags ref="modalRemoveEtags" />
        <ServerTable
            ref="table"
            compact
            :data="currentPageTableData"
            :empty-text="$i18n.t('listings.list.listEmptyText')"
            :sort-column-name="sortColumnName"
            :sort-column-asc="sortColumnAsc"
            :action-options="tableActionOptions"
            :total-count="filteredTotalCount"
            :visible-columns="visibleColumns"
            :page-index="pageIndex"
            :page-size="pageSize"
            can-select-rows
            show-column-picker
            @rowAction="handleRowActionSelect"
            @sort="handleSort"
            @pageChange="goToPageIndex"
            @column-picker-change="columnPickerChange"
            @action-clicked="resetToDefault"
        >
            <TableColumn
                name="logoUrl"
                :label="$i18n.t('listings.list.columnLabelLogoUrl')"
                :sortable="false"
                width="80px"
                hideable
                has-slot
            />
            <template slot="logoUrl" slot-scope="props">
                <img :src="props.data.logoUrl" />
            </template>
            <TableColumn name="name" has-slot />
            <template slot="name" slot-scope="props">
                <span
                    class="text-primary cursor-pointer hover:text-primary-darker"
                    :class="{
                        'text-danger':
                            props.data.status == enumListingStatus.rejected ||
                            props.data.status == enumListingStatus.flagged,
                    }"
                    @click="openListingReviewById(props.data.id)"
                >
                    {{ props.data.name }}
                </span>
                <Icon
                    v-if="props.data.isNotSpam === false"
                    name="userSecretSolid"
                    class="text-danger ml-1"
                />
            </template>
            <TableColumn
                name="locationCountry"
                :label="$i18n.t('listings.list.columnLabelLocationCountry')"
                :sortable="false"
                hideable
            />
            <TableColumn
                name="radiusPublisherId"
                align="right"
                no-wrap
                hideable
                :sortable="false"
                :label="$i18n.t('listings.list.columnLabelRadiusPublisherId')"
            />
            <TableColumn
                name="id"
                align="right"
                no-wrap
                hideable
                :sortable="true"
                :label="$i18n.t('listings.list.columnLabelListingId')"
            />
            <TableColumn name="status" has-slot hideable />
            <template slot="status" slot-scope="props">
                <span
                    @click="openListingReviewById(props.data.id)"
                    :class="{
                        'text-success':
                            props.data.status == enumListingStatus.approved,
                        'text-danger':
                            props.data.status == enumListingStatus.rejected ||
                            props.data.status == enumListingStatus.flagged,
                    }"
                >
                    <span class="capitalize">
                        {{ props.data.status }}
                    </span>
                    <span
                        v-if="
                            props.data.statusReasonLabel &&
                                (props.data.status == 'flagged' ||
                                    props.data.status == 'rejected')
                        "
                    >
                        ({{ props.data.statusReasonLabel }})
                    </span>
                </span>
            </template>
            <TableColumn
                name="createdAt"
                :label="$i18n.t('listings.list.columnLabelCreated')"
                format="date"
                hideable
            />
            <TableColumn
                name="revenue180Days"
                :label="$i18n.t('listings.list.columnLabelRevenue')"
                format="currencyInt"
                align="right"
                hideable
            />
            <TableColumn
                name="reachRating"
                :label="$i18n.t('listings.list.columnLabelReachRating')"
                align="right"
                hideable
            />
            <TableColumn
                no-wrap
                name="assignedToName"
                :label="$i18n.t('listings.list.columnLabelAssignedTo')"
                hideable
                :sortable="false"
            />
            <TableColumn
                name="partnerStatus"
                :label="$i18n.t('listings.list.columnLabelPartnerStatus')"
                :sortable="false"
                hideable
            />
            <TableColumn
                name="language"
                :label="$i18n.t('listings.list.columnLabelLanguage')"
                :sortable="false"
                hideable
            />
            <TableColumn
                name="notes"
                :label="$i18n.t('listings.list.columnLabelNotes')"
                :sortable="false"
                hideable
            />
            <TableColumn
                name="taskTypes"
                has-slot
                hideable
                :sortable="false"
                :label="$i18n.t('listings.list.columnLabelTasks')"
            />
            <template slot="taskTypes" slot-scope="props">
                <Tag v-for="task in props.data.taskTypes || []" :key="task">
                    {{ task }}
                </Tag>
            </template>
            <TableColumn name="issues" has-slot :sortable="false" hideable />
            <template slot="issues" slot-scope="props">
                <div v-if="props.data.issues.length" class="flex text-danger">
                    <div class="self-center pr-2">
                        <Icon name="exclamationTriangle" size="xs" />
                    </div>
                    <div class="text-xs self-center">
                        <div
                            v-for="i in props.data.issues"
                            :key="i"
                            class="whitespace-no-wrap"
                        >
                            {{ i }}
                        </div>
                    </div>
                </div>
            </template>
        </ServerTable>
    </div>
</template>

<script>
import _ from "lodash";
import {mapState} from "vuex";
import {ServerTable, TableColumn} from "@mediarails/vue-table";
import enumListingStatus from "@/models/enum-listing-status";
import Spinner from "@mediarails/vue-spinner";
import AssignToModal from "./ModalAssignTo.vue";
import WorkflowTagsModal from "./ModalWorkflowTags.vue";
import ModalAddEtag from "./ModalAddEtag.vue";
import ModalRemoveEtags from "./ModalRemoveEtags.vue";
import Icon from "@mediarails/vue-fa-icon";
import Tag from "@mediarails/vue-tag";
import LocalStorageService from "@/util/local-storage-service";

const defaultVisibleColumns = [
    "revenue180Days",
    "radiusPublisherId",
    "status",
    "createdAt",
    "reachRating",
    "assignedToName",
    "taskTypes",
    "issues",
];

export default {
    name: "ListingsList",
    components: {
        ServerTable,
        TableColumn,
        Spinner,
        AssignToModal,
        Icon,
        WorkflowTagsModal,
        ModalAddEtag,
        ModalRemoveEtags,
        Tag,
    },
    data: () => ({
        enumListingStatus: enumListingStatus,
        visibleColumns: [],
    }),
    computed: mapState({
        currentUserEmail: s => s.session.userProfile.email,
        tableBusy: s => s.listings.isBusy,
        tableData: s => s.listings.listings,
        pageIndex: s => s.listings.pageIndex,
        pageSize: s => s.listings.pageSize,
        sortColumnName: s => s.listings.sortColumn,
        sortColumnAsc: s => s.listings.sortAsc,
        totalCount: s => s.listings.totalCount,
        filteredTotalCount: s => s.listings.filteredTotalCount,
        users: s => s.static.users,
        pageStartIndex() {
            return this.pageIndex * this.pageSize;
        },
        pageEndIndex() {
            return Math.min(
                this.filteredTotalCount,
                this.pageStartIndex + this.pageSize
            );
        },
        currentPageTableData() {
            let currentRows = this.tableData.slice(
                this.pageStartIndex,
                this.pageEndIndex
            );
            currentRows = currentRows.map(dataRow => {
                // assignedToName
                let assignedToEmail = dataRow.assignedTo;
                dataRow.assignedToName = this.userNamesByEmail[assignedToEmail];

                // statusReasonLabel
                let statusReasonLabel = dataRow.statusReason;
                const maxReasonDisplayChars = 20;
                if (
                    statusReasonLabel &&
                    statusReasonLabel.length > maxReasonDisplayChars
                ) {
                    statusReasonLabel =
                        statusReasonLabel.substring(0, maxReasonDisplayChars) +
                        "...";
                }
                dataRow.statusReasonLabel = statusReasonLabel;

                return dataRow;
            });
            return currentRows;
        },
        tableActionOptions() {
            return [
                {label: "Assign to", value: "assignTo"},
                {label: "Change group", value: "workflowTags"},
                {label: "Add etag", value: "addEtag"},
                {label: "Remove etags", value: "removeEtags"},
            ];
        },
        userNamesByEmail() {
            let namesByEmail = _.chain(this.users)
                .keyBy("value")
                .mapValues("label")
                .value();
            return namesByEmail;
        },
        prospectVisibleColumnsPref() {
            return LocalStorageService.getUserPreference({
                storageKey: this.currentUserEmail,
                preferenceKey: "prospectColumns",
            });
        },
    }),
    watch: {
        tableData: {
            deep: true,
            handler: function() {
                if (this.$refs.table) this.$refs.table.$deselectAllRows();
            },
        },
    },
    mounted() {
        this.visibleColumns =
            this.prospectVisibleColumnsPref || defaultVisibleColumns;
    },
    methods: {
        deselectTableRows() {
            this.$refs.table.$deselectAllRows();
        },
        resetToDefault() {
            this.visibleColumns = defaultVisibleColumns;
            LocalStorageService.setUserPreference({
                storageKey: this.currentUserEmail,
                preferenceKey: "prospectColumns",
                value: defaultVisibleColumns,
            });
        },
        columnPickerChange(columns) {
            this.visibleColumns = columns;
            LocalStorageService.setUserPreference({
                storageKey: this.currentUserEmail,
                preferenceKey: "prospectColumns",
                value: columns,
            });
        },
        goToPageIndex(pageIndex) {
            this.setRouteQueryParams({page: pageIndex + 1});
        },
        openListingReviewById(itemId) {
            let index = _.findIndex(this.tableData, x => x && x.id == itemId);
            this.$emit("openListingReview", index);
        },
        handleRowActionSelect({action, rows}) {
            let clearSelection = () => {
                this.$refs.table.$deselectAllRows();
            };

            const rowIds = _.keys(rows).map(x => parseInt(x, 10));

            if (action == "assignTo") {
                this.$refs.assignToModal.$show(rowIds, clearSelection);
            } else if (action == "workflowTags") {
                this.$refs.workflowTagsModal.$show(rowIds, clearSelection);
            } else if (action === "addEtag") {
                this.$refs.modalAddEtag.$show(rowIds);
            } else if (action === "removeEtags") {
                this.$refs.modalRemoveEtags.$show(rowIds);
            }
        },
        handleSort({sortAsc, column: sortColumn}) {
            this.setRouteQueryParams({sortAsc, sortColumn});
        },
        setRouteQueryParams(params) {
            // NOTE: table sort click trigger multiple times and setting same route throws NavigationDuplicated error
            this.$router
                .push({
                    name: "listings",
                    query: {
                        ...this.$route.query,
                        ...params,
                    },
                })
                .catch(err => {
                    if (
                        err.name !== "NavigationDuplicated" &&
                        !err.message.includes(
                            "Avoided redundant navigation to current location"
                        )
                    ) {
                        throw err;
                    }
                });
        },
    },
};
</script>

<template>
    <Modal
        :busy="isBusy"
        :show="showModal"
        :close-on-mask-click="false"
        :close-on-esc="false"
        padding
        @close="$hide"
    >
        <div class="text-left text-2xl">
            {{ $t("panelManager.panelSelect.title") }}
        </div>
        <div class="flex mt-5" style="width:900px;">
            <div class="flex flex-col pr-5">
                <div class="">
                    <Search
                        always-show
                        :placeholder="
                            $t('panelManager.panelSelect.searchPlaceholder')
                        "
                        :input-width="400"
                        :value="searchQuery"
                        @search="onSearch"
                    />
                </div>
                <div class="flex-1 pt-3">
                    <ServerTable
                        :data="panels"
                        :sort-column-name="sortColumnName"
                        :sort-column-asc="sortColumnAsc"
                        :total-count="totalServerPanelCount"
                        :page-index="pageIndex"
                        :page-size="pageSize"
                        :empty-text="$t('panelManager.panelSelect.emptyText')"
                        compressed
                        :show-paging-top="false"
                        @rowClick="onPanelSelect"
                        @sort="onSort"
                        @pageChange="onPageChange"
                    >
                        <TableColumn name="name" />
                    </ServerTable>
                </div>
            </div>
            <div class="flex-1">
                <PanelPreview
                    :headline="selectedPanel ? selectedPanel.headline : null"
                    :title="selectedPanel ? selectedPanel.title : null"
                    :description="
                        selectedPanel ? selectedPanel.description : null
                    "
                    :logo-url="selectedPanel ? selectedPanel.logoUrl : null"
                    :clickable="false"
                />
            </div>
        </div>
        <div class="text-right mt-6">
            <Button @click="$hide">
                {{ $t("panelManager.panelSelect.cancelAction") }}
            </Button>
            <Button
                primary
                :disabled="!selectedPanel"
                @click="onSavePanelSelect"
            >
                {{ $t("panelManager.panelSelect.selectAction") }}
            </Button>
        </div>
    </Modal>
</template>

<script>
import Vue from "vue";
import {mapState} from "vuex";
import api from "@/util/api";
import Modal from "@mediarails/vue-modal";
import {ServerTable, TableColumn} from "@mediarails/vue-table";
import Button from "@mediarails/vue-button";
import PanelPreview from "@/components/PanelPreview.vue";
import Search from "@mediarails/vue-impact-search";
export default {
    name: "PanelSelectModal",
    components: {Modal, Button, PanelPreview, ServerTable, TableColumn, Search},
    props: {},
    data: () => ({
        showModal: false,
        isBusy: false,
        panels: [],
        selectedPanel: null,
        searchQuery: null,
        pageIndex: 0,
        pageSize: 10,
        sortColumnName: "name",
        sortColumnAsc: true,
        totalServerPanelCount: null,
    }),
    computed: mapState({}),
    watch: {},
    mounted() {},
    methods: {
        $show() {
            this.panels = [];
            this.selectedPanel = null;
            this.searchQuery = null;
            this.pageIndex = 0;
            this.sortColumnName = "name";
            this.sortColumnAsc = true;
            this.totalServerPanelCount = null;
            this.showModal = true;
            this.fetchPanels();
        },
        $hide() {
            this.showModal = false;
        },
        onSearch(value) {
            this.searchQuery = value;
            this.fetchPanels();
        },
        onPanelSelect(panel) {
            this.selectedPanel = panel;
        },
        onSort({sortAsc, column}) {
            this.sortColumnName = column;
            this.sortColumnAsc = sortAsc;
            this.fetchPanels();
        },
        onPageChange(pageIndex) {
            this.pageIndex = pageIndex;
            this.fetchPanels();
        },
        fetchPanels() {
            this.isBusy = true;
            api.panels
                .fetchByFilter(
                    {search: this.searchQuery},
                    this.pageIndex,
                    this.pageSize,
                    this.sortColumnName,
                    this.sortColumnAsc
                )
                .then(response => {
                    this.totalServerPanelCount = response.total;
                    this.panels = response.panels || [];
                })
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.fetch"),
                    });
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        onSavePanelSelect() {
            this.$hide();
            this.$emit("finished", this.selectedPanel);
        },
    },
};
</script>

<style scoped></style>

import Ajax from "@mediarails/util-ajax";
import _ from "lodash";
import apiEnv from "./environment";

const errorPrefix = "API:listings";

export default apiUtil => {
    return {
        async fetchLogoOptions(id) {
            try {
                let listingLogoOptions = await Ajax.get(
                    `${apiEnv.urls.listingLogoOptions}`,
                    {id},
                    null,
                    true,
                    true,
                    await apiUtil.getAjaxConfig()
                );

                if (!listingLogoOptions) listingLogoOptions = [];

                return listingLogoOptions;
            } catch (error) {
                apiUtil.handleApiError(
                    error,
                    `${errorPrefix}:fetchLogoOptions()`
                );
            }
        },
        async fetchByFilter(filter, page = 0, size = 5, sortColumn, sortAsc) {
            try {
                let payload = {size, page};
                _.forOwn(filter, (val, key) => {
                    if (val) payload[key] = val;
                });

                if (sortColumn) payload.sortBy = sortColumn;
                if (sortAsc != null)
                    payload.sortDirection = sortAsc ? "asc" : "desc";

                if (sortColumn === "revenue180Days") payload.sortBy = "revenue";
                if (sortColumn === "totalAudienceSize")
                    payload.sortBy = "audience";

                let response = await Ajax.get(
                    apiEnv.urls.listings,
                    payload,
                    null,
                    true,
                    false,
                    await apiUtil.getAjaxConfig()
                );
                if (!response.results) response.results = [];
                return response;
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:fetchByFilter()`);
            }
        },

        async edit(listingValues) {
            try {
                Ajax.clearCacheByUrl(apiEnv.urls.static);
                let response = await Ajax.post(
                    apiEnv.urls.listings,
                    listingValues,
                    null,
                    true,
                    false,
                    await apiUtil.getAjaxConfig()
                );
                return (response || {}).updated;
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:edit()`);
            }
        },

        async approve(listingId, reason) {
            try {
                let response = await Ajax.post(
                    apiEnv.urls.listingApprove,
                    {id: listingId, reason},
                    null,
                    true,
                    false,
                    await apiUtil.getAjaxConfig()
                );

                return (response || {}).updated;
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:approve()`);
            }
        },

        async reject(listingId, reason) {
            try {
                let response = await Ajax.post(
                    apiEnv.urls.listingReject,
                    {id: listingId, reason},
                    null,
                    true,
                    false,
                    await apiUtil.getAjaxConfig()
                );
                return (response || {}).updated;
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:reject()`);
            }
        },

        async assignTo(listingIds, assignTo) {
            try {
                let response = await Ajax.post(
                    apiEnv.urls.listingAssignTo,
                    {ids: listingIds, assignTo},
                    null,
                    true,
                    false,
                    await apiUtil.getAjaxConfig()
                );
                return response;
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:assignTo()`);
            }
        },

        async addTags(listingIds, addTags = [], scheme) {
            try {
                Ajax.clearCacheByUrl(apiEnv.urls.static);
                let response = await Ajax.post(
                    apiEnv.urls.listingChangeTags,
                    {ids: listingIds, addTags, scheme},
                    null,
                    true,
                    false,
                    await apiUtil.getAjaxConfig()
                );
                if (!response || !response.succeeded) {
                    throw new Error(response.message);
                }
                return response;
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:addTags()`);
            }
        },

        async removeTags(listingIds, removeTags = [], scheme) {
            try {
                Ajax.clearCacheByUrl(apiEnv.urls.static);
                let response = await Ajax.post(
                    apiEnv.urls.listingChangeTags,
                    {ids: listingIds, removeTags, scheme},
                    null,
                    true,
                    false,
                    await apiUtil.getAjaxConfig()
                );
                if (!response || !response.succeeded) {
                    throw new Error(response.message);
                }
                return response;
            } catch (error) {
                apiUtil.handleApiError(error, `${errorPrefix}:removeTags()`);
            }
        },
    };
};

import _ from "lodash";
import Util from "@/util/util";
import api from "@/util/api";
import Vue from "vue";
import filterConfig from "@/../panel.filters.config";

const emptyFilter = _.reduce(
    filterConfig,
    (result, value, key) => {
        result[value.id] = null;
        return result;
    },
    {
        search: null,
    }
);

export default {
    state: {
        isBusy: false,
        filter: emptyFilter,
        panels: [],
        totalServerCount: 0,
        filteredTotalCount: 0,
        pageIndex: 0,
        pageSize: 100,
        sortColumnName: "updatedAt",
        sortColumnAsc: false,
    },
    modules: {},
    mutations: {
        panels_set(state, data) {
            Util.VuexSetValueOnState(state, data);
        },
        panels_setPage(state, {startIndex, panels}) {
            for (let i = startIndex, j = 0; j < panels.length; i++, j++) {
                state.panels[i] = panels[j];
            }
            state.panels = state.panels.slice(0);
        },
        panels_remove(state, panelIds) {
            state.panels = state.panels.filter(
                panel => !panelIds.find(id => id === panel.id)
            );
            state.totalServerCount = state.totalServerCount - panelIds.length;
            state.filteredTotalCount =
                state.filteredTotalCount - panelIds.length;
        },
        panels_set_filter(state, data) {
            Object.assign(state.filter, data);
        },
        panels_update_panel(state, panelData) {
            let panelTarget = state.panels.find(x => x.id === panelData.id);
            if (panelTarget) Object.assign(panelTarget, panelData);
        },
    },
    actions: {
        async panels_fetch({state, commit}) {
            let data = await api.panels.fetchByFilter(
                state.filter,
                state.pageIndex,
                state.pageSize,
                state.sortColumnName,
                state.sortColumnAsc
            );
            commit("panels_set", {
                totalServerCount: data.total,
                filteredTotalCount: data.filteredTotal,
            });
            let panels = data.panels;
            let startIndex = state.pageIndex * state.pageSize;
            commit("panels_setPage", {startIndex, panels});
            return data;
        },
        async panels_delete({commit}, panelIds) {
            let result = await api.panels.delete(panelIds);
            commit("panels_remove", panelIds);
            return result;
        },
        async panels_createPanel({dispatch}, panelData) {
            let result = await api.panels.create(panelData);
            await dispatch("panels_fetch");
            return result;
        },
        async panels_editPanel({commit}, panelData) {
            let result = await api.panels.edit(panelData);
            commit("panels_update_panel", result);
            return result;
        },
    },
};

export default {
    None: {
        label: "No role assigned",
        description: "Role has not been assigned or was not recognized.",
        permissions: {
            canEnhance: false,
            canReview: false,
            canApprove: false,
            canReject: false,
            canCreatePanels: false,
            canEditPanels: false,
            canDeletePanels: false,
            canEditPages: false,
            canPublishPages: false,
        },
    },
    Enhancer: {
        label: "Enhancer",
        description: "Can enhance records but cannot approve/reject.",
        permissions: {
            canEnhance: true,
            canReview: true,
            canApprove: false,
            canReject: true,
        },
    },
    Reviewer: {
        label: "Reviewer",
        description: "Can enhance records and approve/reject them.",
        permissions: {
            canEnhance: true,
            canReview: true,
            canApprove: true,
            canReject: true,
        },
    },
    HomepagePublisher: {
        label: "HomepagePublisher",
        description:
            "Can create/edit/delete panels, create/edit pages, and publish pages.",
        permissions: {
            canCreatePanels: true,
            canEditPanels: true,
            canDeletePanels: true,
            canEditPages: true,
            canPublishPages: true,
        },
    },
};

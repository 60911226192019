var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ConfirmModal',{ref:"refreshWarningDialog",attrs:{"confirm-button-label":_vm.$t('listings.reviewModal.refreshModal.actionLabel'),"title":_vm.$t('listings.reviewModal.refreshModal.title', {
                name: _vm.currentListing.name,
            }),"description":_vm.$tc(
                'listings.reviewModal.refreshModal.description',
                _vm.totalChange,
                {
                    totalChange: _vm.totalChange,
                }
            ),"show":_vm.showRefreshConfirmModal},on:{"confirm":_vm.fetchCurrentListing,"close":function () { return (_vm.showRefreshConfirmModal = false); }}}),_c('div',{staticClass:"bg-dark rounded-full"},[_c('div',{staticClass:"bg-secondary rounded-full",style:(("height:2px; width:" + _vm.progressPercent + "%;"))})]),_c('div',{staticClass:"flex mt-4"},[_c('div',{staticClass:"text-left"},[_c('Button',{attrs:{"small":"","disabled":_vm.isAtFirstListing},on:{"click":_vm.moveToPrevListing}},[_c('Icon',{attrs:{"name":"chevronLeft","size":"sm"}}),_vm._v(" "+_vm._s(_vm.$t("listings.reviewModal.buttonPrevious"))+" ")],1)],1),_c('div',{staticClass:"text-center flex-1"},[_c('ModeToggle',{attrs:{"value":_vm.mode,"options":_vm.modeOptions},on:{"change":_vm.changeMode}}),_c('Button',{staticClass:"ml-3 mb-1 outline-none",attrs:{"text":"","title":_vm.$t('listings.reviewModal.buttonRefreshTitle')},on:{"click":_vm.refreshCurrentListing}},[_c('Icon',{attrs:{"name":"sync","size":"lg"}})],1)],1),_c('div',{staticClass:"text-right"},[_c('Button',{attrs:{"small":"","disabled":_vm.isAtLastListing},on:{"click":_vm.moveToNextListing}},[_vm._v(" "+_vm._s(_vm.$t("listings.reviewModal.buttonNext"))+" "),_c('Icon',{attrs:{"name":"chevronRight","size":"sm"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Util from "@/util/util";

export default {
    state: {
        isBusy: false,
        mode: "review",
        sidebarFormScrollTop: 0,
    },
    mutations: {
        reviewModal_set(state, data) {
            Util.VuexSetValueOnState(state, data);
        },
        reviewModal_busyStart(state) {
            state.isBusy = true;
        },
        reviewModal_busyStop(state) {
            state.isBusy = false;
        },
        reviewModal_setMode(state, mode) {
            state.mode = mode;
        },
    },
};

import _ from "lodash";
import Util from "@/util/util";
import api from "@/util/api";
import listingFilterConfig from "@/../listing.filters.config";
import panelFilterConfig from "@/../panel.filters.config";

let emptyState = {
    countries: [],
    promoMethods: [],
    genders: [],
    pageTypes: [], // {id:0, name:"", maxPanels:4}
    regions: [], // {defaultRegion:false, displayName:"", id:0, name:""}
    languages: [],
    taskTypes: [],
    issueTypes: [],
    programTypes: [],
};

// Add listing filters
emptyState = _.reduce(
    listingFilterConfig,
    (result, value, key) => {
        if (value.staticDataProp) result[value.staticDataProp] = [];
        return result;
    },
    emptyState
);

// Add panel filters
emptyState = _.reduce(
    panelFilterConfig,
    (result, value, key) => {
        if (value.staticDataProp) result[value.staticDataProp] = [];
        return result;
    },
    emptyState
);

export default {
    state: emptyState,
    mutations: {
        static_set(state, data) {
            Util.VuexSetValueOnState(state, data);
        },
    },
    actions: {
        async static_fetch_info({commit}) {
            try {
                let data = await api.static.fetchListingsInfo();
                data = Object.assign({}, data, {
                    listingStatuses: ["needswork", ...data.states],
                });
                commit("static_set", data);
                return data;
            } catch (error) {
                throw new Error(`Static module error: ${error}`);
            }
        },
        async static_fetch_users({commit}) {
            try {
                let users = await api.static.fetchUsers();
                commit("static_set", {users: users});
                return users;
            } catch (error) {
                throw new Error(`Static module error: ${error}`);
            }
        },
    },
};

<template>
    <div id="app" class="flex text-center h-full bg-page">
        <Notify />
        <AppNav v-if="isLoggedIn" @logout="logout" />
        <div class="flex-1 overflow-y-auto">
            <router-view />
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import auth from "@/util/auth";
import Notify from "@/components/Notify.vue";
import AppNav from "@/components/AppNav.vue";
export default {
    name: "App",
    components: {Notify, AppNav},
    computed: mapState({
        isLoggedIn: s => s.session.isLoggedIn,
    }),
    created() {
        auth.setShowExpireNotificationFunc(() => {
            this.$notify({
                type: "error",
                text: `Warning, your session is about to expire please <span onclick="window.location.reload()" class="font-bold text-white hover:underline cursor-pointer">click here</span> to refresh the page.`,
            });
        });
        //this.$store.dispatch("session_renewTokens");
    },
    methods: {
        login() {
            this.$store.dispatch("session_login");
        },
        logout() {
            this.$store.dispatch("session_logout");
            this.$router.push({path: "/"});
        },
    },
};
</script>

<style lang="scss" scoped>
#app {
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply text-black;
}
</style>

<template>
    <div class="shadow-checkbox-field flex">
        <div class="">
            <Checkbox :value="value" disabled />
        </div>
        <div class="flex-1" :class="{'text-tertiary': valueMatches === false}">
            {{ label }}
        </div>
        <div class="self-end pl-3">
            <Button
                :disabled="value == null"
                style="margin-bottom:2px;"
                @click="$emit('copyValue', value)"
            >
                <Icon name="longArrowRight" />
            </Button>
        </div>
    </div>
</template>

<script>
import Button from "@mediarails/vue-button";
import Icon from "@mediarails/vue-fa-icon";
import Checkbox from "@mediarails/vue-checkbox";
export default {
    name: "ShadowCheckboxField",
    components: {Checkbox, Button, Icon},
    props: {
        label: {type: String, default: null},
        value: {type: Boolean, default: null},
        valueMatches: {type: Boolean, default: true},
    },
    data: () => ({}),
    computed: {},
    watch: {},
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.shadow-checkbox-field {
    margin-bottom: 28px;
    min-height: 30px;
}

.shadow-checkbox-field__label {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 4px;
    box-sizing: border-box;
    text-align: left;
}

.shadow-checkbox-field__value {
    font-size: 15px;
    height: 40px;
    box-sizing: border-box;
    word-wrap: break-word;
}
</style>

<template>
    <div>
        <div style="margin-top:80px;">
            <div
                class="bg-contain bg-no-repeat bg-center mx-auto bg-dark border"
                :style="{
                    'background-image': `url(${shadowData.logoUrl})`,
                    width: '220px',
                    height: '220px',
                }"
            />
        </div>
        <div style="margin-top:61px;">
            <template v-for="sf in shadowFieldMeta">
                <ShadowTextField
                    v-if="sf.type === 'text'"
                    :key="sf.label"
                    :label="sf.label"
                    :value="sf.value"
                    :value-matches="sf.valueMatches"
                    @copyValue="
                        copyShadowValue({name: sf.key, value: sf.value})
                    "
                />
                <ShadowTextareaField
                    v-else-if="sf.type === 'textarea'"
                    :key="sf.label"
                    :label="sf.label"
                    :value="sf.value"
                    :height="sf.height"
                    :value-matches="sf.valueMatches"
                    @copyValue="
                        copyShadowValue({name: sf.key, value: sf.value})
                    "
                />
                <ShadowTagField
                    v-else-if="sf.type === 'tag'"
                    :key="sf.label"
                    :label="sf.label"
                    :value="sf.value"
                    :name="sf.key"
                    :value-matches="sf.valueMatches"
                    @copyValue="
                        copyShadowValue({name: sf.key, value: sf.value})
                    "
                />
                <ShadowCheckboxField
                    v-else-if="sf.type === 'checkbox'"
                    :key="sf.label"
                    :label="sf.label"
                    :value="sf.value"
                    :value-matches="sf.valueMatches"
                    @copyValue="
                        copyShadowValue({name: sf.key, value: sf.value})
                    "
                />
                <div
                    v-else-if="sf.type === 'sectionSeparator'"
                    :key="sf.label"
                    style="margin: 42px 0 36px 0;"
                    class="border-l-2 pl-2"
                >
                    <span class="font-bold">{{ sf.label }}</span>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import _ from "lodash";
import ShadowTextField from "./ShadowTextField.vue";
import ShadowTextareaField from "./ShadowTextareaField.vue";
import ShadowTagField from "./ShadowTagField.vue";
import ShadowCheckboxField from "./ShadowCheckboxField.vue";
export default {
    name: "ShadowListingFormMirror",
    components: {
        ShadowTextField,
        ShadowTextareaField,
        ShadowTagField,
        ShadowCheckboxField,
    },
    props: {
        shadowData: {type: Object, default: () => ({})},
        listingData: {type: Object, default: () => ({})},
    },
    data: () => ({}),
    computed: {
        valueNotMatchingCount() {
            let count = 0;
            this.shadowFieldMeta.forEach(sf => {
                if (sf.valueMatches === false) count++;
            });
            return count;
        },
        shadowFieldMeta() {
            const valueMatches = (shadowValue, listingValue) => {
                if (shadowValue == null || shadowValue === "") return null;
                else return _.isEqual(shadowValue, listingValue);
            };

            return [
                {
                    key: "isNotSpam",
                    label: "Is Spam",
                    type: "checkbox",
                    value: this.shadowData.isNotSpam,
                    valueMatches: valueMatches(
                        this.shadowData.isNotSpam,
                        this.listingData.isNotSpam
                    ),
                },
                {
                    key: "logoUrl",
                    label: "Logo Url",
                    type: "text",
                    value: this.shadowData.logoUrl,
                    valueMatches: valueMatches(
                        this.shadowData.logoUrl,
                        this.listingData.logoUrl
                    ),
                },
                {
                    key: "name",
                    label: "Name",
                    type: "text",
                    value: this.shadowData.name,
                    valueMatches: valueMatches(
                        this.shadowData.name,
                        this.listingData.name
                    ),
                },
                {
                    key: "description",
                    label: "Description",
                    type: "textarea",
                    value: this.shadowData.description,
                    height: 154,
                    valueMatches: valueMatches(
                        this.shadowData.description,
                        this.listingData.description
                    ),
                },
                {
                    key: "partnerType",
                    label: "Listing type",
                    type: "text",
                    value: this.shadowData.partnerType,
                    valueMatches: valueMatches(
                        this.shadowData.partnerType,
                        this.listingData.partnerType
                    ),
                },
                {
                    key: "locationCountry",
                    label: "Country",
                    type: "text",
                    value: this.shadowData.locationCountry,
                    valueMatches: valueMatches(
                        this.shadowData.locationCountry,
                        this.listingData.locationCountry
                    ),
                },
                {
                    key: "language",
                    label: "Language",
                    type: "text",
                    value: this.shadowData.language,
                    valueMatches: valueMatches(
                        this.shadowData.language,
                        this.listingData.language
                    ),
                },
                {
                    key: "promoMethods",
                    label: "Promo methods",
                    type: "tag",
                    value: this.shadowData.promoMethods,
                    valueMatches: valueMatches(
                        this.shadowData.promoMethods,
                        this.listingData.promoMethods
                    ),
                },
                {
                    key: "tags",
                    label: "Tags",
                    type: "tag",
                    value: this.shadowData.tags,
                    valueMatches: valueMatches(
                        this.shadowData.tags,
                        this.listingData.tags
                    ),
                },
                {
                    key: "editorialTags",
                    label: "Editorial tags",
                    type: "tag",
                    value: this.shadowData.editorialTags,
                    valueMatches: valueMatches(
                        this.shadowData.editorialTags,
                        this.listingData.editorialTags
                    ),
                },
                {
                    key: "categories",
                    label: "Categories",
                    type: "tag",
                    value: this.shadowData.categories,
                    valueMatches: valueMatches(
                        this.shadowData.categories,
                        this.listingData.categories
                    ),
                },
                {
                    key: "gender",
                    label: "Gender",
                    type: "tag",
                    value: this.shadowData.gender,
                    valueMatches: valueMatches(
                        this.shadowData.gender,
                        this.listingData.gender
                    ),
                },
                {
                    label: "Contact",
                    type: "sectionSeparator",
                },
                {
                    key: "contactEmail",
                    label: "Email",
                    type: "text",
                    value: this.shadowData.contactEmail,
                    valueMatches: valueMatches(
                        this.shadowData.contactEmail,
                        this.listingData.contactEmail
                    ),
                },
                {
                    key: "contactIsAgent",
                    label: "Is agency representative",
                    type: "checkbox",
                    value: this.shadowData.contactIsAgent,
                    valueMatches: valueMatches(
                        this.shadowData.contactIsAgent,
                        this.listingData.contactIsAgent
                    ),
                },
                {
                    key: "contactFullName",
                    label: "Full name",
                    type: "text",
                    value: this.shadowData.contactFullName,
                    valueMatches: valueMatches(
                        this.shadowData.contactFullName,
                        this.listingData.contactFullName
                    ),
                },
                {
                    key: "contactFirstName",
                    label: "First name",
                    type: "text",
                    value: this.shadowData.contactFirstName,
                    valueMatches: valueMatches(
                        this.shadowData.contactFirstName,
                        this.listingData.contactFirstName
                    ),
                },
                {
                    key: "contactLastName",
                    label: "Last name",
                    type: "text",
                    value: this.shadowData.contactLastName,
                    valueMatches: valueMatches(
                        this.shadowData.contactLastName,
                        this.listingData.contactLastName
                    ),
                },
                {
                    key: "contactPhone",
                    label: "Phone",
                    type: "text",
                    value: this.shadowData.contactPhone,
                    valueMatches: valueMatches(
                        this.shadowData.contactPhone,
                        this.listingData.contactPhone
                    ),
                },
            ];
        },
    },
    watch: {
        valueNotMatchingCount: {
            immediate: true,
            handler: function(valueNotMatchingCount) {
                this.$emit("valueNotMatchingCount", valueNotMatchingCount);
            },
        },
    },
    mounted() {},
    methods: {
        copyShadowValue({name, value}) {
            this.$store.commit("listings_update_editedListingValue", {
                name,
                value,
            });
        },
    },
};
</script>

<style scoped></style>

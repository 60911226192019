<template>
    <div class="panel-preview" :class="{'panel-preview--clickable': clickable}">
        <div v-if="$slots.default" class="panel-preview__hover-content">
            <div>
                <slot />
            </div>
        </div>
        <div
            class="panel-preview__img"
            :style="{'background-image': `url('${logoUrl}')`}"
        />
        <div class="panel-preview__info">
            <div class="panel-preview__title">{{ title }}</div>
            <div class="panel-preview__headline">{{ headline }}</div>
            <div class="panel-preview__description">{{ description }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PanelPreview",
    components: {},
    filters: {},
    props: {
        headline: {type: String, default: null},
        title: {type: String, default: null},
        description: {type: String, default: null},
        logoUrl: {type: String, default: null},
        clickable: {type: Boolean, default: true},
    },
    data: () => ({}),
    computed: {},
    watch: {},
    mounted() {},
    methods: {},
};
</script>

<style scoped>
.panel-preview {
    border: 1px solid #ccc;
    border-radius: 8px;
    height: 420px;
    position: relative;
    display: inline-block;
    background-color: #fff;
    box-sizing: border-box;
    width: 100%;
}

.panel-preview__hover-content {
    position: absolute;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.35);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 8px;
}
.panel-preview__hover-content > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.panel-preview:hover .panel-preview__hover-content {
    opacity: 1;
}

.panel-preview__img {
    height: 270px;
    background-color: #fff;
    background-size: cover;
    background-position: top center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-sizing: border-box;
}

.panel-preview__info {
    background-color: #fff;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 10px 30px;
    position: absolute;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
}

.panel-preview__title {
    color: #e73860;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 15px;
    font-weight: normal;
    width: calc(100% - 60px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

.panel-preview__headline {
    color: #2c3e50;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
    width: calc(100% - 60px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

.panel-preview__description {
    color: #626f7c;
    padding-bottom: 20px;
    font-size: 15px;
    width: calc(100% - 60px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
}

.panel-preview--clickable {
    cursor: pointer;
}
</style>

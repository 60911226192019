<template>
    <div>
        <Spinner v-if="isBusy" mask-color="rgba(251,252,253,0.5)" />
        <PanelActions @panelCreate="onPanelOpenCreate" />
        <PanelsTable
            ref="panelsTable"
            class="mt-6"
            @panelDelete="onPanelDelete"
            @panelEdit="onOpenPanelEdit"
        />
        <PanelDeleteConfirm ref="deleteConfirm" @finished="clearSelection" />
        <PanelCreateEditModal ref="createEditModal" />
    </div>
</template>

<script>
import Vue from "vue";
import {mapState} from "vuex";
import PanelActions from "./PanelActions.vue";
import PanelsTable from "./PanelsTable.vue";
import Spinner from "@mediarails/vue-spinner";
import PanelDeleteConfirm from "./PanelDeleteConfirm.vue";
import PanelCreateEditModal from "../PanelCreateEditModal.vue";
export default {
    name: "PanelsView",
    components: {
        PanelActions,
        PanelsTable,
        Spinner,
        PanelDeleteConfirm,
        PanelCreateEditModal,
    },
    props: {},
    data: () => ({}),
    computed: mapState({
        isBusy: s => s.panelManager.panels.isBusy,
    }),
    watch: {},
    mounted() {
        this.fetchInitPanels();
    },
    methods: {
        fetchInitPanels() {
            this.$store.commit("panels_set", {
                isBusy: true,
                pageIndex: 0,
            });
            this.$store
                .dispatch("panels_fetch")
                .catch(error => {
                    Vue.rollbar.error(error);
                    this.$notify({
                        type: "error",
                        text: error || this.$i18n.t("errors.fetch"),
                    });
                })
                .finally(() => {
                    this.$store.commit("panels_set", {isBusy: false});
                });
        },
        onPanelDelete(rows = []) {
            this.$refs.deleteConfirm.$show(rows);
        },
        onOpenPanelEdit(panelData) {
            this.$refs.createEditModal.$show(panelData);
        },
        onPanelOpenCreate() {
            this.$refs.createEditModal.$show();
        },
        clearSelection() {
            this.$refs.panelsTable.$deselectAllRows();
        },
    },
};
</script>

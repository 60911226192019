<template>
    <LayoutSingleColumn>
        <div
            class="px-8 py-8 bg-white mx-auto mt-14 shadow-lg rounded"
            style="width:600px;"
        >
            <div class="text-center text-5xl">
                {{ $t("home.pageTitle") }}
            </div>
            <div
                class="text-center text-2xl mt-10 cursor-pointer"
                @click="login"
            >
                <span
                    class="border border-grey rounded px-4 py-1 bg-primary text-white shadow-md hover:bg-primary-dark"
                >
                    {{ $t("home.loginLabel") }}
                </span>
            </div>
        </div>
    </LayoutSingleColumn>
</template>

<script>
import LayoutSingleColumn from "@/components/LayoutSingleColumn.vue";
export default {
    name: "ViewHome",
    components: {LayoutSingleColumn},
    methods: {
        login() {
            this.$store.dispatch("session_login");
        },
    },
};
</script>

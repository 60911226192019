import Vue from "vue";
import _ from "lodash";

export default class Util {
    static VuexSetValueOnState(state, data) {
        _.forOwn(data, (value, key) => {
            Vue.set(state, key, value);
        });
    }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"bg-contain bg-no-repeat bg-center mr-4 bg-neon-pink inline-block align-middle border",style:({
                'background-image': ("url(" + _vm.logoUrl + ")"),
                width: '220px',
                height: '220px',
            })}),_c('div',{staticClass:"text-center pl-3 inline-block align-middle",class:{
                'text-success': _vm.listing.logoUrlApproved === true,
                'text-danger': _vm.listing.logoUrlApproved === false,
            },staticStyle:{"width":"30px"}},[(_vm.listing.logoUrlApproved === true)?_c('Icon',{staticClass:"align-middle ml-1",attrs:{"name":"checkCircleSolid","size":"1x"}}):_vm._e(),(_vm.listing.logoUrlApproved === false)?_c('Icon',{staticClass:"align-middle ml-1",attrs:{"name":"flagSolid","size":"sm"}}):_vm._e()],1)]),_c('div',{staticClass:"flex mt-6"},[_c('div',{staticClass:"flex-1 text-lg font-bold truncate",class:{'text-danger': _vm.isRejectedOrFlagged}},[_vm._v(" "+_vm._s(_vm.listing.name)+" ")]),_c('div',{staticClass:"text-right text-lg capitalize pl-6",class:{
                'text-success':
                    _vm.listing.status == _vm.enumListingStatus.approved,
                'text-danger': _vm.isRejectedOrFlagged,
            }},[_vm._v(" "+_vm._s(_vm.listing.status)+" ")])]),_c('div',{staticClass:"mt-5"},[_vm._v(_vm._s(_vm.listing.description))]),_c('div',{staticClass:"mt-7"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("listings.reviewModal.headerMediaProperties"))+" ")]),_vm._l((_vm.listing.mediaProperties),function(mp){return _c('div',{key:mp.url,staticClass:"mt-2 pl-4 truncate"},[_c('Tooltip',{attrs:{"content":(mp.issues || []).map(function (x) { return x.type; }).join(', ') ||
                        _vm.$t(
                            'listings.reviewModal.mediaProperties.noDataQualityIssues'
                        )}},[(_vm.mediaPropertyHasIssues(mp))?_c('Icon',{staticClass:"text-danger mr-1",attrs:{"name":"exclamationTriangle","size":"sm"}}):(mp.approved === true)?_c('Icon',{staticClass:"text-secondary mr-1",attrs:{"name":"checkCircleSolid","size":"sm"}}):(mp.approved === false)?_c('Icon',{staticClass:"text-danger mr-1",attrs:{"name":"timesOctagonSolid","size":"sm"}}):_vm._e(),(mp.primary)?_c('Icon',{staticClass:"text-secondary mr-1",attrs:{"name":"starSolid","size":"sm"}}):_vm._e(),_c('a',{staticClass:"no-underline text-primary",class:{'text-danger': _vm.mediaPropertyHasIssues(mp)},attrs:{"href":mp.url,"target":"_blank"}},[_vm._v(" "+_vm._s(mp.url)+" ")])],1)],1)})],2),(
            _vm.listing.listingFeedback != null &&
                _vm.listing.listingFeedback.length > 0
        )?_c('div',{staticClass:"mt-7"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("listings.reviewModal.headerListingFeedback"))+" ")]),_vm._l((_vm.listing.listingFeedback),function(lf){return _c('div',{key:lf.feedbackId,staticClass:"mt-2 pl-4"},[_c('div',{staticClass:"text-danger"},[_c('Icon',{staticClass:"mr-1",attrs:{"name":"exclamationTriangle","size":"sm"}}),_vm._v(" "+_vm._s(lf.feedbackType)+" ")],1),_c('div',[_vm._v(_vm._s("Received: " + _vm.$d(lf.submittedAt, "short")))]),_c('div',[_vm._v(_vm._s("Application: " + lf.submittedFrom))]),_c('div',[_vm._v(" "+_vm._s("Submitted By: " + (lf.submittedBy || "Unknown"))+" ")]),_c('div',{staticClass:"flex-1"},[_vm._v(" "+_vm._s("Comment: " + (lf.comment || "No comment available"))+" ")])])})],2):_vm._e(),_c('div',{staticClass:"mt-7"},_vm._l((_vm.values),function(v){return _c('div',{key:v.key,staticClass:"flex mb-5"},[_c('div',{staticStyle:{"width":"150px"}},[_vm._v(" "+_vm._s(v.label)+" ")]),_c('div',{staticClass:"text-center pr-3",class:{
                    'text-success': v.approved === true,
                    'text-danger': v.approved === false,
                },staticStyle:{"width":"30px"}},[(v.approved === true)?_c('Icon',{staticClass:"align-middle ml-1",attrs:{"name":"checkCircleSolid","size":"1x"}}):_vm._e(),(v.approved === false)?_c('Icon',{staticClass:"align-middle ml-1",attrs:{"name":"flagSolid","size":"sm"}}):_vm._e()],1),_c('div',{staticClass:"flex-1"},[(v.type == 'tag' && v.value)?_vm._l((v.value.split(',')),function(t,i){return _c('Tag',{key:(t + " " + i)},[_vm._v(" "+_vm._s(t)+" ")])}):(
                        v.type == 'boolean' && typeof v.value === 'boolean'
                    )?[_c('span',{class:{'text-tertiary': v.value === true}},[_vm._v(" "+_vm._s(v.value ? "Yes" : "No")+" ")])]:(v.type == 'spam')?[(v.value === false)?_c('span',{staticClass:"text-danger"},[_c('Icon',{staticClass:"align-middle ml-1 mr-1",attrs:{"name":"userSecretSolid","size":"lg"}}),_vm._v(" "+_vm._s(_vm.$t("listings.reviewModal.isNotSpamYesLabel"))+" ")],1):(v.value === true)?_c('span',{staticClass:"text-success"},[_c('Icon',{staticClass:"align-middle ml-1 mr-1",attrs:{"name":"userCheckSolid","size":"lg"}}),_vm._v(" "+_vm._s(_vm.$t("listings.reviewModal.isNotSpamNoLabel"))+" ")],1):[_vm._v(" "+_vm._s(_vm.$t("listings.reviewModal.isNotSpamEmptyLabel"))+" ")]]:(v.type == 'currency')?[_vm._v(" "+_vm._s(_vm.$n(v.value, "currency-float"))+" ")]:(v.type == 'currencyInt')?[_vm._v(" "+_vm._s(_vm.$n(v.value, "currency-int"))+" ")]:(v.type == 'floatSingle')?[_vm._v(" "+_vm._s(_vm.$n(v.value, "float-single"))+" ")]:(v.type == 'dateShort')?[_vm._v(" "+_vm._s(v.value ? _vm.$d(v.value, "short") : "--")+" ")]:[_vm._v(" "+_vm._s(v.value || "--")+" ")]],2)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="inline-block rounded-lg">
        <div
            v-for="(o, i) in options"
            :key="o.value"
            class="px-4 py-1 inline-block cursor-pointer border-grey-light"
            :class="{
                'rounded-l-lg': i == 0,
                'rounded-r-lg': i == options.length - 1,
                'border-l-0': i > 0,
                'bg-secondary': o.value == value,
                'text-white': o.value == value,
                'shadow-inner': o.value == value,
                'border-transparent': o.value == value,
                'bg-white': o.value != value,
                shadow: o.value != value,
                border: o.value != value,
                'hover:bg-light': o.value != value,
            }"
            @click="$emit('change', o.value)"
        >
            {{ o.label }}
        </div>
    </div>
</template>

<script>
export default {
    name: "ModeToggle",
    props: {
        value: {type: [String, Boolean, Object, Number], default: null},
        options: {type: Array, default: () => []},
    },
};
</script>

<template>
    <ModalReview ref="modal" @close="$emit('close')">
        <MediaPropScreenshotTabs slot="content" />
        <ShadowSidebar
            v-show="!shadowCollapsed"
            slot="shadowSidebar"
            @valueNotMatchingCount="v => (valueNotMatchingCount = v)"
        />
        <Sidebar
            slot="sidebar"
            :value-not-matching-count="valueNotMatchingCount"
            @shadowCollapse="v => (shadowCollapsed = v)"
        />
    </ModalReview>
</template>

<script>
import {mapState} from "vuex";
import ModalReview from "@/components/ModalReview.vue";
import MediaPropScreenshotTabs from "./MediaPropScreenshotTabs.vue";
import Sidebar from "./Sidebar.vue";
import ShadowSidebar from "./ListingsReviewModalShadowValues.vue";
export default {
    name: "ListingsReviewModal",
    components: {ModalReview, MediaPropScreenshotTabs, Sidebar, ShadowSidebar},
    data: () => ({
        shadowCollapsed: true,
        valueNotMatchingCount: 0,
    }),
    methods: {
        $show() {
            this.$refs.modal.$show();
        },
        $hide() {
            this.$refs.modal.$hide();
        },
    },
};
</script>

/*
    EDITING THIS FILE:
    To add a filter you 'should' only have to edit this file and possibly src/views/PanelManager/Panels/PanelActions.vue.
    If the filter has options that are straight forward and simple then you should only need to add
    an entry into this file and provide the 'getOptions' function. If the filter options require more data
    other than the data provided in the static data API call then you will have to add a computed property
    to the src/views/PanelManager/Panels/PanelActions.vue file and then reference that property in this file
    with the 'optionDataProp' value.

    {
        id
          // Key of the filter, used in api request as param name and overall as an ID
        label
          // Name of field to show in UI
        type
          // "select" or "multiselect", type of filter field
        disabled
        pinned
          // If filter field should always be shown even if not set
        staticDataProp
          // Name of the matching data prop in the static data api request
        getOptions
          // NOTE: use this OR optionDataProp
          // Function that recieves
        optionDataProp
          // Name of a computed prop in src/views/PanelManager/Panels/PanelActions.vue that will be used
          // to provide options for this filter. NOTE: only needed if the filter
          // options need to reference data other than values from the static data api call
    },
*/
export default [
    // {
    //     id: "region",
    //     label: "Region",
    //     type: "select",
    //     disabled: false,
    //     pinned: true,
    //     staticDataProp: "regions",
    //     getOptions: staticData => {
    //         return staticData.regions.map(x => ({
    //             label: x.displayName,
    //             value: x.name,
    //         }));
    //     },
    // },
    {
        id: "status",
        label: "Status",
        type: "select",
        disabled: false,
        pinned: true,
        staticDataProp: "panelStatus",
        getOptions: staticData => {
            return staticData.panelStatus.map(x => ({
                label: x,
                value: x,
            }));
        },
    },
    {
        id: "editorialTag",
        label: "Editorial tag",
        type: "select",
        disabled: false,
        pinned: true,
        staticDataProp: "editorialTags",
        getOptions: staticData => {
            return staticData.editorialTags.map(x => ({
                label: x.name,
                value: x.id,
            }));
        },
    },
];
